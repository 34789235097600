import React from "react";
import styled from "styled-components";
import Logo from "../assets/logo.png";


const DatonaConnectLogo = () => {
  return (
    <TitleBox>
      <TitleImage src={Logo}/>
      <Title className="h1">Datona Connect</Title>
    </TitleBox>
  );
};

export default DatonaConnectLogo;


const TitleBox = styled.div`
  flex: 0 0 auto;
  width: 100%;
  position: relative;
`;

const TitleImage = styled.img`
  position: relative;
  width: calc(min(30vw, 30vh));
  opacity: 30%;
  filter: blur(1.4px);
`;

const Title = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${props => props.theme.colors.lightHighContrast};
  z-index: 100;
`;
