import React from "react";
import {ScrollPort, ViewPort} from "../../components/StyledComponents";
import ChannelDetailsEditor from "../../components/ChannelDetailsEditor";
import SingleButtonFooterWithBypass from "../../components/SingleButtonFooterWithBypass";

class ChangeDetailsView extends React.Component {

  constructor(props) {
    super(props);
    this.personaId = props.personaId;
    this.knownAs = props.knownAs;
    this.iconIndex = props.iconIndex;
    this.icon = undefined; // will be initialised on construction of ChannelDetailsEditor
    this.saveListener = props.onSave;
    this.cancelListener = props.onCancel;
  }

  setDetails(knownAs, iconIndex, icon) {
    this.knownAs = knownAs;
    this.iconIndex = iconIndex;
    this.icon = icon;
  }

  save() {
    this.saveListener(this.knownAs, this.iconIndex, this.icon);
  }

  cancel() {
    this.cancelListener();
  }

  render() {
    return (
      <ViewPort>
        <ScrollPort>
          <ChannelDetailsEditor personaId={this.personaId} knownAs={this.knownAs}
                                iconIndex={this.iconIndex} onChange={this.setDetails.bind(this)}/>
        </ScrollPort>
        <SingleButtonFooterWithBypass buttonText="Save" onClick={this.save.bind(this)} bypassButtonText="cancel" bypassOnClick={this.cancel.bind(this)}/>
      </ViewPort>
    );
  }

}

export default ChangeDetailsView;