import React from 'react';
import styled from "styled-components";
import {colorUtils} from "../utils/utils";

const inputBoxComponent = (props) => {
  return (
    <InputBoxComponent bgColor={props.bgColor}>
      <Input className="h5" placeholder={props.placeholder} value={props.value} onInput={props.onInput} textAlign={props.textAlign} height={props.height} disabled={props.disabled === undefined ? false : props.disabled}/>
    </InputBoxComponent>
  );
};

export default inputBoxComponent;

const InputBoxComponent = styled.div`
  background: ${colorUtils.bgColorSelector};
  border-radius: 8px;
  padding: 4px 8px;
`;

const Input = styled.textarea`
  height: ${props => props.height ? props.height : "18px"};
  width: 100%;
  border: 0;
  outline: none;
  resize: none;
  background-color: transparent;
  text-align: ${props => props.textAlign ? props.textAlign : "center"};
  color: ${props => props.theme.colors.lightHighContrast};
  ::placeholder {
    color: ${props => props.theme.colors.lightMidContrast};
  }
`;
