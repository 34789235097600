/* This is the project stylesheet */

export default {
  colors: {
    brand: "#00B7A1",
    lightBackground: "#F3F6F5",
    lightHighContrast: "#494949",
    lightMidContrast: "#686666",
    lightLowContrast: "#E7F1EF",
    error: "#DA1636",
    success: "#3BB890"
  }
};