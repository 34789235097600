import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import {colorUtils} from "../utils/utils";
import {Link} from "react-router-dom";


const ButtonComponent = (props) => {
  const button = <Button className="h6" bgColor={props.bgColor ? props.bgColor : "brand"} color={props.color ? props.color : "white"} onClick={props.onClick} width={props.width}>{props.text}</Button>;
  if (props.route) {
    return (
      <Link to={props.route}>
        {button}
      </Link>
    );
  }
  else return button;
};

ButtonComponent.propTypes = {
  text: PropTypes.string.isRequired,
  route: PropTypes.string,
  onClick: PropTypes.func,
  bgColor: PropTypes.string,
  fgColor: PropTypes.string
}

export default ButtonComponent;


const Button = styled.div`
  background-color: ${colorUtils.bgColorSelector};
  color: ${colorUtils.colorSelector};
  border-radius: 5px;
  text-align: center;
  min-width: ${props => props.width ? props.width : "150px"};
  height: 50px;
  line-height: 50px;
  box-shadow: 0px 4px 4px 0 rgba(0,0,0,0.25);
`;
