import React from "react";
import PropTypes from 'prop-types';
import Button from "./Button";
import {Footer} from "./StyledComponents";

const SingleButtonFooter = (props) => {
  return (
    <Footer bgColor={props.bgColor}>
      <Button bgColor={props.buttonColor} text={props.buttonText} route={props.route} onClick={props.onClick}/>
    </Footer>
  );
};

SingleButtonFooter.propTypes = {
  buttonText: PropTypes.string.isRequired,
  route: PropTypes.string,
  onClick: PropTypes.func,
  buttonColor: PropTypes.string,
  bgColor: PropTypes.string
}

export default SingleButtonFooter;

