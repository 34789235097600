import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import {Link} from "react-router-dom";
import IdentityButtonSelected from "../assets/Identity-Button-Selected.png";
import IdentityButton from "../assets/Identity-Button.png";
import ConversationButtonSelected from "../assets/Conversation-Button-Selected.png";
import ConversationButton from "../assets/Conversation-Button.png";
import SettingsButtonSelected from "../assets/Settings-Button-Selected.png";
import SettingsButton from "../assets/Settings-Button.png";

const BottomTabs = (props) => {
  const identityImg = props.selected === "personas" ? IdentityButtonSelected : IdentityButton;
  const conversationImg = props.selected === "channels" ? ConversationButtonSelected : ConversationButton;
  const settingsImg = props.selected === "settings" ? SettingsButtonSelected : SettingsButton;
  return (
    <BottomTabsComponent>
      <Link to="/personas"><Button src={identityImg} /></Link>
      <Link to="/channels"><Button src={conversationImg} route="/channels" /></Link>
      <Link to="/settings"><Button src={settingsImg} route="/settings" /></Link>
    </BottomTabsComponent>
  );
};

BottomTabs.propTypes = {
  selected: PropTypes.string
}

export default BottomTabs;


const BottomTabsComponent = styled.div`
  background-color: ${props => props.theme.colors.lightBackground};
  border-top: 1px ${props => props.theme.colors.lightLowContrast} solid;
  padding: 8px;
  flex: 0 0 auto;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const Button = styled.img`
  width: 32px;
  height: 32px;
  fill: red;
`;
