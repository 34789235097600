import React from "react";
import styled from "styled-components";
import SingleButtonFooter from "../../components/SingleButtonFooter";
import {CenteredContent, ViewPort} from "../../components/StyledComponents";
import LogoIcon from "../../assets/logo.png";

const Main = (props) => {
  return (
    <ViewPort>
      <CenteredContent>
        <Title className="h1">Datona Identity</Title>
        <Logo src={LogoIcon} />
        <p className="h3">Welcome to a world where <u>YOU</u> are in control of your online identity</p>
        <p className="h5">This app helps enforce your data protection rights, protecting you from identity theft, fraud, misuse and abuse of your data.<br/><br/>Share your data in complete confidence and under your control.</p>
      </CenteredContent>
      <SingleButtonFooter buttonText="Next" route="/welcome"/>
    </ViewPort>
  );
};

export default Main;


const Title = styled.div`
  color: ${props => props.theme.colors.lightHighContrast};
  flex: 0 0;
`;

const Logo = styled.img`
  flex: 0 0;
  background-color: transparent;
  max-width: calc(min(35vw, 35vh));
  height: auto !important;
`;
