import {
  BackButton,
  Header,
  Subtitle,
  Title,
  CenteredTitle,
  TitleAndSubtitle,
  TitleRow,
  Line,
  Icon, AddButton, ButtonGap
} from "./StyledComponents";
import {Link} from "react-router-dom";
import React from "react";
import styled from "styled-components";
import InputSearchBox from "./InputBox";


export const H2Header = (props) => {
  const back = !props.back ? <ButtonGap/> : generateBackButton(props.back);
  const add = !props.add ? <ButtonGap/> : generateAddButton(props.add);
  const search = !props.search ? null : (
    <SearchRow>
      <InputSearchBox placeholder="Search..." bgColor="lightBackground"/>
    </SearchRow>
  );
  return (
    <Header>
      <TitleRow>
        {back}
        <CenteredTitle className="h2">{props.title}</CenteredTitle>
        {add}
      </TitleRow>
      {search}
      <Line/>
    </Header>
  )
}

export const H3Header = (props) => {
  const back = !props.back ? null : generateBackButton(props.back);
  const icon = !props.icon ? null : <Icon src={props.icon}/>
  const search = !props.search ? null : (
    <SearchRow>
      <InputSearchBox placeholder="Search..." bgColor="lightBackground"/>
    </SearchRow>
  );
  return (
    <Header>
      <TitleRow>
        {back}
        <TitleAndSubtitle>
          <Title className="h3">{props.title}</Title>
          <Subtitle className="h4">{props.subtitle}</Subtitle>
        </TitleAndSubtitle>
        {icon}
      </TitleRow>
      {search}
      <Line/>
    </Header>
  )
}

export const ButtonOnlyHeader = (props) => {
  const back = !props.back ? null : generateBackButton(props.back);
  return (
    <TransparentHeader style={{height: 24}}>
      <TitleRow>
        {back}
      </TitleRow>
    </TransparentHeader>
  )
}

function generateBackButton(click) {
  if (typeof click === "string") return <Link to={click}><BackButton/></Link>;
  else return <BackButton onClick={click}/>
}

function generateAddButton(click) {
  if (typeof click === "string") return <Link to={click}><AddButton/></Link>;
  else return <AddButton onClick={click}/>
}

const SearchRow = styled.div`
  height: 27px;
  width: 100%;
  margin-top: 8px;
`;

const TransparentHeader = styled.div`
  background-color: transparent;
  flex: 0 0 auto;
  padding: 12px;
  width: 100%;
`;

