import React from "react";
import styled from "styled-components";
import {timeUtils} from "../utils/utils";
import Linkify from 'react-linkify';
import EmailIcon from "../assets/email-icon.png";
import RequestIcon from "../assets/Request-Icon.png";

const Channel = (props) => {
  const timeStr = timeUtils.timeToRelativeTimeString(props.item.time);
  let bubble = null;
  const content = props.item.content; // ? htmlUtils.expandLinks(props.item.content) : props.item.content;
  switch (props.item.type) {
    case "event":
      bubble = <EventBubble text={props.item.content}/>;
      break;
    case "text":
      bubble = props.item.source !== 0 ? <TextBubbleWithIcon icon={props.icon} text={content}/> : <TextBubble text={content}/>;
      break;
    case "email":
      bubble = props.item.source !== 0 ? <EmailBubbleWithIcon icon={props.icon} text={props.item.subject} onClick={() => {props.onClick(props.item)}}/> : <EmailBubble text={props.item.subject}/>;
      break;
    case "request":
      bubble = props.item.source !== 0 ? <RequestBubbleWithIcon icon={props.icon} text={props.item.subject}/> : <RequestBubble text={props.item.subject}/>;
      break;
    case "image":
      bubble = props.item.source !== 0 ? <ImageBubbleWithIcon icon={props.icon} src={props.item.image}/> : <ImageBubble src={props.item.image}/>;
      break;
    case "image+text":
      bubble = props.item.source !== 0 ? <ImagePlusTextBubbleWithIcon icon={props.icon} src={props.item.image} text={content}/> : <ImagePlusTextBubble src={props.item.image} text={content}/>;
      break;
    default:
      bubble = null;
  }
  return (
    <ChannelItemComponent>
      <DateTime className="h5">{timeStr}</DateTime>
      {bubble}
      <SubNote className="h5">{props.item.response}</SubNote>
    </ChannelItemComponent>
  );
};

const EventBubble = (props) => {
  return (
    <Content>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 5L16.781 10.9243L23 11.8801L18.5 16.489L19.562 23L14 19.9243L8.438 23L9.5 16.489L5 11.8801L11.219 10.9243L14 5Z" stroke="#999999" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      <Gap/>
      <EventText className="BodyText">{props.text}</EventText>
    </Content>
  );
};

const TextBubbleWithIcon = (props) => {
  return (
    <Content>
      <Icon src={props.icon}/>
      <Gap/>
      <DarkBubble>
        <LightText className="BodyText"><Linkify properties={{target: '_blank'}}>{props.text}</Linkify></LightText>
      </DarkBubble>
    </Content>
  );
};

const TextBubble = (props) => {
  return (
    <Content>
      <LightBubble>
        <DarkText className="BodyText"><Linkify properties={{target: '_blank', className: "unformatted"}}>{props.text}</Linkify></DarkText>
      </LightBubble>
    </Content>
  );
};

const EmailBubbleWithIcon = (props) => {
  return (
    <Content onClick={props.onClick}>
      <Icon src={props.icon}/>
      <Gap/>
      <DarkBubble>
        <BubbleIcon src={EmailIcon}/>
        <Gap/>
        <LightText className="BodyText" style={{fontWeight: "bold"}}>{props.text}</LightText>
      </DarkBubble>
    </Content>
  );
};

const EmailBubble = (props) => {
  return (
    <Content>
      <LightBubble>
        <BubbleIcon src={EmailIcon}/>
        <Gap/>
        <DarkText className="BodyText" style={{fontWeight: "bold"}}>{props.text}</DarkText>
      </LightBubble>
    </Content>
  );
};

const RequestBubbleWithIcon = (props) => {
  return (
    <Content>
      <Icon src={props.icon}/>
      <Gap/>
      <BrandBubble>
        <BubbleIcon src={RequestIcon}/>
        <Gap/>
        <Column>
          <LightText className="BodyText" style={{fontWeight: "bold"}}>Request for Data</LightText>
          <LightText className="BodyText">{props.text}</LightText>
        </Column>
      </BrandBubble>
    </Content>
  );
};

const RequestBubble = (props) => {
  return (
    <Content>
      <LightBubble>
        <BubbleIcon src={RequestIcon}/>
        <Gap/>
        <DarkText className="BodyText" style={{fontWeight: "bold"}}>{props.text}</DarkText>
      </LightBubble>
    </Content>
  );
};

const ImageBubbleWithIcon = (props) => {
  return (
    <Content>
      <Icon src={props.icon}/>
      <Gap/>
      <DarkBubble style={{borderRadius: 2, padding: 4}}>
        <Image src={props.src} alt="image not available"/>
      </DarkBubble>
    </Content>
  );
};

const ImageBubble = (props) => {
  return (
    <Content>
      <LightBubble style={{borderRadius: 2, padding: 4}}>
        <Image src={props.src} alt="image not available"/>
      </LightBubble>
    </Content>
  );
};

const ImagePlusTextBubbleWithIcon = (props) => {
  return (
    <Content>
      <Icon src={props.icon}/>
      <Gap/>
      <DarkBubble style={{padding: 4, borderRadius: 2, borderBottomLeftRadius: 6, borderBottomRightRadius: 6}}>
        <Column>
          <Image src={props.src} alt="image not available"/>
          <LightText className="BodyText" style={{padding: "8px 10px 6px"}}><Linkify properties={{target: '_blank'}}>{props.text}</Linkify></LightText>
        </Column>
      </DarkBubble>
    </Content>
  );
};

const ImagePlusTextBubble = (props) => {
  return (
    <Content>
      <LightBubble style={{padding: 4, borderTopLeftRadius: 2, borderTopRightRadius: 2}}>
        <Column>
          <Image src={props.src} alt="image not available"/>
          <DarkText className="BodyText" style={{padding: "8px 10px 6px"}}><Linkify properties={{target: '_blank'}}>{props.text}</Linkify></DarkText>
        </Column>
      </LightBubble>
    </Content>
  );
};


export default Channel;


// Nested component styles

const ChannelItemComponent = styled.div`
  background-color: ${props => props.theme.colors.lightBackground};
  padding: 16px 20px;
`;

const DateTime = styled.p`
  color: ${props => props.theme.colors.lightMidContrast};
  margin-bottom: 8px;
`;

const Content = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Gap = styled.div`
  flex: 0 0 12px;
`;

const Icon = styled.img`
  background-color: transparent;
  border-radius: 50%;
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
`;

const BubbleIcon = styled.img`
  background-color: transparent;
  flex: 0 0 auto;
  width: 45px;
  height: 45px;
`;

const EventText = styled.div`
  flex: 1 1;
  margin-left: 12px;
`;

const SubNote = styled.p`
  color: ${props => props.theme.colors.lightMidContrast};
  text-align: right;
  right: 12px;
  overflow: hidden;
  wrap: nowrap;
  text-overflow: ellipsis;
`;

const LightBubble = styled.div`
  flex: 1 1;
  border-radius: 20px;
  padding: 8px 12px;
  background-color: ${props => props.theme.colors.lightLowContrast};
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
`;

const DarkBubble = styled.div`
  flex: 1 1;
  border-radius: 20px;
  padding: 8px 12px;
  background-color: ${props => props.theme.colors.lightMidContrast};
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
`;

const BrandBubble = styled.div`
  flex: 1 1;
  border-radius: 20px;
  padding: 8px 12px;
  background-color: ${props => props.theme.colors.brand};
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
`;

const LightText = styled.p`
  color: ${props => props.theme.colors.lightLowContrast};
  word-break: break-word;
`;

const DarkText = styled.p`
  color: ${props => props.theme.colors.lightMidContrast};
  word-break: break-word;
`;

const Image = styled.img`
  left: 3px;
  right: 3px;
  top: 3px;
  max-width:100%;
  max-height: 100%;
`;

