import datonaLib from 'datona-lib';
import {logger} from "../utils/logger";
import storage from "./storage";
import Web3 from 'web3';
import {DatonaConnect} from "./DatonaConnect";
import {ApplicationError, InternalError} from "./errors";
const RETRY_PERIOD_WHILE_CONNECTING = 1000;
const HOUSEKEEPING_PERIOD_LOW = 60000;
const HOUSEKEEPING_PERIOD_HIGH = 3000;
const UNAVAILABLE_AFTER_RETRY_COUNT = 5;

export const datona = datonaLib;
export const web3 = Web3;

export const ChannelTasks = {
  CREATE_VAULT: 200,
  WRITE_METADATA: 201,
  READ_METADATA: 202,
  OPEN_CHANNEL: 203,
  CHECK_FOR_NEW_POSTS: 204,
  READ_POST: 205,
  CHECK_CONTRACT_EXPIRY: 206,
  OPEN_WORKER: 207,
  POST: 208
}

export const ChannelState = {
  CONSTRUCTING: 100,
  OPEN: 101,
  CLOSED: 102
}

const WorkerState = {
  CONNECTING: 0,
  UNAVAILABLE: 1,
  OPEN: 2,
  CLOSED: 3
}

export const SubscriptionEvent = {
  CHANNEL_OPEN: 300,
  CHANNEL_CLOSED: 301,
  CHANNEL_UNAVAILABLE: 302,
  CHANNEL_AVAILABLE: 303,
  METADATA_UPDATED: 304,
  NEW_POSTS: 305
}


//
// ChannelWorker
//
// Periodically checks a channel's vault for new posts and metadata.  Checks each participant's vault
// folder separately.  Fetches any new or updated files since the last time it checked that folder and
// adds new posts to storage.  Updated metadata (name and icon) are updated in storage if received.
//
// Has a crude retry capability: if any of the set of new or updated files cannot be retrieved from a
// vault folder then all will be read again next period (the fetch time will not be updated).
//
export class ChannelWorker {

  constructor(channelData, personaKey) {

    logger.log("channel worker created for contract "+channelData.contractAddress+", channel index "+channelData.channelIndex);

    if (channelData.participants === undefined || channelData.participants.length === 0)
      throw new InternalError("Cannot set up a ChannelWorker with no participants");

    this.channelData = channelData;
    this.channelId = {contractAddress: channelData.contractAddress, channelIndex: channelData.channelIndex};
    this.key = personaKey;
    this.LOG_PREFIX = "Channel "+channelData.contractAddress+"/"+channelData.channelIndex+" ("+channelData.title+"): ";

    // State
    this.workerState = WorkerState.CONNECTING;
    this.updateRate = HOUSEKEEPING_PERIOD_LOW;
    this.retryRate = HOUSEKEEPING_PERIOD_LOW;
    this.errorCount = 0;
    this.timerId = undefined;
    this.subscribers = [];
    this.subscriptionId = 0;

    // Contract Setup
    this.contract = new datona.blockchain.Contract(channelData.request.contractSource.abi, channelData.contractAddress);

    // Vault Setup
    this.vault = new datona.vault.RemoteVault(channelData.vault.url, channelData.id.contractAddress, personaKey, channelData.vault.id);
    this.myVaultFolderIndex = 0;
    this.participants = channelData.participants;
    for (let i=0; i<channelData.participants.length; i++) {
      if (channelData.participants[i].id === channelData.personaId) this.myVaultFolderIndex = i;
      this.participants[i].participantIndex = i;
    }

    // Task Setup
    this.taskList = storage.select(storage.Tables.CHANNEL_TASKS, this.channelId);
    this.taskListLocked = false;

    // Bind callbacks
    this._checkContractState = this._checkContractState.bind(this);
    this._unqueueTask = this._unqueueTask.bind(this);
    this._run = this._run.bind(this);
    this._createVault = this._createVault.bind(this);
    this._writeMetadata = this._writeMetadata.bind(this);
    this._readMetadata = this._readMetadata.bind(this);
    this._readPosts = this._readPosts.bind(this);
    this._readPost = this._readPost.bind(this);
    this._updateParticipantRecord = this._updateParticipantRecord.bind(this);
    this._updateChannelState = this._updateChannelState.bind(this);

    this._run();
  }


  setHighUpdateRate(active) {
    this.updateRate = active ? HOUSEKEEPING_PERIOD_HIGH : HOUSEKEEPING_PERIOD_LOW;
    this.retryRate = active ? HOUSEKEEPING_PERIOD_HIGH : HOUSEKEEPING_PERIOD_LOW;
    if (active) {
      window.clearTimeout(this.timerId);
      for (let i=0; i<this.participants.length; i++) {
        if (this.workerState === WorkerState.CONNECTING) this._queueTask({task: ChannelTasks.READ_METADATA, participantIndex: i});
        this._queueTask({task: ChannelTasks.CHECK_FOR_NEW_POSTS, participantIndex: i});
      }
      this._run();
    }
  }

  writePost(id, post) {
    window.clearTimeout(this.timerId);
    const task = {channelId: this.channelId, task: ChannelTasks.POST, post: post, id: id};
    storage.insert(storage.Tables.CHANNEL_TASKS, task);
    this._queueTask(task);
    this._run();
  }

  updateMetadata(name, icon) {
    window.clearTimeout(this.timerId);
    const id = name+"-"+Date.now();
    const task = {id: id, channelId: this.channelId, task: ChannelTasks.WRITE_METADATA, name: name, icon: icon};
    storage.insert(storage.Tables.CHANNEL_TASKS, task);
    this._queueTask(task);
    this._run();
  }

  subscribe(handler) {
    const id = this.subscriptionId++;
    this.subscribers.push({
      id: id,
      handler: handler
    });
    return id;
  }

  unsubscribe(id) {
    let i=-1;
    let found = false;
    while (++i < this.subscribers.length && !found) {
      if (this.subscribers[i].id === id) {
        this.subscribers.splice(i,1);
        found = true;
      }
    }
    return found;
  }

  close() {
    this._closeChannel();
  }

  _informSubscribers(event, data) {
    this.subscribers.forEach( (subscriber) => {
      subscriber.handler(event, data);
    })
  }

  _queueTask(task) {
    let discard = false;
    while (this.taskListLocked) {}  // task is being unqueued so wait
    this.taskListLocked = true;
    switch (task.task) {
      case ChannelTasks.CREATE_VAULT:
      case ChannelTasks.OPEN_CHANNEL:
      case ChannelTasks.CHECK_CONTRACT_EXPIRY:
      case ChannelTasks.OPEN_WORKER:
        // discard if already on the queue
        for (let i=0; i<this.taskList.length; i++) {
          if (this.taskList[i].task === task.task) discard = true;
        }
        break;

      case ChannelTasks.READ_METADATA:
      case ChannelTasks.CHECK_FOR_NEW_POSTS:
        // discard if already on the queue for this participant
        for (let i=0; i<this.taskList.length; i++) {
          if (this.taskList[i].task === task.task && this.taskList[i].participantIndex === task.participantIndex) discard = true;
        }
        break;

      case ChannelTasks.READ_POST:
        // discard if this post id is already on the queue
        for (let i=0; i<this.taskList.length; i++) {
          if (this.taskList[i].task === task.task && this.taskList[i].filename === task.filename) discard = true;
        }
        break;

      case ChannelTasks.WRITE_METADATA:
        // replace task if there's another on the queue for this participant
        for (let i=0; i<this.taskList.length; i++) {
          if (this.taskList[i].task === task.task && this.taskList[i].participantIndex === task.participantIndex) {
            this.taskList[i] = task;
            discard = true;
          }
        }
        break;

      case ChannelTasks.POST:
        // always accept a new post
        break;

      default:
        throw new InternalError("Unexpected task in _queueTask "+task.task);

    }
    if (!discard) this.taskList.push(task);
    this.taskListLocked = false;
  }

  _unqueueTask() {
    while (this.taskListLocked) {}  // task is being queued so wait
    this.taskListLocked = true;
    let task;
    if (this.taskList.length > 0) {
      if (this.taskList[0].id) {
        storage.del(storage.Tables.CHANNEL_TASKS, this.taskList[0].id);
      }
      task = this.taskList.splice(0,1);
    }
    this.taskListLocked = false;
    return task;
  }

  _run() {
    let tryAgain = true;
    while (tryAgain) {
      tryAgain = false;
      if (this.taskList.length > 0) {
        const task = this.taskList[0];
        let promise;
        switch (task.task) {
          case ChannelTasks.CREATE_VAULT:
            promise = this._createVault();
            promise.retryCondition = (err) => { return !err.message.match("attempt to create a vault that already exists") };
            break;
          case ChannelTasks.WRITE_METADATA:
            promise = this._writeMetadata(this.participants[this.myVaultFolderIndex], task.name, task.icon);
            break;
          case ChannelTasks.READ_METADATA:
            if (task.participantIndex === undefined || this.participants[task.participantIndex] === undefined) {
              throw new InternalError("invalid task.participantIndex passed to READ_METADATA: "+task.participantIndex);
            }
            promise = this._readMetadata(this.participants[task.participantIndex], task.mtime);
            break;
          case ChannelTasks.CHECK_FOR_NEW_POSTS:
            if (task.participantIndex === undefined || this.participants[task.participantIndex] === undefined) {
              throw new InternalError("invalid task.participantIndex passed to CHECK_FOR_NEW_POSTS: "+task.participantIndex);
            }
            promise = this._readPosts(this.participants[task.participantIndex]);
            break;
          case ChannelTasks.READ_POST:
            if (task.participantIndex === undefined || this.participants[task.participantIndex] === undefined) {
              throw new InternalError("invalid task.participantIndex passed to READ_POST: "+task.participantIndex);
            }
            promise = this._readPost(this.participants[task.participantIndex], task.filename, task.mtime);
            break;
          case ChannelTasks.POST:
            promise = this._post(task.id, task.post);
            break;
          case ChannelTasks.OPEN_CHANNEL:
            this._updateChannelState(ChannelState.OPEN);
            this._unqueueTask();
            tryAgain = true;
            this._informSubscribers(SubscriptionEvent.CHANNEL_OPEN);
            break;
          case ChannelTasks.OPEN_WORKER:
            this.workerState = WorkerState.OPEN;
            this._unqueueTask();
            tryAgain = true;
            this._informSubscribers(SubscriptionEvent.CHANNEL_OPEN);
            break;
          case ChannelTasks.CHECK_CONTRACT_EXPIRY:
            promise = this._checkContractState();
            break;
          default:
            logger.logError("Invalid Task ID: "+task.task);
        }
        if (promise) promise
          .then( () => {
            this._unqueueTask();
            this.errorCount = 0;
            if (this.workerState === WorkerState.UNAVAILABLE) {
              this.workerState = WorkerState.OPEN;
              this._informSubscribers(SubscriptionEvent.CHANNEL_AVAILABLE);
            }
          })
          .then(this._run)
          .catch( (err) => {
            const retryPeriod = (this.workerState === WorkerState.CONNECTING) ? RETRY_PERIOD_WHILE_CONNECTING : this.retryRate;
            logger.logError(this.LOG_PREFIX+"(task "+task.task+") "+err.message+". Trying again in "+retryPeriod+"ms", err);
            this.errorCount++;
            if (this.errorCount >= UNAVAILABLE_AFTER_RETRY_COUNT) {
              this.workerState = WorkerState.UNAVAILABLE;
              this._informSubscribers(SubscriptionEvent.CHANNEL_UNAVAILABLE);
            }
            if (err instanceof datona.errors.PermissionError) this.taskList.unshift({task: ChannelTasks.CHECK_CONTRACT_EXPIRY});
            if (err instanceof datona.errors.DatonaError || err instanceof ApplicationError){
              const retry = !promise.retryCondition || promise.retryCondition(err);
              if (retry) this.timerId = window.setTimeout(this._run, retryPeriod);
              else this._unqueueTask();
            }
            else throw err;
          });
      }
      else if (this.workerState !== WorkerState.CLOSED) {
        const restartTimer = this.taskList.length === 0;
        for (let i=0; i<this.participants.length; i++) {
          if (this.workerState === WorkerState.CONNECTING) this._queueTask({task: ChannelTasks.READ_METADATA, participantIndex: i});
          this._queueTask({task: ChannelTasks.CHECK_FOR_NEW_POSTS, participantIndex: i});
        }
        // run straight away if starting up, else run again later.
        if (this.workerState === WorkerState.CONNECTING) {
          this._queueTask({task: ChannelTasks.OPEN_WORKER});
          tryAgain = true;
        }
        else if (restartTimer) this.timerId = window.setTimeout(this._run, this.updateRate);
      }
    }
  }

  _createVault() {
    logger.log(this.LOG_PREFIX+"creating vault")
    return this.vault.create();
  }

  _post(id, post) {
    const filename = this.participants[this.myVaultFolderIndex].folder+"/"+id;
    logger.logTrace(this.LOG_PREFIX+"appending post with filename "+filename);
    return this.vault.append(JSON.stringify(post), filename);
  }

  _readMetadata(participant, mtime) {
    logger.logTrace(this.LOG_PREFIX+"reading metadata for participant "+participant.participantIndex);
    return this.vault.read(participant.folder+"/metadata")
      .then( (data) => {
        const metadata = JSON.parse(data);
        logger.logTrace(this.LOG_PREFIX+"updated channel participant metadata "+this.channelData.contractAddress+"/"+participant.folder);
        const updates = {};
        if (metadata.name && metadata.name !== participant.name) updates.name = metadata.name;
        if (metadata.icon && metadata.icon !== participant.icon) updates.icon = metadata.icon;
        if (mtime && mtime > participant.lastFetchTime) updates.lastFetchTime = mtime;
        this._updateParticipantRecord(participant, updates);
        if (updates.name || updates.icon) this._informSubscribers(SubscriptionEvent.METADATA_UPDATED);
      });
  }

  _writeMetadata(participant, name, icon) {
    logger.log(this.LOG_PREFIX+"writing metadata to vault: "+name+", "+icon);
    const metadata = {
      name: name || participant.name,
      icon: icon || participant.icon
    };
    return this.vault.write(JSON.stringify(metadata), participant.folder+"/metadata");
  }

  _readPosts(participant) {
    logger.logTrace(this.LOG_PREFIX+"reading vault folder "+this.channelData.contractAddress+"/"+participant.folder+" for files newer than "+participant.lastFetchTime);
    return this.vault.read(participant.folder, {mtime: true, laterThan: participant.lastFetchTime})
      .then((list) => {
        if (datona.assertions.isArray(list)) {
          logger.logTrace(this.LOG_PREFIX+"number of updates: "+list.length);
          let latestTime = participant.lastFetchTime;
          for (let i=0; i<list.length; i++) {
            const id = list[i];
            if (id.mtime > latestTime) latestTime = id.mtime;
            if (id.file === "metadata") this._queueTask({task: ChannelTasks.READ_METADATA, participantIndex: participant.participantIndex, mtime: id.mtime});
            else {
              // only fetch if it's not already in the database
              if (storage.select(storage.Tables.POSTS, id.file).length === 0) {
                this._queueTask({
                  task: ChannelTasks.READ_POST,
                  participantIndex: participant.participantIndex,
                  filename: id.file,
                  mtime: id.mtime
                });
              }
            }
          }
          if (latestTime > participant.lastFetchTime) {
            this._updateParticipantRecord(participant, {lastFetchTime: latestTime});
          }
        }
        else logger.logTrace(this.LOG_PREFIX+"number of updates: null");
      });
  }

  _readPost(participant, filename, mtime) {
    return this.vault.read(participant.folder + "/" + filename)
      .then( (rawData) => { console.log(rawData);
        const data = JSON.parse(rawData);
        if (data === undefined || data.type === undefined) {
          logger.logError(this.LOG_PREFIX+"invalid post received with name "+filename+". Ignoring.");
        }
        else{
          logger.logTrace(this.LOG_PREFIX+"rx new post from "+this.channelId.contractAddress+"/"+filename);
          if (storage.select(storage.Tables.POSTS, filename).length !== 0) {
            logger.logWarning("post already in database");
            return;
          }
          const srcIndex = participant.participantIndex;
          const post = {
            ...data,
            id: filename,
            contractAddress: this.channelData.contractAddress,
            channelIndex: this.channelData.channelIndex,
            time: mtime,
            source: (srcIndex === this.myVaultFolderIndex) ? 0 : (srcIndex < this.myVaultFolderIndex) ? srcIndex + 1 : srcIndex,
          }
          storage.insert(storage.Tables.POSTS, post);
          this._informSubscribers(SubscriptionEvent.NEW_POSTS);
        }
      })
  }

  _checkContractState() {
    return this.contract.hasExpired()
      .then( (expired) => {
        if (expired && this.channelData.state !== ChannelState.CLOSED) {
          logger.log(this.LOG_PREFIX + " channel closed due to contract expiry");
          this._closeChannel();
        }
      });
  }

  _closeChannel() {
    window.clearTimeout(this.timerId);
    while(this._unqueueTask()) {}
    this.workerState = WorkerState.CLOSED;
    this._updateChannelState(ChannelState.CLOSED);
    const closeEvent = {
      contractAddress: this.channelId.contractAddress,
      channelIndex: this.channelId.channelIndex,
      time: Date.now(),
      source: -1,
      type: "event",
      content: "Channel Closed"
    };
    storage.insert(storage.Tables.POSTS, closeEvent);
    this._informSubscribers(SubscriptionEvent.NEW_POSTS);
    this._informSubscribers(SubscriptionEvent.CHANNEL_CLOSED);
  }

  _updateParticipantRecord(participant, updates) {
    const channelId = {contractAddress: this.channelData.contractAddress, channelIndex: this.channelData.channelIndex};
    const channelRecord = storage.select(storage.Tables.CHANNELS, channelId);
    if (channelRecord === undefined) throw new Error("received updated metadata from vault but failed to update the channel record: channel record with id "+JSON.stringify(channelId)+" does not exist");
    for (let i=0; i<channelRecord.participants.length; i++) {
      if (channelRecord.participants[i].id === participant.id) {
        channelRecord.participants[i] = {
          ...channelRecord.participants[i],
          ...updates
        }
      }
    }
    storage.update(storage.Tables.CHANNELS, channelId, channelRecord);
  }

  _updateChannelState(newState) {
    const channelId = {contractAddress: this.channelData.contractAddress, channelIndex: this.channelData.channelIndex};
    const channelRecord = storage.select(storage.Tables.CHANNELS, channelId);
    if (channelRecord === undefined) throw new Error("received updated metadata from vault but failed to update the channel record: channel record with id "+JSON.stringify(channelId)+" does not exist");
    if (channelRecord.state === newState) return;
    channelRecord.state = newState;
    storage.update(storage.Tables.CHANNELS, channelId, channelRecord);
  }

}


export class InviteWorker {

  retryPeriods = [
    { period: 100, tries: 10 },
    { period: 1000, tries: 10 },
    { period: 60000, tries: 0 }
  ];

  constructor(invite, callback) {
    this.invite = invite;
    this.callback = callback;
    this.retryCount = 0;
    this.connect();
  }

  connect() {
    this.timerId = undefined;
    logger.log("watching blockchain for acceptance of invite code "+this.invite.inviteCode);
    DatonaConnect.watchForAcceptance(this.invite.inviteCode, this.invite.originator)
      .then( this.handleAcceptance.bind(this) )
      .catch( (err) => {
        const retryPeriod = this.getRetryPeriod();
        logger.logError("Invite acceptance failure: "+err.message+". Retrying in "+retryPeriod+"ms", err);
        this.timerId = window.setTimeout(this.connect.bind(this), retryPeriod);
      })
  }

  getRetryPeriod() {
    this.retryCount++;
    let totalCount = 0;
    for (let i=0; i<this.retryPeriods.length; i++) {
      totalCount += this.retryPeriods[i].tries;
      if (this.retryCount <= totalCount) return this.retryPeriods[i].period;
    }
    return this.retryPeriods[this.retryPeriods.length-1].period;
  }

  handleAcceptance(event) {
    logger.logTrace(event);
    const connectCode = web3.utils.toHex(event.returnValues.connectCode);
    logger.log("Invite "+connectCode+" accepted by "+event.returnValues.user+". Contract address: "+event.returnValues.contractAddress);
     this.callback(this.invite, event.returnValues.contractAddress, event.returnValues.user );
  }

  close() {
    if (this.timerId) window.clearTimeout(this.timerId);
  }
}

//
// Functions
//

// function determineFolderName(folderId, userIds) {
//   if (folderId < 0 && -folderId < userIds.length) return userIds[-folderId];
//   if (folderId >= 0) return folderId;
//   logger.logError("invalid folder id: "+folderId);
//   throw new Error("invalid folder ID "+folderId);
// }

