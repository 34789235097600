import React from 'react';
import styled from "styled-components";
import {colorUtils} from "../utils/utils";

const selectBoxComponent = (props) => {
  let key = 0;
  const options = props.options.map( (option) => {
    return <Option key={key++} className="h4" value={option.id} color={props.color} textAlign={props.textAlign}>{option.text}</Option>;
  });
  return (
    <SelectBoxComponent bgColor={props.bgColor}>
      <Select placeholder={props.placeholder} value={props.value} onInput={props.onInput} height={props.height} disabled={props.disabled === undefined ? false : props.disabled}>
        {options}
      </Select>
    </SelectBoxComponent>
  );
};

export default selectBoxComponent;

const SelectBoxComponent = styled.div`
  background: ${colorUtils.bgColorSelector};
  border-radius: 8px;
  padding: 4px 8px;
`;

const Select = styled.select`
  height: ${props => props.height ? props.height : "18px"};
  width: 100%;
  border: 0;
  outline: none;
  background-color: transparent;
`;

const Option = styled.option`
  color: ${colorUtils.colorSelector};
  text-align: ${props => props.textAlign ? props.textAlign : "center"};
`;
