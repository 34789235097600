import styled from "styled-components";
import React from "react";
import {colorUtils} from "../utils/utils";
import BackArrow from "../assets/Back-Arrow.png";
import PlusIcon from "../assets/plus.png";
import Loading from "../assets/icons/loading.png";
import Success from "../assets/icons/success.png";

export const clamp = {
  back: BackArrow,
  plus: PlusIcon
};

export const ViewPort = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0px;
  top: 0px;
  background: ${props => props.theme.colors.lightBackground};
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

export const Header = styled.div`
  position: relative;
  padding: 12px;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.lightLowContrast};
`;

export const CenteredContent = styled.div`
  flex: 1 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  @media only screen and (min-width: 768px) {
    padding-left: 150px;
    padding-right: 150px;
  }
`;

export const Footer = styled.div`
  position: relative;
  height: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colorUtils.bgColorSelector};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const FooterColumn = styled.div`
  position: relative;
  height: 130px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colorUtils.bgColorSelector};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const ScrollPort = styled.div`
  flex: 1 1;
  overflow-y: scroll;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  gap: 12px;
`;

export const TitleAndSubtitle = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  padding: 0px 12px 0px 0px;
`;

export const Title = styled.span`
  white-space: nowrap;
  color: ${props => props.theme.colors.lightHighContrast};
`;

export const CenteredTitle = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.lightHighContrast};
  background-color: transparent;
  text-align: center;
`;


export const Subtitle = styled.p`
  flex: 1 1 auto;
  color: ${props => props.theme.colors.brand};
  wrap: nowrap;
  max-height: 24px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ButtonGap = styled.div`
  flex: 0 0 auto;
  height: 24px;
  width: 24px;
  margin-left: 12px;
  margin-right: 12px;
`;

export const BackButton = styled.div`
  flex: 0 0 auto;
  height: 24px;
  width: 24px;
  background: url(${BackArrow});
  background-size: 24px 24px;
  margin-left: 12px;
  margin-right: 12px;
`;

export const AddButton = styled.div`
  flex: 0 0 auto;
  width: 24px;
  height: 24px;
  background: url(${PlusIcon});
  background-size: 24px 24px;
  margin-left: 12px;
  margin-right: 12px;
`;

let nonce = 0;

export const FileAddButton = (props) => {
  const size = props.size ? props.size : 24;
  const color = colorUtils.colorSelector(props);
  const filter = props.filter ? props.filter : '*';
  return (
    <div>
      <label htmlFor={"picker-"+nonce}>
        <svg width={size} height={size} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.8917 8.17834C17.2229 8.17834 17.4904 8.29299 17.6943 8.52229C17.8981 8.72611 18 8.99363 18 9.32484C18 9.60509 17.8981 9.85987 17.6943 10.0892C17.4904 10.293 17.2229 10.3949 16.8917 10.3949H10.2038V17.3885C10.2038 17.7452 10.0892 18.051 9.85987 18.3057C9.63057 18.535 9.33758 18.6497 8.98089 18.6497C8.64968 18.6497 8.35669 18.535 8.10191 18.3057C7.87261 18.051 7.75796 17.758 7.75796 17.4267V10.3949H1.10828C0.77707 10.3949 0.509554 10.293 0.305733 10.0892C0.101911 9.85987 0 9.57962 0 9.24841C0 8.94267 0.101911 8.6879 0.305733 8.48408C0.509554 8.28025 0.77707 8.17834 1.10828 8.17834H7.75796V1.22293C7.75796 0.866241 7.87261 0.573248 8.10191 0.343949C8.33121 0.11465 8.6242 0 8.98089 0C9.36306 0 9.65605 0.11465 9.85987 0.343949C10.0892 0.54777 10.2038 0.828025 10.2038 1.18471V8.17834H16.8917Z" fill={color}/>
        </svg>
      </label>
      <input id={"picker-"+nonce++} type="file" accept={filter} hidden={true} onChange={props.onFiles}/>
    </div>
  )
}


export const Line = styled.div`
  position: absolute;
  height: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 1px solid ${props => props.theme.colors.lightMidContrast};
  transform: matrix(1, 0, 0, 1, 0, 0);
`;

export const Icon = styled.img`
  flex: 0 0 auto;
  background-color: transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
`;

export const ErrorMessage = (props) => {
  const errorMsg = props.text === undefined ? <br/> : props.text;
  return <ErrorText className="h5">{errorMsg}</ErrorText>
}

const ErrorText = styled.div`
  color: ${props => props.theme.colors.error};
`;

export const ExpandingGap = styled.div`
  flex: 1 1;
`;

export const Gap10vh = styled.div`
  flex: 0 0 auto;
  height: 10vh;
`;

const LoadingIconStyle = styled.img`
  flex: 0 0 auto;
  background-color: transparent;
  width: 120px;
  height: 120px;
`;

export const LoadingIcon = (props) => { return <LoadingIconStyle src={Loading} className="slow-spin" /> }

export const SuccessIcon = (props) => { return <LoadingIconStyle src={Success} /> }

