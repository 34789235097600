import React from "react";
import {useTheme} from "styled-components";
import SingleButtonFooter from "../../components/SingleButtonFooter";
import {ButtonOnlyHeader} from "../../components/Headers";
import {ScrollPort, ViewPort} from "../../components/StyledComponents";
import {useParams} from "react-router-dom";
import ChannelDetailsEditor from "../../components/ChannelDetailsEditor";

class Main extends React.Component {

  constructor(props) {
    super(props);
    let params = this.props.params;

    this.state = {
      personaId: params.persona,
      knownAs: params.knownAs,
      iconIndex: params.iconIndex
    }

  }

  setDetails(knownAs, iconIndex) {
    this.setState({ knownAs: knownAs, iconIndex: iconIndex });
  }

  render() {
    return (
      <ViewPort>
        <ButtonOnlyHeader back={this.props.history.goBack} />
        <ScrollPort>
          <ChannelDetailsEditor personaId={this.state.personaId} knownAs={this.state.knownAs} iconIndex={this.state.iconIndex} onChange={this.setDetails.bind(this)}/>
        </ScrollPort>
        <SingleButtonFooter buttonText="Next" route={"/channels/connect/remote/"+this.state.personaId+"/"+this.state.knownAs+"/"+this.state.iconIndex} />
      </ViewPort>
    );
  }

}

export default (props) => (<Main {...props} params={useParams()} theme={useTheme()} />);
