import React from "react";
import styled from "styled-components";
import {CenteredContent, ExpandingGap, ScrollPort, ViewPort} from "../components/StyledComponents";
import {useParams} from "react-router-dom";
import storage from "../model/storage";
import SingleButtonFooter from "../components/SingleButtonFooter";
import {logger} from "../utils/logger";

class Del extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      request: props.params.table.toUpperCase(),
      done: false
    }
  }

  view() {
    try {
      if (this.state.request === "ALL") storage.Tables.forEach(item => {
        storage.del(item, '*')
      });
      else storage.del(storage.Tables[this.state.request], '*');
      this.setState({ done: true })
    }
    catch (err) {
      logger.logError("failed to delete table", err);
      this.setState({ done: true, error: err.message });
    }
  }

  back() {
    this.props.history.back();
  }

  render() {
    let data;
    let dataContent;
    let error;
    try {
      data = storage.select(storage.Tables[this.state.request], '*');
      let i=0;
      if (Array.isArray(data)) {
        dataContent = data.map( item => {
          const strippedItem = { ...item };
          if (item.image) strippedItem.image = "image";
          if (item.icons) {
            strippedItem.icons = [];
            for (let i=0; i<item.icons.length; i++) {
              const icon = item.icons[i] ? "image" : undefined;
              strippedItem.icons.push(icon);
            }
          }
          if (item.participants) {
            strippedItem.participants = [];
            for (let i=0; i<item.participants.length; i++) {
              const participant = { ...item.participants[i] };
              participant.icon = item.participants[i].icon ? "image" : undefined;
              strippedItem.participants.push(participant);
            }
          }
          return <Item key={i} className="h5">{i++} {JSON.stringify(strippedItem)}</Item>
        })
      }
      else dataContent = <Item key={i} className="h5">{JSON.stringify(data)}</Item>;
    }
    catch (err) {
      logger.logError("failed to view table", err);
      error = err.message;
    }
    let content;
    if (error) content =
      <CenteredContent>
        <Title className="h2">Error!</Title>
        <ExpandingGap/>
        <p className="h5">{error}</p>
        <ExpandingGap/>
        <ExpandingGap/>
      </CenteredContent>;
    else content =
      <ScrollPort>
        <DataContent>
          <Title className="h2">Table {this.state.request}</Title>
          <p className="h5">Number of Elements: {data.length}</p>
          {dataContent}
        </DataContent>
      </ScrollPort>
    return (
      <ViewPort>
        {content}
        <SingleButtonFooter buttonText="OK" route="/" />
      </ViewPort>
    );
  }
}

export default (props) => (<Del {...props} params={useParams()} />);


const Title = styled.div`
  color: ${props => props.theme.colors.lightHighContrast};
  flex: 0 0 auto;
  padding-top: 7vh;
  padding-bottom: 7vh;
`;

export const DataContent = styled.div`
  padding: 20px;
  display: block;
  text-align: center;
  @media only screen and (min-width: 768px) {
    padding-left: 150px;
    padding-right: 150px;
  }
  word-break: break-all;
`;

const Item = styled.p`
  padding-top: 12px;
`;
