import React from "react";
import styled from "styled-components";
import SingleButtonFooter from "../../components/SingleButtonFooter";
import model from "../../model/model";
import SelectBox from "../../components/SelectBox";
import DatonaConnect from "../../components/DatonaConnect";
import {ButtonOnlyHeader} from "../../components/Headers";
import {CenteredContent, ScrollPort, ViewPort} from "../../components/StyledComponents";

class Main extends React.Component {

  constructor(props) {
    super(props);
    this.options = [];
    model.getPersonas().forEach( (persona) => {
      this.options.push({ id: persona.id, text: persona.name });
    });

    this.state = {
      persona: this.options[0].id
    }
  }

  setPersona(event) {
    this.setState({ persona: event.target.value });
  }

  render() {
    return (
      <ViewPort>
        <ButtonOnlyHeader back={this.props.history.goBack} />
        <ScrollPort>
          <CenteredContent>
            <DatonaConnect/>
            <InputBoxContainer>
              <span className="BodyText">Choose which persona to connect as:</span>
              <SelectBox options={this.options} onInput={this.setPersona.bind(this)} bgColor="lightLowContrast" textAlign="center" style={{width: "100%"}} height="38px" />
            </InputBoxContainer>
            <BottomGap/>
          </CenteredContent>
        </ScrollPort>
        <SingleButtonFooter buttonText="Next" route={"/channels/connect/choose-known-as/"+this.state.persona} />
      </ViewPort>
    );
  }

}

export default Main;

const InputBoxContainer = styled.div`
  height: 140px;
  width: 90%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const BottomGap = styled.div`
  flex: 0 0 auto;
  height: 20vh;
`;
