import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";


const Menu = (props) => {
  let buttons = [];
  for (let i=0; i<props.buttons.length; i++) {
    if (i !== 0) buttons.push(<Line key={i+100}/>);
    buttons.push(<Button key={i} id={i} text={props.buttons[i].text} handleClick={props.buttons[i].handleClick} route={props.buttons[i].route} />);
  }
  return (
    <MenuComponent>
      <Content>
        {buttons}
      </Content>
      <Content>
        <Button text={props.closeButtonText} handleClick={props.handleClose} route={props.route} />
      </Content>
    </MenuComponent>
  );
};

export default Menu;

const Button = (props) => {
  const button = <ButtonDiv key={props.id} className="h4" onClick={props.handleClick}>{props.text}</ButtonDiv>;
  if (props.route) {
    return (
      <Link to={props.route}>
        {button}
      </Link>
    );
  }
  else return button;
};

const MenuComponent = styled.div`
  background-color: transparent;
  margin: auto;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Content = styled.div`
  flex: 0 0 auto;
  width: 360px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonDiv = styled.div`
  background-color: "white";
  color: ${props => props.theme.colors.lightHighContrast};
  border-radius: 5px;
  text-align: center;
  height: 26px;
  line-height: 26px;
  padding: 12px;  
`;

const Line = styled.div`
  height: 0px;
  width: 90%;
  border: 1px solid ${props => props.theme.colors.lightLowContrast};
  transform: matrix(1, 0, 0, 1, 0, 0);
`;

