import React from "react";
import styled from "styled-components";
import SingleButtonFooter from "../../components/SingleButtonFooter";
import {CenteredContent, ExpandingGap, ViewPort} from "../../components/StyledComponents";

const Main = (props) => {
  return (
    <ViewPort>
      <CenteredContent>
        <Title className="h2">Welcome</Title>
        <p className="h5">With Datona Identity your personal identity data is held securely on this app. <br/><br/>An organisation may request access to pieces of your data through this app and only if you approve it will the data be shared with them. <br/><br/>You will always be able to see who you are sharing your data with and can withdraw access to it at any time (unless for legal reasons it must be held for a fixed period).</p>
        <ExpandingGap/>
      </CenteredContent>
      <SingleButtonFooter buttonText="Next" route="/create-pin"/>
    </ViewPort>
  );
};

export default Main;

const Title = styled.div`
  color: ${props => props.theme.colors.lightHighContrast};
  flex: 0 0 auto;
  padding-top: 7vh;
  padding-bottom: 7vh;
`;
