import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import ChevronRight from "../assets/icons/chevron-right.png";

const Item = (props) => {
  const item =
    <ItemComponent>
      <Icon src={props.icon}/>
      <Details>
        <Title className="h4">{props.title}</Title>
        <Summary className="BodyText">{props.summary}</Summary>
      </Details>
      <Icon src={ChevronRight}/>
    </ItemComponent>;
  if (props.route) return (<Link to={props.route}>{item}</Link>);
  else return (<div style={{opacity: 0.5}}>{item}</div>);
};

export default Item;


// Nested component styles

const ItemComponent = styled.div`
  background-color: ${props => props.theme.colors.lightBackground};
  border: 1px solid ${props => props.theme.colors.lightLowContrast};
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Icon = styled.img`
  background-color: transparent;
  border-radius: 50%;
  width: 28px;
  height: 28px;
`;

const Details = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Title = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.lightHighContrast};
`;

const Summary = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipses;
  color: ${props => props.theme.colors.lightMidContrast};
`;