import React from "react";
import styled from "styled-components";
import model from "../../model/model";
import Post from "../../components/ChannelItem";
import {SubscriptionEvent} from "../../model/workers";
import Menu from "../../components/Menu";
import ChangeDetails from "./ChangeDetails";
import {Footer, ScrollPort, ViewPort} from "../../components/StyledComponents";
import {H3Header} from "../../components/Headers";
import ChannelBottomMenuComponent from "../../components/ChannelBottomMenu";

const NUM_POSTS_TO_LOAD_AT_ONCE = 30;

class ChannelView extends React.Component {

  constructor(props) {
    super(props);
    this.subscriptionId = undefined;
    this.state = {
      channel: props.channel,
      posts: model.getPosts(props.channel.id, NUM_POSTS_TO_LOAD_AT_ONCE),
      numPosts: NUM_POSTS_TO_LOAD_AT_ONCE,
      message: "",
      menuVisible: false
    }
    this.postText = this.postText.bind(this);
  }

  scrollToBottom = () => {
    this.scrollPort.scrollTop = this.scrollPort.scrollHeight;
  }

  handleChannelEvent(event) {
    // only interested in the new post and channel closed events
    if (event === SubscriptionEvent.NEW_POSTS) {
      this.setState({
        posts: model.getPosts(this.state.channel.id, this.state.numPosts)
      });
    }
    else if(event === SubscriptionEvent.CHANNEL_CLOSED) {
      this.setState({channel: model.getChannel(this.state.channel.id.contractAddress, this.state.channel.id.channelIndex) });
    }
  }

  componentDidMount() {
    if (!this.subscriptionId) {
      this.subscriptionId = this.state.channel.subscribe(this.handleChannelEvent.bind(this));
    }
    this.state.channel.setHighUpdateRate(true);
    this.scrollToBottom();
  }

  componentWillUnmount() {
    this.state.channel.setHighUpdateRate(false);
    this.state.channel.unsubscribe(this.subscriptionId);
    this.subscriptionId = undefined;
  }

  componentDidUpdate() {
    if (!this.state.changeDetailsVisible) this.scrollToBottom();
  }

  setMessage(event) {
    this.setState({message: event.target.value});
  }

  postText(message) {
    const post = {
      type: "text",
      content: message
    };
    model.post(this.state.channel.id, post);
    this.setState({
      posts: model.getPosts(this.state.channel.id, this.state.numPosts)
    });
  }

  readFiles(event) {
      const fileList = event.target.files;
      if (fileList) {
        for( let i=0; i<fileList.length; i++) {
          const reader = new FileReader();
          const postman = this.postImage.bind(this);
          reader.onload = function (e) { postman(e.target.result) }
          reader.readAsDataURL(fileList.item(i));
        }
      }
   }

  postImage(img, text) {
    const post = {
      type: "image",
      content: text,
      image: img
    };
    model.post(this.state.channel.id, post);
    this.setState({
      posts: model.getPosts(this.state.channel.id, this.state.numPosts)
    });
  }

  openMenu() {
    this.setState({menuVisible: true});
  }

  closeMenu() {
    this.setState({menuVisible: false});
  }

  openChangeDetailsView() {
    this.setState({menuVisible: false, changeDetailsVisible: true})
  }

  closeChangeDetailsView() {
    this.setState({changeDetailsVisible: false})
  }

  savePersonaDetails(knownAs, iconIndex, icon) {
    this.state.channel.updateMetadata(knownAs, icon);
    this.setState({changeDetailsVisible: false});
  }

  render() {
    let key = 0;
    const posts = this.state.posts.map(item => {
      return <Post key={key++} icon={this.props.icon} item={item} onClick={this.props.postClickHandler}/>
    });
    const menuButtons = [
      { text: "Change Your Details", handleClick: this.openChangeDetailsView.bind(this) },
      { text: "Close This Channel", route: "/channel/close/"+this.state.channel.contractAddress+"/"+this.state.channel.channelIndex }, // TODO
    ];
    if (this.state.changeDetailsVisible) {
      const myDetails = this.state.channel.participants[this.state.channel.personaIndex];
      return <ChangeDetails personaId={this.state.channel.persona} knownAs={myDetails.name} iconIndex={0} onSave={this.savePersonaDetails.bind(this)} onCancel={this.closeChangeDetailsView.bind(this)}/>
    }
    const footer = this.state.channel.state === model.ChannelState.CLOSED
      ? <Footer><TextFooter className="h4">Channel Closed</TextFooter></Footer>
      : <ChannelBottomMenuComponent message={this.state.message} onInput={this.setMessage.bind(this)} onPost={this.postText} onFiles={this.readFiles.bind(this)} onMenu={this.openMenu.bind(this)}/>;

    return (
      <div>
        <MenuContainer hidden={!this.state.menuVisible}>
          <Menu closeButtonText="Cancel" handleClose={this.closeMenu.bind(this)} buttons={menuButtons} />
        </MenuContainer>
        <ViewPort className={this.state.menuVisible ? "darken" : ""}>
          <H3Header title={this.props.name} subtitle={this.props.channel.title} icon={this.props.icon} back={this.props.history.goBack} />
          <ScrollPort ref={(el) => { this.scrollPort = el }}>
            {posts}
          </ScrollPort>
          {footer}
        </ViewPort>
      </div>
    )
  }

}


export default  ChannelView;

const MenuContainer = styled.div`
  position: absolute;
  top: 52px;
  width: 100%;
  align: center;
  margin: auto;
  z-index: 100;
  ::menuHidden {
    display: none;
  }
`;

const TextFooter = styled.span`
  color: ${props => props.theme.colors.lightMidContrast};
  font-weight: normal;
`;

