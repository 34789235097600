import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {postToString} from "../utils/utils";
import {Icon} from "./StyledComponents";
import {timeUtils} from "../utils/utils";
import model from "../model/model";
import AnonymousIcon from "../assets/anonymous.png";

const ConversationItem = (props) => {
  const name = props.conversation.name ? props.conversation.name : "Anonymous";
  let icon;
  let found = false;
  for (let i=0; !found && i<props.conversation.participants.length; i++) {
    if (props.conversation.participants[i].id !== props.conversation.persona) {
        icon = props.conversation.participants[i].icon;
        found = true;
    }
  }
  const hasUnread = props.conversation.lastReadPost === undefined || props.conversation.lastMessageTime > props.conversation.lastReadPost;
  if (!icon) icon = AnonymousIcon;
  const posts = props.conversation.posts;
  posts.reset();
  const summary = postToString(posts.next());
  const closed = props.conversation.state === model.ChannelState.CLOSED;
  return (
    <Link to={props.route}>
        <ConversationItemComponent>
          <Iconandindicator className={closed ? "opacity20" : ""}>
            <Icon src={icon} style={{height: 56, width: 56}}/>
            {hasUnread ? <Newmessageindicator/> : null}
          </Iconandindicator>
          <Details>
            <Titlerow>
              <Name className={closed ? "h4 opacity20" : "h4"} style={{fontWeight: hasUnread ? 900 : "normal"}}>{name}</Name>
              <DateTime className={closed ? "h5 opacity70" : "h5"} style={{fontWeight: hasUnread ? 600 : "normal"}}>{timeUtils.timeToRelativeTimeString(props.conversation.lastMessageTime)}</DateTime>
            </Titlerow>
            <ConversationTitle className={closed ? "h5 opacity70" : "h5"} style={{fontWeight: hasUnread ? 500 : "normal"}}>{props.conversation.title}</ConversationTitle>
            <Summary className={closed ? "BodyText opacity70" : "BodyText"} style={{fontWeight: hasUnread ? 500 : "normal"}}>
              <span>{summary}</span>
            </Summary>
          </Details>
        </ConversationItemComponent>
    </Link>
  );
};

export default ConversationItem;


// Nested component styles

const ConversationItemComponent = styled.div`
  background-color: ${props => props.theme.colors.lightBackground};
  border: 1px solid ${props => props.theme.colors.lightLowContrast};
  padding: 12px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const Iconandindicator = styled.div`
  flex: 0 0 64px;
  position: relative;
`;

const Newmessageindicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  background-color: ${props => props.theme.colors.brand};
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.lightHighContrast};
`;

const Details = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Titlerow = styled.div`
  margin-bottom: 3px;
  display: flex;
  flex: 1 1;
  flex-direction: row;
`;

const Name = styled.p`
  flex: 1 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.lightHighContrast};
`;

const DateTime = styled.p`
  flex: 0 0 128px;
  margin-left: auto;
  color: ${props => props.theme.colors.lightMidContrast};
  text-align: right;
`;

const ConversationTitle = styled.div`
  color: ${props => props.theme.colors.brand};
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Summary = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.lightMidContrast};
  max-height: 36px;
  line-height: 18px;
`;