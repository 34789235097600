const bs58 = require("bs58");

const mins = 60000;
const hours = 60*mins;
const days = 24*hours;
// const weeks = 7*days;
// const months = 4*weeks;
// const years = 12*months;
const daysOfTheWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const shortDaysOfTheWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const monthsOfTheYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const shortMonthsOfTheYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


module.exports = {
  colorUtils: {
    colorSelector: colorSelector,
    bgColorSelector: bgColorSelector,
    buttonColorSelector: buttonColorSelector
  },
  postToString: postToString,
  timeUtils: {
    timeToRelativeTimeString: timeToRelativeTimeString,
    daysOfTheWeek: daysOfTheWeek,
    shortDaysOfTheWeek: shortDaysOfTheWeek,
    monthsOfTheYear: monthsOfTheYear,
    shortMonthsOfTheYear: shortMonthsOfTheYear
  },
  stringUtils: {
    copyStringToClipboard: copyStringToClipboard,
    stringToUrl: stringToUrl,
    hexToBase58: (address) => { if (address.startsWith("0x")) address = address.substr(2); return bs58.encode(Buffer.from(address, 'hex')) },
    base58ToHex: (address) => { return "0x"+bs58.decode(address).toString('hex') },
    capitalise: capitalise,
    capitaliseAllWords: capitaliseAllWords
  },
  htmlUtils: {
    expandLinks: expandLinks
  },
  cryptographicUtils: {
    textToByteArray: textToByteArray,
    hexToByteArray: hexToByteArray,
    uintToByteArray: uintToByteArray
  },
  arrayUtils: {
    exclusivePush: exclusivePush
  },
  imageUtils: {
    resize: resizeImage
  }
};

function colorSelector(props) {
  return _colorSelector(props.theme, props.color, props.theme.colors.lightHighContrast);
}

function bgColorSelector(props) {
  return _colorSelector(props.theme, props.bgColor, props.theme.colors.lightBackground);
}

function buttonColorSelector(props) {
  return _colorSelector(props.theme, props.buttonColor, props.theme.colors.brand);
}

function _colorSelector(theme, color, defaultColor) {
  switch(color) {
    case "lightBackground":
    case "background":
      return theme.colors.lightBackground;
    case "lightLowContrast":
    case "lowContrast":
      return theme.colors.lightLowContrast;
    case "lightMidContrast":
    case "midContrast":
      return theme.colors.lightMidContrast;
    case "lightHighContrast":
    case "highContrast":
      return theme.colors.lightHighContrast;
    case "brand":
      return theme.colors.brand;
    case "success":
      return theme.colors.success;
    case "error":
      return theme.colors.error;
    case "disabled":
      return theme.colors.disabled;
    case "white":
    case "black":
    case "transparent":
      return color;
    default:
      return defaultColor;
  }
}

function postToString(post) {
  if (post === undefined) return "";
  switch(post.type) {
    case "text": return post.content;
    case "text+image": return "(Image) " + post.content;
    case "image": return "(Image)";
    case "video": return "(Video)";
    case "email": return "(Email) " + post.subject;
    case "event": return post.content;
    default: return "ERROR! Unknown post type: "+post.type;
  }
}


function timeToRelativeTimeString(time) {
  const timeDate = new Date(time);
  const nowDate = new Date();
  const now = nowDate.getTime();
  const startOfToday = Math.trunc(now/days)*days;
  const startOfYesterday = startOfToday - days;
  const oneWeekAgo = startOfToday - 6*days;
  const oneYearAgoNextMonth = new Date("1 "+shortMonthsOfTheYear[(nowDate.getMonth()+1)%12]+" "+(nowDate.getFullYear()-(nowDate.getMonth()===11 ? 0 : 1))).getTime();
  const endOfToday = startOfToday + days;
  const endOfTomorrow = startOfToday + 2*days;
  const oneWeekAhead = endOfToday + 6*days;
  const oneYearAheadLastMonth = new Date("1 "+shortMonthsOfTheYear[(nowDate.getMonth()-1)%12]+" "+nowDate.getFullYear()+1).getTime();
  if (time <= now && time >= startOfToday) return "Today "+timeDate.getHours().toString().padStart(2,'0')+"."+timeDate.getMinutes().toString().padStart(2,'0');
  if (time < now && time >= startOfYesterday) return "Yesterday "+timeDate.getHours().toString().padStart(2,'0')+"."+timeDate.getMinutes().toString().padStart(2,'0');
  if (time < now && time >= oneWeekAgo) return daysOfTheWeek[timeDate.getDay()]+" "+timeDate.getHours().toString().padStart(2,'0')+"."+timeDate.getMinutes().toString().padStart(2,'0');
  if (time < now && time >= oneYearAgoNextMonth) return shortDaysOfTheWeek[timeDate.getDay()]+" "+shortMonthsOfTheYear[timeDate.getMonth()]+" "+timeDate.getDate()+", "+timeDate.getHours().toString().padStart(2,'0')+"."+timeDate.getMinutes().toString().padStart(2,'0');
  if (time > now && time >= endOfToday) return "Today "+timeDate.getHours().toString().padStart(2,'0')+"."+timeDate.getMinutes().toString().padStart(2,'0');
  if (time > now && time >= endOfTomorrow) return "Tomorrow "+timeDate.getHours().toString().padStart(2,'0')+"."+timeDate.getMinutes().toString().padStart(2,'0');
  if (time > now && time >= oneWeekAhead) return daysOfTheWeek[timeDate.getDay()]+" "+timeDate.getHours().toString().padStart(2,'0')+"."+timeDate.getMinutes().toString().padStart(2,'0');
  if (time > now && time >= oneYearAheadLastMonth) return shortDaysOfTheWeek[timeDate.getDay()]+" "+shortMonthsOfTheYear[timeDate.getMonth()]+" "+timeDate.getDate()+", "+timeDate.getHours().toString().padStart(2,'0')+"."+timeDate.getMinutes().toString().padStart(2,'0');
  return shortDaysOfTheWeek[timeDate.getDay()]+" "+shortMonthsOfTheYear[timeDate.getMonth()]+" "+timeDate.getDate()+" "+timeDate.getFullYear();
}


//
// String Utils
//

function copyStringToClipboard (str) {
  let el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style = {position: 'absolute', left: '-9999px'};
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

function stringToUrl(urlStr) {
  try {
    if (!urlStr) throw new Error("url is empty");
    const urlArray = urlStr.charAt(urlStr.length-1) === '/' ? urlStr.substr(0, urlStr.length-1).split(':') : urlStr.split(':');
    if (urlArray.length<2 || urlArray.length > 3) {
      throw new Error("Invalid url '"+urlStr+"'.  Must be of the form <protocol>://<host>[:<port>]");
    }
    const port = (urlArray.length === 2) ? undefined : parseInt(urlArray[2]);
    return {
      scheme: urlArray[0],
      host: urlArray[1].match(/\/\/(.*)/)[1],
      port: port
    };
  }
  catch(err) {
    alert(err.message);
    throw err;
  }
}

function capitalise(str) {
  return str[0].toUpperCase() + str.substring(1);
}

function capitaliseAllWords(str) {
  return str.split(' ')
    .map(a => a.trim())
    .map(a => a[0].toUpperCase() + a.substring(1))
    .join(" ")
}



//
// HTML Utils
//

function expandLinks(str) {
  const exp_match = /(\b(https?|):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
  const element_content = str.replace(exp_match, "<a href='$1'>$1</a>");
  const new_exp_match = /(^|[^/])(www\.[\S]+(\b|$))/gim;
  return element_content.replace(new_exp_match, '$1<a target="_blank" href="http://$2">$2</a>');
}


//
// Cryptographic Utils
//


function textToByteArray(val, length) {
  const arr = new Uint8Array(length);
  let arrIndex = length - val.length;
  for (let i=0; i<val.length; i++) {
    arr[arrIndex++] = val.charCodeAt(i);
  }
  return arr;
}

function hexToByteArray(val, length) {
  if (val.startsWith("0x")) val = val.substr(2);
  const arr = new Uint8Array(length);
  const valArr = Buffer.from(val, 'hex');
  let arrIndex = length - valArr.length;
  for (let i=0; i<valArr.length; i++) {
    arr[arrIndex++] = valArr[i];
  }
  return arr;
}

function uintToByteArray(val, length) {
  const arr = new Uint8Array(length);
  let i = arr.length-1;
  while (val > 0) {
    arr[i--] = (val & 0xFF);
    val = val >> 8;
  }
  return arr;
}

function exclusivePush(arr, val) {
  if (arr.indexOf(val) < 0) arr.push(val)
}


//
// Image Utils
//

function resizeImage(img, maxWidth, maxHeight) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  let width = img.width;
  let height = img.height;
  if (width > height) {
    if (width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width *= maxHeight / height;
      height = maxHeight;
    }
  }
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(img, 0, 0, width, height);
  return canvas.toDataURL("image/png");
}