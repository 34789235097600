import React from "react";
import styled from "styled-components";
import InputBox from "./InputBox";

class ChannelBottomMenuComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      message: "",
      onPost: props.onPost
    };
    this.postText = this.postText.bind(this);
  }

  postText() {
    if (this.state.message !== "" && this.state.onPost) {
      this.state.onPost(this.state.message);
      this.setState({message: ""});
    }
  }

  onInput(e) {
    this.setState({message: e.target.value});
  }

  render() {
    const postButton =
      <Buttonbar>
        <svg onClick={this.postText} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="30" height="30" rx="10" fill="#00B7A1"/>
          <path d="M6.59888 14.2008V22.2008C6.59888 22.7313 6.80959 23.24 7.18466 23.615C7.55974 23.9901 8.06844 24.2008 8.59888 24.2008H20.5989C21.1293 24.2008 21.638 23.9901 22.0131 23.615C22.3882 23.24 22.5989 22.7313 22.5989 22.2008V14.2008M18.5989 8.20084L14.5989 4.20084M14.5989 4.20084L10.5989 8.20084M14.5989 4.20084V17.2008" stroke="#F3F6F5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </Buttonbar>;
    const mediaButtons =
      <Buttonbar>
        <label onClick={this.props.onMenu}>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="10" fill="#00B7A1"/>
            <path d="M15 9.75V20.25M9.75 15H20.25" stroke="#F3F6F5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </label>
        <label>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="10" fill="#00B7A1"/>
            <path d="M22.8571 10.2857C22.8571 9.42138 22.15 8.71423 21.2857 8.71423H8.71425C7.84997 8.71423 7.14282 9.42138 7.14282 10.2857M22.8571 10.2857V19.7142C22.8571 20.5785 22.15 21.2857 21.2857 21.2857H8.71425C7.84997 21.2857 7.14282 20.5785 7.14282 19.7142V10.2857M22.8571 10.2857L15 15.7857L7.14282 10.2857" stroke="#F3F6F5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </label>
        <label>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="10" fill="#00B7A1"/>
            <path d="M23.25 20.25C23.25 20.6478 23.092 21.0294 22.8107 21.3107C22.5294 21.592 22.1478 21.75 21.75 21.75H8.25C7.85218 21.75 7.47064 21.592 7.18934 21.3107C6.90804 21.0294 6.75 20.6478 6.75 20.25V12C6.75 11.6022 6.90804 11.2206 7.18934 10.9393C7.47064 10.658 7.85218 10.5 8.25 10.5H11.25L12.75 8.25H17.25L18.75 10.5H21.75C22.1478 10.5 22.5294 10.658 22.8107 10.9393C23.092 11.2206 23.25 11.6022 23.25 12V20.25Z" stroke="#F3F6F5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15 18.75C16.6569 18.75 18 17.4069 18 15.75C18 14.0931 16.6569 12.75 15 12.75C13.3431 12.75 12 14.0931 12 15.75C12 17.4069 13.3431 18.75 15 18.75Z" stroke="#F3F6F5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </label>
        <label htmlFor="image-picker">
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="10" fill="#00B7A1"/>
            <path d="M9.75 21.75H20.25C21.0784 21.75 21.75 21.0784 21.75 20.25V9.75C21.75 8.92157 21.0784 8.25 20.25 8.25H9.75C8.92157 8.25 8.25 8.92157 8.25 9.75V20.25C8.25 21.0784 8.92157 21.75 9.75 21.75ZM9.75 21.75L18 13.5L21.75 17.25M13.5 12.375C13.5 12.9963 12.9963 13.5 12.375 13.5C11.7537 13.5 11.25 12.9963 11.25 12.375C11.25 11.7537 11.7537 11.25 12.375 11.25C12.9963 11.25 13.5 11.7537 13.5 12.375Z" stroke="#F3F6F5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </label>
        <input id="image-picker" type="file" accept="image/*" hidden={true} onChange={this.props.onFiles}/>
      </Buttonbar>;

    const buttons = (this.state.message) !== "" ? postButton : mediaButtons;

    return (
      <ChannelBottomMenu>
        <MessageBoxContainer>
          <InputBox value={this.state.message} onInput={this.onInput.bind(this)} onEnter={this.postText} placeholder="Message..." textAlign="left"/>
        </MessageBoxContainer>
        {buttons}
      </ChannelBottomMenu>
    );
  }
}

export default ChannelBottomMenuComponent;

const ChannelBottomMenu = styled.div`
  background-color: ${props => props.theme.colors.lightLowContrast};
  border-top: 1px solid ${props => props.theme.colors.lightMidContrast};
  padding: 10px 10px;
  flex: 0 0 auto;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
`;

const Buttonbar = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const MessageBoxContainer = styled.div`
  flex: 1 1;
`;
