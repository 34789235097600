import React from "react";
import styled from "styled-components";
import InputBox from "../../components/LargeInputBox";
import SingleButtonFooter from "../../components/SingleButtonFooter";
import TextButton from "../../components/TextButton";
import model from "../../model/model";
import {AppStates} from "../../AppStates";
import {CenteredContent, ErrorMessage, LoadingIcon, ViewPort} from "../../components/StyledComponents";
import {ConnectRemotely_params} from "../channels/Connect-Remotely";
import {logger} from "../../utils/logger";
import storage from "../../model/storage";

const State = { NOT_CONNECTING: 0, CONNECTING: 1, FINISHED: 2, FAILED: 3 }

class Main extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      connecting: State.NOT_CONNECTING
    };
    this.clear();
  }

  clear() {
    this.requestStr = "";
  }

  input(event) {
    this.requestStr = event.target.value;
    if (model.isChannelInvite(this.requestStr)) {
      const inviteIsValid = model.validateChannelInvite(this.requestStr);
      if (inviteIsValid) {
        const personas = model.getPersonas();
        const route = personas.length > 1 ? "/channels/connect/choose-persona/" : "/channels/connect/choose-known-as/"+personas[0].id;
        ConnectRemotely_params.invite = this.requestStr;
        this.props.history.push(route);
      }
      else {
        this.setState({validationResult: "invite is invalid"});
      }
    }
    else {
      model.receiveRequest(this.requestStr)
        .then( request => {
          const requestId = storage.insert(storage.Tables.OPEN_REQUESTS, request);
          this.props.history.push("/request/"+requestId);
        })
        .catch(err => {
          logger.logError("Request is invalid", err);
          this.setState({
            validationResult: err.message,
            connecting: State.NOT_CONNECTING
          });
        });
      this.setState({ connecting: State.CONNECTING });
    }
  }

  cancel() {
    // TODO delete open request and cancel retrieval
    this.finish();
  }

  finish() {
    this.props.history.push("/channels");
    model.setAppState(AppStates.SETUP_COMPLETE);
  }

  render() {

    const inputContent =
      <CenteredContent>
        <Title className="h2">Have a Request?</Title>
        <p className="h5">If you have a request code or request link then paste it below or scan the QR code </p>
        <InputBoxContainer>
          <InputBox onInput={this.input.bind(this)} bgColor="white" placeholderText="Paste your request code here..." textAlign="center" style={{width: "90%"}}/>
          <TextButton text="Scan" color="brand" />
        </InputBoxContainer>
        <ErrorMessage className="h5" text={this.state.validationResult} />
        <br/>
        <p className="h5">If not, you are now set up and ready to go!</p>
        <BottomGap/>
      </CenteredContent>

    const connectingContent =
      <CenteredContent>
        <Title className="h2">Have a Request?</Title>
        <p className="h5">If you have a request code or request link then paste it below or scan the QR code </p>
        <Indicator>
          <LoadingIcon/>
          <p className="h5">Fetching Request...</p>
        </Indicator>
        <BottomGap/>
      </CenteredContent>

    const content = this.state.connecting === State.NOT_CONNECTING ? inputContent : connectingContent;
    const buttonText = this.state.connecting === State.NOT_CONNECTING ? "Finish" : "Cancel"
    const buttonAction = this.state.connecting === State.NOT_CONNECTING ? this.finish.bind(this) : this.cancel.bind(this)

    return (
      <ViewPort>
        {content}
        <SingleButtonFooter buttonText={buttonText} onClick={buttonAction}/>
      </ViewPort>
    );
  }

}

export default Main;

const Title = styled.div`
  color: ${props => props.theme.colors.lightHighContrast};
  flex: 0 0 auto;
  padding-top: 7vh;
  padding-bottom: 7vh;
`;

const InputBoxContainer = styled.div`
  height: 100px;
  width: 80%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BottomGap = styled.div`
  flex: 0 0 auto;
  height: 10vh;
`;

const Indicator = styled.div`
  flex: 0 0 auto;
  height: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

