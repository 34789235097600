import React from "react";
import model from "../../model/model";
import {useParams} from "react-router-dom";
import {getCategoryUiDetails, getDataItemUiDetails} from "./CategoryUiDetails";
import DataForm from "./DataForm";
import {H3Header} from "../../components/Headers";
import {ViewPort} from "../../components/StyledComponents";
import AnonymousIcon from "../../assets/anonymous.png";

class View extends React.Component {

  constructor(props) {
    super(props);
    let params = this.props.params;
    const persona = model.getPersona(params.personaId);
    if (persona === undefined) throw new Error("persona does not exist: " + params.id);
    this.state = {
      persona: persona,
      category: {
        id: params.category,
        uiDetails: getCategoryUiDetails(params.category)
      },
      items: []
    };
    let index = 0;
    if (this.state.category.id === "about") {
      // special condition for 'About' category: add persona icons
      // persona icons are specific to channels and not part of the standard right now
      this.state.items.push({
        index: index++,
        standard: {type: "icons"},
        label: "Channel Icons",
        value: undefined,
        edit: undefined
      });
    }
    const items = model.dataStandard.getItemsInCategory(params.category);
    for (let i=0; i<items.length; i++) {
      this.state.items.push({
        index: index++,
        standard: items[i],
        label: getDataItemUiDetails(items[i].id).title,
        value: undefined,
        edit: undefined
      });
    }
  }

  handleSearchInput(event) {}

  render() {
    const persona = this.state.persona;
    const icon = persona.icons.length > 0 ? persona.icons[0] : {AnonymousIcon};
    return (
      <ViewPort>
        <H3Header title={persona.name} subtitle={this.state.category.uiDetails.name} icon={icon} back={this.props.history.goBack} />
        <DataForm personaId={persona.id} items={this.state.items} />
      </ViewPort>
    );
  };

}

export default (props) => (<View {...props} params={useParams()} />);
