import storage from "./storage";
import {datona} from "./workers";
import {logger} from "../utils/logger";
import {cryptographicUtils, stringUtils} from "../utils/utils";
import Web3 from "web3";
import {DatonaConnectError, InternalError} from "./errors";
import {PrepaidBlockchainGateway} from "./PrepaidBlockchainGateway";

const DATONA_CONNECT_CONTRACT = {
  address: "0xd39A1a26d04a0Af8FF334c6d98c2E4D328F45ece",
  abi: [{"anonymous": false, "inputs": [{"indexed": true, "internalType": "address", "name": "user", "type": "address"}, {"indexed": false, "internalType": "address", "name": "contractAddress", "type": "address"}, {"indexed": true, "internalType": "uint256", "name": "connectCode", "type": "uint256"}], "name": "JoinedChannel", "type": "event"}, {"inputs": [{"internalType": "uint256", "name": "connectCode", "type": "uint256"}, {"internalType": "address", "name": "contractAddress", "type": "address"}, {"internalType": "address", "name": "member1", "type": "address"}, {"internalType": "address", "name": "member2", "type": "address"}], "name": "registerChannel", "outputs": [], "stateMutability": "nonpayable", "type": "function"}],
  bytecode: "608060405234801561001057600080fd5b506102ae806100206000396000f3fe608060405234801561001057600080fd5b506004361061002b5760003560e01c8063cd1beb6914610030575b600080fd5b6100bc6004803603608081101561004657600080fd5b8101908080359060200190929190803573ffffffffffffffffffffffffffffffffffffffff169060200190929190803573ffffffffffffffffffffffffffffffffffffffff169060200190929190803573ffffffffffffffffffffffffffffffffffffffff1690602001909291905050506100be565b005b60008085815260200190815260200160002060009054906101000a900460ff1615610151576040517f08c379a00000000000000000000000000000000000000000000000000000000081526004018080602001828103825260198152602001807f636f6e6e65637420636f646520616c726561647920757365640000000000000081525060200191505060405180910390fd5b600160008086815260200190815260200160002060006101000a81548160ff021916908315150217905550838273ffffffffffffffffffffffffffffffffffffffff167fba6d5b4f67edafc10c15549d2c2a126552b7fd50a8baca60e4bbe9cbc00a16ab85604051808273ffffffffffffffffffffffffffffffffffffffff1673ffffffffffffffffffffffffffffffffffffffff16815260200191505060405180910390a3838173ffffffffffffffffffffffffffffffffffffffff167fba6d5b4f67edafc10c15549d2c2a126552b7fd50a8baca60e4bbe9cbc00a16ab85604051808273ffffffffffffffffffffffffffffffffffffffff1673ffffffffffffffffffffffffffffffffffffffff16815260200191505060405180910390a35050505056fea2646970667358221220a1754ffa2ca0fd389ac1986562a35b5905e69d92e4a84ce5a06dd0974b3fe01364736f6c63430006060033",
  hash: "fd7d7bc83944dffd0d6ae9d02f2dd4eb6f90c3f685fdfb898352162d9ccad007"
}

const INVITE_EXPIRY_PERIOD = 7*86400;  // 7 days
let web3Socket = undefined;

export const DatonaConnect = {
  generateInvite: generateInvite,
  isInviteLink: isInviteLink,
  parseInviteLink: parseInviteLink,
  signInvite: signInvite,
  watchForAcceptance: watchForAcceptance,
  setProvider: setProvider,
  deployChannelContract: deployChannelContract,
  terminateChannelContract: terminateChannelContract
}

// 608060405234801561001057600080fd5b50610310806100206000396000f3fe608060405234801561001057600080fd5b5060043610610053576000357c01000000000000000000000000000000000000000000000000000000009004806317cea7c414610058578063cd1beb6914610086575b600080fd5b6100846004803603602081101561006e57600080fd5b8101908080359060200190929190505050610114565b005b6101126004803603608081101561009c57600080fd5b8101908080359060200190929190803573ffffffffffffffffffffffffffffffffffffffff169060200190929190803573ffffffffffffffffffffffffffffffffffffffff169060200190929190803573ffffffffffffffffffffffffffffffffffffffff1690602001909291905050506101d5565b005b60008082815260200190815260200160002060009054906101000a900460ff16156101a7576040517f08c379a00000000000000000000000000000000000000000000000000000000081526004018080602001828103825260198152602001807f636f6e6e65637420636f646520616c726561647920757365640000000000000081525060200191505060405180910390fd5b600160008083815260200190815260200160002060006101000a81548160ff02191690831515021790555050565b6101de84610114565b838273ffffffffffffffffffffffffffffffffffffffff167fba6d5b4f67edafc10c15549d2c2a126552b7fd50a8baca60e4bbe9cbc00a16ab85604051808273ffffffffffffffffffffffffffffffffffffffff1673ffffffffffffffffffffffffffffffffffffffff16815260200191505060405180910390a3838173ffffffffffffffffffffffffffffffffffffffff167fba6d5b4f67edafc10c15549d2c2a126552b7fd50a8baca60e4bbe9cbc00a16ab85604051808273ffffffffffffffffffffffffffffffffffffffff1673ffffffffffffffffffffffffffffffffffffffff16815260200191505060405180910390a35050505056fea264697066735822122002b2910fa3c9ab3f491ee5ba16b4a326651fec47085f96b212b35db7abd4894464736f6c63430006030033

function setProvider(provider) { console.log(provider);
  const port = provider.port ? ":"+provider.port : "";
  const urlStr = provider.scheme+"://"+provider.host+port;
  logger.log("Datona Connect: using web provider "+urlStr);
  web3Socket = new Web3(new Web3.providers.WebsocketProvider(urlStr));
}

function deployChannelContract(inviteCode, expiryTime, member1Signature, member2Signature) {
  const connectService = "https://datonavault.com:8133"; //storage.select(storage.Tables.SETTINGS, "prePaidBlockchainService");
  const api = new PrepaidBlockchainGateway(connectService+"/createChannel");
  return api.transact([inviteCode, expiryTime, member1Signature, member2Signature]);
}

function terminateChannelContract(contractAddress, key) {
  const packet = _createTerminatePacketForSigning(contractAddress);
  const signature = "0x"+key.sign(datona.crypto.hash(packet));
  const connectService = storage.select(storage.Tables.SETTINGS, "prePaidBlockchainService");
  const api = new PrepaidBlockchainGateway(connectService+"/terminateChannel");
  return api.transact({ contractAddress: contractAddress, params: [signature]});
  //return new Promise((resolve, reject) => {resolve()});
}


function watchForAcceptance(inviteCode, user) {
  if (web3Socket === undefined) throw new Error("Datona Connect: must setProvider before watching for events");

  return new Promise( (resolve, reject) => {
    const datonaConnectContract = new web3Socket.eth.Contract(DATONA_CONNECT_CONTRACT.abi, DATONA_CONNECT_CONTRACT.address);
    logger.logTrace("getting past events for invite "+inviteCode);
    const result = datonaConnectContract.getPastEvents(
      "JoinedChannel",
      { fromBlock: 0, toBlock: 'latest', filter: {connectCode: [inviteCode]} },
      function(error, events) {
        if (error !== undefined && error !== null) {
          console.log("Logging error: ", error);
          reject(new DatonaConnectError("Failed to get past invite acceptance events from the blockchain", error));
        }
        else if (events.length > 2) reject(new DatonaConnectError("Blockchain reports multiple invite acceptance events: "+events.length));
        else if (events.length === 2) { console.log(events);
          // must resolve the event with the other users's address
          logger.logTrace("past event found for invite "+inviteCode);
          if (events[0].returnValues.user.toLowerCase() === user.toLowerCase()) resolve(events[1]);
          else if (events[1].returnValues.user.toLowerCase() === user.toLowerCase()) resolve(events[0]);
          else logger.logWarning("invite accepted but has the wrong user address: "+events[0].returnValues.user+", "+events[1].returnValues.user);
        }
        else {
          logger.logTrace("no past events found for invite "+inviteCode+". Watching for future events");
          datonaConnectContract.events.JoinedChannel(
            { filter: {connectCode: [inviteCode]} },
            function(error, event) {
              if (error) reject(new DatonaConnectError("Failed to watch blockchain for invite acceptance", error));
              else {
                logger.logTrace("blockchain event found for invite "+inviteCode+" and user "+event.user);
                if (event.user !== user) resolve(event);
              }
            });
        }
      });
  });
}

function generateInvite(personaId, key) {
  // Create invite with random inviteCode
  const vaultService = storage.select(storage.Tables.SETTINGS, "preferredVaultService");
  if (vaultService === undefined) throw new InternalError("preferredVaultService is missing from Settings");
  const now = Math.round(Date.now()/1000);
  const invite = {
    inviteCode: Math.random(),
    originator: personaId,
    created: now,
    expiryTime: now+INVITE_EXPIRY_PERIOD,
    vault: {
      url: vaultService.url,
      id: vaultService.id
    }
  }
  invite.inviteCode = "0x"+datona.crypto.hash(JSON.stringify(invite)).substr(0,32);
  logger.logTrace("generated new invite: "+JSON.stringify(invite));
  const link = _createInviteLink(invite, key);
  return { invite: invite, link: link };
}


function isInviteLink(link) {
  return link.indexOf("https://datona.io/connect/") === 0;
}

function parseInviteLink(link) {
  let url;
  try {
    url = new URL(link);
  }
  catch(err) {
    logger.logError("invalid link", err);
    throw new InternalError("invalid link", { error: err });
  }
  let vaultUrl;
  try {
    vaultUrl = new URL(decodeURIComponent(url.searchParams.get("vaultUrl")));
  }
  catch(err) {
    logger.logError("invalid vaultUrl", err);
    throw new InternalError("invalid vaultUrl parameter", { error: err });
  }
  const result = {
    inviteCode: stringUtils.base58ToHex(url.searchParams.get("inviteCode")),
    expiryTime: parseInt(stringUtils.base58ToHex(url.searchParams.get("expiryTime")), 16),
    vault: {
      url: {
        scheme: vaultUrl.protocol.substr(0, vaultUrl.protocol.length-1),
        host: vaultUrl.hostname,
        port: parseInt(vaultUrl.port)
      },
      id: stringUtils.base58ToHex(url.searchParams.get("vaultKey"))
    },
    signature: stringUtils.base58ToHex(url.searchParams.get("signature"))
  };
  if (result.signature === undefined) throw new InternalError("missing signature parameter");
  const invitePacket = _createConnectPacketForSigning(result.inviteCode, result.expiryTime);
  result.originator = datona.crypto.recover(datona.crypto.hash(invitePacket), result.signature.substr(2));
  if (result.inviteCode === undefined) throw new InternalError("missing inviteCode parameter");
  if (result.inviteCode.length !== 34) throw new InternalError("invalid inviteCode parameter length - expected 32, received "+result.inviteCode.length);
  if (result.vault.url.scheme === undefined) throw new InternalError("missing vaultUrl protocol");
  if (result.vault.url.host === undefined) throw new InternalError("missing vaultUrl host");
  if (result.vault.id === undefined) throw new InternalError("missing vaultKey parameter");
  if (result.vault.id.length !== 42) throw new InternalError("invalid vaultKey parameter");
  if (!datona.assertions.isAddress(result.originator)) throw new InternalError("invalid signature");
  return result;
}


function signInvite(invite, key) {
  const invitePacket = _createConnectPacketForSigning(invite.inviteCode, invite.expiryTime);
  return "0x"+key.sign(datona.crypto.hash(invitePacket));
}

//
// Private methods
//

function _createInviteLink(invite, key) {
  const signature = signInvite(invite, key);
  return "https://datona.io/connect/"+
    "?vaultUrl="+encodeURIComponent(invite.vault.url.scheme+"://"+invite.vault.url.host+":"+invite.vault.url.port)+
    "&vaultKey="+stringUtils.hexToBase58(invite.vault.id.substr(2))+
    "&inviteCode="+stringUtils.hexToBase58(invite.inviteCode)+
    "&expiryTime="+stringUtils.hexToBase58(invite.expiryTime.toString(16))+
    "&signature="+stringUtils.hexToBase58(signature);
}


function _createConnectPacketForSigning(inviteCode, expiryTime){
  const PREFIX = 'Datona Connect';
  const packet = new Uint8Array(PREFIX.length+64);
  packet.set(cryptographicUtils.textToByteArray(PREFIX, PREFIX.length), 0);
  packet.set(cryptographicUtils.hexToByteArray(inviteCode, 32), PREFIX.length);
  packet.set(cryptographicUtils.uintToByteArray(expiryTime, 32), PREFIX.length+32);
  return packet;
}

function _createTerminatePacketForSigning(contractAddress){
  const PREFIX = 'terminate';
  const packet = new Uint8Array(PREFIX.length+20);
  packet.set(cryptographicUtils.textToByteArray(PREFIX, PREFIX.length), 0);
  packet.set(cryptographicUtils.hexToByteArray(contractAddress, 20), PREFIX.length);
  return packet;
}
