import React from "react";
import EmailView from "./EmailView";
import model from "../../model/model";
import {useParams} from "react-router-dom";
import ChannelView from "./ChannelView";
import {SubscriptionEvent} from "../../model/workers";
import AnonymousIcon from "../../assets/anonymous.png";

class Channel extends React.Component {

  constructor(props) {
    super(props);
    let params = this.props.params;
    const channelId = {contractAddress: params.contractAddress, channelIndex: parseInt(params.channelIndex)};
    const channel = model.getChannel(channelId.contractAddress, channelId.channelIndex);
    const nameAndIcon = this.getNameAndIcon(channel);
    if (channel === undefined) throw new Error("channel does not exist: " + params.contractAddress + "/" + params.channelIndex);
    this.state = {
      post: undefined,
      channelId: channelId,
      channel: channel,
      name: nameAndIcon.name,
      icon: nameAndIcon.icon
    };
    if (channel.lastReadPost === undefined || channel.lastMessageTime > channel.lastReadPost) model.setLastPostRead(channelId, channel.lastMessageTime);
  }

  getNameAndIcon(channel) {
    const name = channel.name ? channel.name : "Anonymous";
    let icon = AnonymousIcon;
    let found = false;
    for (let i=0; !found && i<channel.participants.length; i++) {
      if (channel.participants[i].id !== channel.persona) {
        icon = channel.participants[i].icon;
        found = true;
      }
    }
    return {name: name, icon: icon};
  }

  componentDidMount() {
    if (!this.subscriptionId) {
      this.subscriptionId = this.state.channel.subscribe(this.handleChannelEvent.bind(this));
    }
  }

  componentWillUnmount() {
    this.state.channel.unsubscribe(this.subscriptionId);
    this.subscriptionId = undefined;
  }

  handleChannelEvent(event) {
    // only interested in the updated metadata events
    if (event === SubscriptionEvent.METADATA_UPDATED ) {
      const channel = model.getChannel(this.state.channel.contractAddress, this.state.channel.channelIndex);
      const nameAndIcon = this.getNameAndIcon(channel);
      this.setState({
        channel: channel,
        name: nameAndIcon.name,
        icon: nameAndIcon.icon
      });
    }
    if (event === SubscriptionEvent.NEW_POSTS) {
      // mark the post as read to clear the new post indicator
      const channel = model.getChannel(this.state.channel.contractAddress, this.state.channel.channelIndex);
      model.setLastPostRead(this.state.channelId, channel.lastMessageTime);
    }
  }

  displayEmail(item) {
    this.setState({ post: item });
  }

  displayChannel() {
    this.setState({ post: undefined });
  }

  render() {
    const view = this.state.post === undefined ? "channel" : this.state.post.type;
    switch (view) {
      case "email":
        return <EmailView channel={this.state.channel} name={this.state.name} icon={this.state.icon} post={this.state.post} history={this.props.history} />
      default:
        return <ChannelView channel={this.state.channel} name={this.state.name} icon={this.state.icon} postClickHandler={this.displayEmail.bind(this)} history={this.props.history}/>;
    }
  }

}

export default (props) => (<Channel {...props} params={useParams()} />);

