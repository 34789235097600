import React from "react";
import styled from "styled-components";
import SelectionItem from "../../components/SelectionItem";
import model from "../../model/model";
import {Link, useParams} from "react-router-dom";
import {getCategoryUiDetails} from "./CategoryUiDetails";
import {BackButton, Header, Icon, Line, ScrollPort, Title, TitleRow, ViewPort} from "../../components/StyledComponents";
import {logger} from "../../utils/logger";
import AnonymousIcon from "../../assets/anonymous.png";
import BackArrowIcon from "../../assets/Back-Arrow.png";
import EditIcon from "../../assets/icons/edit.png";

class View extends React.Component {

  constructor(props) {
    super(props);
    let params = this.props.params;
    const persona = model.getPersona(params.id);
    if (persona === undefined) throw new Error("persona does not exist: " + params.id);
    this.state = {
      persona: persona,
      editName: false
    };
    this.nameInput = React.createRef();
  }

  toggleTitleEdit() {
    if (this.state.editName) this.saveTitle()
    else {
      this.nameInput.current.value = this.state.persona.name;
      this.nameInputValue = this.state.persona.name;
      this.setState({editName: true});
    }
  }

  saveTitle() {
    try {
      model.setPersonaName(this.state.persona.id, this.nameInput.current.value);
    }
    catch(err) {
      logger.logError(err.message);
    }
    this.setState({editName: false});
  }

  handleEditTitleKeyup(event) {
    if (event.keyCode === 13) this.saveTitle();
  }

  componentDidMount(){
    if (this.state.editName) this.nameInput.current.focus();
  }

  render() {
    const persona = this.state.persona;
    const icon = persona.icons.length > 0 ? persona.icons[0] : AnonymousIcon;
    let key = 0;
    const categoryList = model.dataStandard.categories.map(item => {
      const details = getCategoryUiDetails(item);
      return <SelectionItem key={key++} title={details.name} summary={details.summary} icon={details.icon} route={this.state.editName ? null : "/personas/persona/data/"+persona.id+"/"+item} />
    });
    return (
      <ViewPort>
        <Header style={{height: 48}}>
          <TitleRow>
            <Link to="/personas"><BackButton src={BackArrowIcon}/></Link>
            <EditableTitle>
              <EditTitle ref={this.nameInput} className="h2" hidden={!this.state.editName} onKeyUp={this.handleEditTitleKeyup.bind(this)} onBlur={this.toggleTitleEdit.bind(this)}/>
              <Title className="h2" hidden={this.state.editName}>{persona.name}</Title>
              <EditButton src={EditIcon} hidden={this.state.editName} onClick={this.toggleTitleEdit.bind(this)}/>
            </EditableTitle>
            <Icon src={icon}/>
          </TitleRow>
          <Line/>
        </Header>
        <ScrollPort>
          {categoryList}
        </ScrollPort>
      </ViewPort>
    );
  };

}

export default (props) => (<View {...props} params={useParams()} />);



const EditableTitle = styled.div`
  flex: 1 1;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EditTitle = styled.input`
  border: 0;
  outline: none;
  background: ${props => props.theme.colors.lightBackground};
  color: ${props => props.theme.colors.lightHighContrast};
`;

const EditButton = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 12px;
`;
