import storage from "../storage";
import {datona} from "../workers";
import {bip39ToKey} from "../bip39";
import {logger} from "../../utils/logger";
const bip32 = require('bip32');

export const Identities = {
  getPublicIdentity: getPublicIdentity,
  generatePublicId: generatePublicId,
  validatePublicId: validatePublicId,
  validatePrivateKey: validatePrivateKey,
  getPrivateKey: getPrivateKey,
  getChildKey: getChildKey,
  createRandomIdentity: createRandomIdentity,
  createIdentity: createIdentity
}


//
// Public Identities
//

function getPublicIdentity(id) {
  return storage.select(storage.Tables.PUBLIC_IDS, id);
}

function generatePublicId(personaId) {
  if (!personaId) throw new Error("Cannot generate public id for empty persona id");
  return "https://datona.io/id/eth/"+personaId
}

function validatePublicId(id) {
  // TODO
  return undefined;
}


//
// Private Identities
//

function validatePrivateKey(keyOrPhrase) {
  try {
    _toPrivateKey(keyOrPhrase)
    return undefined;
  }
  catch (err) {
    return err.message;
  }
}

function getPrivateKey(persona) {
  const identity = storage.select(storage.Tables.IDENTITIES, persona.parentId);
  if (identity === undefined) throw new Error("Identity not found for persona "+persona.parentId)
  return getChildKey(identity, persona.derivationPath);
}

function getChildKey(identity, derivationPath) {
  let bip32Node = bip32.fromPrivateKey(datona.crypto.hexToUint8Array(identity.privateKey), datona.crypto.hexToUint8Array(identity.chaincode));
  const childKey = bip32Node.derivePath(derivationPath);
  return new datona.crypto.Key(datona.crypto.uint8ArrayToHex(childKey.privateKey));
}

function createRandomIdentity() {
  // TODO use decent random number generator and accept entropy from UI
  logger.logTrace("create random identity");
  createIdentity(datona.crypto.uint8ArrayToHex(datona.crypto.generateKey().privateKey));

}

function createIdentity(keyOrPhrase) {
  logger.logTrace("create identity");
  const entropy = new Uint8Array(32);
  window.crypto.getRandomValues(entropy);
  const key = _toPrivateKey(keyOrPhrase);
  const newIdentity = {
    id: key.address,
    privateKey: datona.crypto.uint8ArrayToHex(key.privateKey),
    chaincode: datona.crypto.uint8ArrayToHex(entropy)
  };
  storage.insert(storage.Tables.IDENTITIES, newIdentity);
  logger.log("created identity "+newIdentity.id);
  // TODO import contracts, backup and data from the blockchain and vaults
}


//
// Private functions
//

function _toPrivateKey(keyOrPhrase) {
  keyOrPhrase = keyOrPhrase.trim();
  if (datona.assertions.isPrivateKey(keyOrPhrase)) return new datona.crypto.Key(keyOrPhrase);
  const numberOfWords = keyOrPhrase.split(/\s+/).length;
  if (numberOfWords === 1) throw new Error("Private key is invalid");
  if (numberOfWords > 1 && numberOfWords < 24) throw new Error("Phrase is invalid: too few words");
  if (numberOfWords > 24) throw new Error("Phrase is invalid: too many words");
  return new datona.crypto.Key(datona.crypto.uint8ArrayToHex(bip39ToKey(keyOrPhrase)));
}

