import React from "react";
import model from "../../model/model";
import TextArea from "../../components/TextArea";
import SingleButtonFooter from "../../components/SingleButtonFooter";
import {
  CenteredContent,
  ErrorMessage,
  ViewPort
} from "../../components/StyledComponents";
import {H3Header} from "../../components/Headers";

class View extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rawId: "",
      validationResult: undefined
    };
  }

  handleId(event) {
    this.setState({ rawId: event.target.value, validationResult: undefined });
  }

  importId() {
    const validationResult = model.validatePrivateKey(this.state.rawId);
    if (validationResult) this.setState({ validationResult: validationResult });
    else {
      model.createIdentity(this.state.rawId);
      this.props.history.push("/settings");
    }
  }

  render() {
    return (
      <ViewPort>
        <H3Header title="Settings" subtitle="Import An Identity" back={this.props.history.goBack} />
        <CenteredContent>
          <p className="h5">This will restore an existing identity into this app.  You can have multiple identities controlled by this app - they will appear as separate personas.</p>
          <div>
            <p className="h5">Enter your 24-word backup passphrase or private key below.</p>
            <br/>
            <TextArea value={this.state.rawId} onInput={this.handleId.bind(this)} textAlign="center" height="126px" bgColor="white" />
            <ErrorMessage text={this.state.validationResult} />
          </div>
          <p className="h5">If the identity has been backed up to the cloud it’s personas and data will automatically be imported into this app.</p>
        </CenteredContent>
        <SingleButtonFooter buttonText="Import" onClick={this.importId.bind(this)} />
      </ViewPort>
    );
  };

}

export default View;

