import {ApplicationError, DataRequestError, DatonaConnectError, UserError} from "../errors";
import axios from "axios";
import {cryptographicUtils, stringUtils} from "../../utils/utils";
import * as datona from "datona-lib";
import storage from "../storage";
import {logger} from "../../utils/logger";
import {PrepaidBlockchainGateway} from "../PrepaidBlockchainGateway";

export const Requests = {
//  isDataRequest: isDataRequest,
  receiveRequest: receiveRequest
}
// http://77.68.75.133:8125/requests/shares
class DataRequest {

  constructor(request) {
    if (request.datonaVersion === undefined) throw new DataRequestError("request is invalid");
    if (request.datonaVersion !== "0.1") throw new DataRequestError("data request version "+request.datonaVersion+" is not supported", request);
    if (request.requestType !== "smart data access") throw new DataRequestError("request is not a smart data access request", request);
    this.request = request;
    this.requestData = {
      request: request,
      requester: this._getRequester(),
      requesters: request.requesters,
      primaryPurposes: this._getPrimaryPurposes(),
      dataItems: this._getDataItemList(),
      files: this._getFileList(),
      rating: this._getRating(),
      maxDuration: request.maxDuration,
      warnings: this._getWarnings(),
      restrictions: request.restrictions,
      fullTerms: request.fullTerms
    }
    this.requestData.exposure = this._getDataExposure();
    this.requestData.summary = this._getSummary(this.requestData.requesters[0], this.requestData.primaryPurposes);
    this.requestData.id = datona.crypto.hash(JSON.stringify(this.requestData));
  }

  getDataRequest() { return this.requestData }

  _getRequester() {
    const request = this.request;
    let requester;
    for (let i=0; i<request.requesters.length; i++) {
      if (request.requesters[i].primary) {
        if (requester) throw new DataRequestError("a request can only have one primary requester", request);
        requester = request.requesters[i];
      }
    }
    if (!requester) throw new DataRequestError("a request must have one primary requester", request);
    return requester;
  }

  _getPrimaryPurposes() {
    const request = this.request;
    let purposes = [];
    for (let i=0; i<request.purposes.length; i++) {
      if (request.purposes[i].primary) purposes.push(request.purposes[i]);
    }
    if (purposes.length === 0) throw new DataRequestError("a request must have at least one primary purpose", request);
    return purposes;
  }

  _getPurposes(requesterIndex) {
    const request = this.requestData;
    let purposes = [];
    if (request.dataItems === undefined) throw new DataRequestError("a request must have at least one data item", request);
    for (let i=0; i<request.dataItems.length; i++) {
      const item = request.dataItems[i];
      if (item.requester === requesterIndex) {
        let found = false;
        for (let j=0; !found && j<purposes.length; j++) {
          if (purposes[i] === item.purpose) found = true;
        }
        if (!found) {
          purposes.push({
            ...request.purposes[item.purpose],
            purposeIndex: item.purpose
          });
        }
      }
    }
    return purposes;
  }

  _getSummary(requester, purposes) {
    const plural = purposes.length === 1 ? "" : "s";
    let purposeStr = "";
    for (let i=0; i<purposes.length; i++) {
      purposeStr += stringUtils.capitaliseAllWords(purposes[i].summary);
      purposeStr += i === purposes.length-1 ? "" : i === purposes.length-2 ? " and " : ", ";
    }
    return requester.knownAs + " requests the following data for the purpose"+plural+" of "+purposeStr+".";
  }

  _getDataItemList() {
    const request = this.request;
    const items = [];
    request.requestedData.forEach( item => {
      let found = false;
      for (let i=0; i<items.length; i++) {
        if (item.dataItem === items[i]) found = true;
      }
      if (!found) items.push(item.dataItem);
    });
    if (items.length === 0) throw new DataRequestError("a request must have at least one data item", request);
    return items;
  }

  _getFileList() {
    const request = this.request;
    const items = [];
    request.requestedData.forEach( item => {
      if (item.file || item.directory) items.push({ itemId: item.dataItem, file: item.file, directory: item.directory });
    });
    if (items.length === 0) throw new DataRequestError("a request must have at least one data item to share", request);
    return items;
  }

  _getDataItems(requester, purpose) {
    const request = this.request;
    const items = [];
    request.requestedData.forEach( item => {
      let found = false;
      for (let i=0; i<items.length; i++) {
        if (item.dataItem === items[i].dataItem) found = true;
      }
      if (!found) {
        let passFilter = requester === undefined || requester === item.requester;
        passFilter &= purpose === undefined || purpose === item.purpose;
        if (passFilter) items.push(item);
      }
    });
  }

  _getRating() {
    // TODO - get rating from the blockchain?
    return {
      stars: 4,
      warnings: 2
    }
  }

  _getWarnings() {
    const request = this.request;
    let result = [];
  }

  _getDataExposure() {
    const request = this.request;
    const result = [];
    for (let i=0; i<request.requesters.length; i++) {
      const purposes = this._getPurposes(request, i);
      const exposures = [];
      for (let j=0; j<purposes.length; j++) {
        exposures.push({
          purpose: purposes[j],
          dataItems: this._getDataItems(request, i, j)
        });
      }
      result.push({
        requester: request.requesters[i],
        exposure: exposures
      })
    }
    return result;
  }

}


export function receiveRequest(str) {
  try {
    const requestObj = JSON.parse(str);
    return new Promise( resolve => { resolve(new DataRequest(requestObj).getDataRequest()) });
  }
  catch(err) {
    if (err instanceof ApplicationError) throw err;
    // otherwise str is not JSON so assume it is a URL containing a request
    return axios.get(str)
      .then( (request) => { return new DataRequest(request.data).getDataRequest() })
  }
}


export function approveRequest(request, personaId, key) {
  // check all requested data is available and ready the data
  const dataToShare = [];
  request.dataItems.forEach( item => {
    const data = storage.select(storage.Tables.PERSONA_DATA, { personaId: personaId, id: item.id });
    if (!data) throw new UserError("data missing");
    dataToShare.push({ itemId: item.id, data: data });
  });
  logger.log("approved request "+request.id);
  // deploy contract
  const nonce = request.id;
  const expiryTime = Date.now()/1000 + storage.select(storage.Tables.SETTINGS, "request-expiry-period");
  const packet = _createPacketForSigning(request.request.contractSourceCode.prefix, nonce, expiryTime);
  const signature = "0x"+key.sign(datona.crypto.hash(packet));
  const api = new PrepaidBlockchainGateway(request.request.prepaidBlockchainGateway);
  return api.transact(nonce, expiryTime, signature)
    .then( contractAddress => {
      if (!datona.assertions.isAddress(contractAddress)) throw new DataRequestError("invalid response from pre-paid blockchain gateway", request.request);
      // TODO check deployed contract is the correct one
      // TODO create vault
      // TODO create channel objects and workers
    })


}


function _createPacketForSigning(prefix, nonce, expiryTime){
  const packet = new Uint8Array(prefix.length+64)
  packet.set(cryptographicUtils.textToByteArray(prefix, prefix.length), 0);
  packet.set(cryptographicUtils.hexToByteArray(nonce, 32), prefix.length);
  packet.set(cryptographicUtils.uintToByteArray(expiryTime, 32), prefix.length+32);
  return packet;
}
