import DataStandard from "./dataStandard";
import {logger} from "../utils/logger";
import {DatonaConnect} from "./DatonaConnect";
import storage from "./storage";
import {datona} from "./workers";
import {Personas} from "./modelComponents/personas";
import {Identities} from "./modelComponents/identities";
import {Channels} from "./modelComponents/channels";
import {ApplicationError} from "./errors";
import {Requests} from "./modelComponents/requests";
import CloudIcon from "../assets/icons/download-cloud.png";


//
// Datona Vault queries needed:
//   - getPermissions on list of directories
//   - get contracts I'm participating in (active and/or inactive).  Return address and request data (or hash?) for each.
//   - get events for list of contracts
//   - get public identity (inc. attestations) for list of public keys
//


storage.open();  // TODO only open when PIN is given
//storage.del(storage.Tables.CHANNELS, {contractAddress: "0xA9C205f0E6130bE7b1965d172DAb4c75b59CE3AE", channelIndex: 0});

// Delete old images to make space in local storage.
const posts = storage.select(storage.Tables.POSTS, '*');
const yesterday = Date.now() - 48*60*60*1000;
let found = false;
posts.forEach( post => {
  if (post.type === "image" && post.time < yesterday && post.image && post.image.length > 50) {
    logger.logDebug("deleting image in post at time "+post.time);
    post.image = CloudIcon;
    found = true;
  }
})
if (found) {
  storage.close();
  storage.open();
}

datona.blockchain.setProvider(storage.select(storage.Tables.SETTINGS, "blockchainProvider"));
DatonaConnect.setProvider(storage.select(storage.Tables.SETTINGS, "blockchainEventProvider"))
Channels.startWorkers();

const model = {

  dataStandard: DataStandard,
  ChannelState: Channels.States,

  getAppState: getAppState,
  isAuthenticated: isAuthenticated,
  setAppState: setAppState,
  setLastPostRead: Channels.setLastPostRead,
  createIdentity: Identities.createIdentity,
  createRandomIdentity: Identities.createRandomIdentity,
  generatePublicId: Identities.generatePublicId,
  validatePublicId: Identities.validatePublicId,
  validatePrivateKey: Identities.validatePrivateKey,
  getPersonas: Personas.getPersonas,
  getPersona: Personas.getPersona,
  createPersona: Personas.createPersona,
  setPersonaName: Personas.setPersonaName,
  setPersonaIcons: Personas.setPersonaIcons,
  setPersonaDetails: Personas.setPersonaDetails,
  getDataItem: Personas.getDataItem,
  setDataItem: Personas.setDataItem,
  getChannels: Channels.getChannels,
  getChannel: Channels.getChannel,
  getPosts: Channels.getPosts,
  subscribeToAllChannels: Channels.subscribe,
  unsubscribeFromAllChannels: Channels.unsubscribe,
  isChannelInvite: Channels.isInvite,
  validateChannelInvite: Channels.validateInviteLink,
  terminateContract: terminateContract,
  receiveRequest: Requests.receiveRequest,
  approveRequest: approveRequest,
  post: Channels.post,
  receiveChannelInvite: receiveInvite,
  validatePIN: validatePIN,
  setPIN: setPIN,
  generateInviteLink: generateInviteLink
}

function getAppState() {
  return storage.select(storage.Tables.SETTINGS, "appState");
}

function generateInviteLink(personaId, knownAs, icon) {
  const persona = Personas.getPersona(personaId);
  const key = Identities.getPrivateKey(persona);
  return Channels.generateInviteLink(persona, knownAs, icon, key);
}

function receiveInvite(inviteStr, personaId, knownAs, icon) {
  const persona = Personas.getPersona(personaId);
  const key = Identities.getPrivateKey(persona);
  return Channels.receiveInvite(inviteStr, personaId, key, knownAs, icon);
}

function approveRequest(request, personaId) {
  const persona = Personas.getPersona(personaId);
  const key = Identities.getPrivateKey(persona);
  return Requests.approveRequest(request, personaId, key);
}

function terminateContract(contractAddress, personaId) {
  logger.log("terminating contract "+contractAddress);
  const persona = Personas.getPersona(personaId);
  const key = Identities.getPrivateKey(persona);
  return Channels.terminateContract(contractAddress, key);
}

function setAppState(state) {
  storage.update(storage.Tables.SETTINGS, "appState", state)
}

function setPIN(currentPIN, newPIN) {
  logger.logTrace("setPIN");
  if (newPIN !== null) {
    const validationResult = validatePIN(newPIN);
    //if (privateData.pinHash !== undefined && datona.crypto.hash(currentPIN) !== privateData.pinHash) throw "current PIN is incorrect";
    if (validationResult !== undefined) throw validationResult;
  }
  try {
    //privateData.pinHash = datona.crypto.hash(newPIN);
    saveModel();
  }
  catch(err) {
    // TODO
    throw new ApplicationError(err);
  }
}

function validatePIN(pin) {
  if (typeof pin !== "string" || pin.length < 4) return "PIN must be at least 4 digits long";
  logger.logTrace("PIN valid");
  return undefined;
}

function isAuthenticated() {
  return true; // TODO
}

function saveModel() {
  // TODO
}

function loadModel() {
  // TODO
}

loadModel();

export default model;
