import storage from "../storage";
import {logger} from "../../utils/logger";
import {ChannelState, ChannelTasks, ChannelWorker, datona, InviteWorker, SubscriptionEvent} from "../workers";
import {DatonaConnect} from "../DatonaConnect";
import {createPrivateChannelRequest} from "../requestStandard";
import {ApplicationError, DatonaConnectError, InternalError} from "../errors";
import {Identities} from "./identities";
import {Personas} from "./personas";

let inviteWorkers = [];
let channelWorkers = [];
let subscribers = [];
let subscriptionId = 0;

export const Channels = {
  States: ChannelState,
  startWorkers: startWorkers,
  getChannels: getChannels,
  getChannel: getChannel,
  getPosts: getPosts,
  setLastPostRead: setLastPostRead,
  isInvite: DatonaConnect.isInviteLink,
  validateInviteLink: validateInviteLink,
  generateInviteLink: generateInviteLink,
  receiveInvite: receiveInvite,
  post: post,
  subscribe: subscribe,
  unsubscribe: unsubscribe,
  terminateContract: terminateContract
}

function startWorkers() {
  // Channels
  const channels = getChannels();
  for (let i=0; i<channels.length; i++) {
    const channel = channels[i];
    if (channel.state !== ChannelState.CLOSED) {
      const persona = Personas.getPersona(channel.persona);
      if (!persona) throw new InternalError("Channel has persona that does not exist: "+channel.persona);
      const key = Identities.getPrivateKey(persona);
      const worker = new ChannelWorker(channel, key);
      worker.subscribe(handleChannelWorkerEvent);
      channelWorkers.push(worker);
    }
  }
  // Invites
  const invites = storage.select(storage.Tables.INVITES, '*');
  for (let i=0; i<invites.length; i++) {
    const invite = invites[i];
    const now = Math.round(Date.now()/1000);
    if (invite.expiryTime <= now) {
      storage.del(storage.Tables.INVITES, invite.inviteCode);
      logger.log("invite "+invite.inviteCode+" has expired without acceptance.  Deleting.");
    }
    else {
      const worker = new InviteWorker(invite, handleInviteAccepted);
      inviteWorkers.push(worker);
    }
  }
}

function getChannels() {
  let result = [];
  const channels = storage.select(storage.Tables.CHANNELS, '*');
  channels.forEach( (channel) => result.push(_constructChannelObject(channel)) );
  return result.sort((a, b) => { return b.lastMessageTime - a.lastMessageTime });
}

function getChannel(contractAddress, channelIndex) {
  logger.logTrace("getChannel: "+contractAddress +", "+ channelIndex);
  const channel = storage.select(storage.Tables.CHANNELS, {contractAddress: contractAddress, channelIndex: channelIndex});
  if (channel === undefined) {
    logger.logError("channel does not exits: "+contractAddress+", "+channelIndex);
    return undefined;
  }
  return _constructChannelObject(channel);
}

function setLastPostRead(channelId, time) {
  const channel = storage.select(storage.Tables.CHANNELS, channelId);
  channel.lastReadPost = time;
  storage.update(storage.Tables.CHANNELS, channelId, channel);
}


//
// Invite Process
//

function validateInviteLink(link) {
    logger.logTrace("validating invite link: "+link);
    try {
      DatonaConnect.parseInviteLink(link);
      return true;
    }
    catch(err) {
      logger.logError("invalid invite link: "+err.message, err);
      return false;
    }
 }


function generateInviteLink(persona, knownAs, icon, key) {
  const inviteAndLink = DatonaConnect.generateInvite(persona.id, key);
  const invite = inviteAndLink.invite;
  invite.personaData = {
    name: knownAs,
    icon: icon
  };
  // save new invite and watch for acceptance in case it is accepted
  storage.insert(storage.Tables.INVITES, invite);
  const worker = new InviteWorker(invite, handleInviteAccepted);
  inviteWorkers.push(worker);
  // return the invite in url form
  return inviteAndLink.link;
}


function handleInviteAccepted(invite, contractAddress, acceptedBy) {
  try {
    // TODO check contract bytecode is correct
    const persona = storage.select(storage.Tables.PERSONAS, invite.originator);
    console.log(persona);
    if (!persona) throw new InternalError("Invite accepted but persona does not exist (or no longer exists): " + invite.originator);
    const key = Identities.getPrivateKey(persona);
    if (!key) throw new InternalError("Invite accepted but key for persona cannot be constructed: " + invite.originator);
    const channelId = createChannelRecords(invite.originator, acceptedBy, contractAddress, invite.vault, invite.personaData.name, invite.personaData.icon);
    const channelData = getChannel(channelId.contractAddress, channelId.channelIndex);
    const id = Date.now();
    storage.insert(storage.Tables.CHANNEL_TASKS, {channelId: channelId, task: ChannelTasks.WRITE_METADATA, id: id});
    storage.insert(storage.Tables.CHANNEL_TASKS, {
      channelId: channelId,
      task: ChannelTasks.READ_METADATA,
      participantIndex: 1,
      id: id + 1
    });
    storage.insert(storage.Tables.CHANNEL_TASKS, {channelId: channelId, task: ChannelTasks.OPEN_CHANNEL, id: id + 2});
    const worker = new ChannelWorker(channelData, key);
    worker.subscribe(handleChannelWorkerEvent);
    channelWorkers.push(worker);
    storage.del(storage.Tables.INVITES, invite.inviteCode);
  }
  catch(error) {
    logger.logError(error.message, error);
    try {
      storage.del(storage.Tables.INVITES, invite.inviteCode);
    }
    catch(error) {
      logger.logError(error.message, error);
    }
  }
}


function receiveInvite(inviteStr, personaId, key, knownAs, icon) {
  logger.logTrace("received invite link: "+inviteStr);
  return new Promise( (resolve, reject) => {
    try {
      const invite = DatonaConnect.parseInviteLink(inviteStr);
      logger.log("create channel from invite: " + JSON.stringify(invite));
      resolve( _createChannelFromReceivedInvite(key, personaId, invite, knownAs, icon) );
    }
    catch(err) {
      logger.logError("invalid invite", err);
      reject(err);
    }
  });
}


function createChannelRecords(personaId, recipientId, contractAddress, vault, knownAs, icon) {
  const request = createPrivateChannelRequest(personaId, recipientId, contractAddress);
  const channelRecord = {
    contractAddress: contractAddress,
    channelIndex: 0,
    persona: personaId,
    vault: vault,
    icons: [undefined],
    participants: [
      { id: personaId, name: knownAs, icon: icon, folder: personaId.toLowerCase(), lastFetchTime: 0 },
      { id: recipientId, name: "Anonymous", icon: undefined, folder: recipientId.toLowerCase(), lastFetchTime: 0 }
    ],
    lastReadPost: 0,
    state: "new"
  };
  logger.logTrace("creating channel with: ");
  logger.logTrace("channelRecord=");
  logger.logTrace(channelRecord);
  logger.logTrace("request=");
  logger.logTrace(request);
  storage.insert(storage.Tables.REQUESTS, request);
  storage.insert(storage.Tables.CHANNELS, channelRecord);
  return {contractAddress: channelRecord.contractAddress, channelIndex: channelRecord.channelIndex};
}


function post(channelId, postData) {
  postData.time = Date.now();
  let originalContent;
  // TODO only include thumbnail in post. Load original content on full screen view.
  // if (postData.type === "image") {
  //   originalContent = postData.image;
  //   postData.image = imageUtils.resize(originalContent, 600, 600);
  // }
  const id = datona.crypto.hash(JSON.stringify(postData));
  const post = {
      ...postData,
      id: id,
      contractAddress: channelId.contractAddress,
      channelIndex: channelId.channelIndex,
      source: 0,
      pending: true
    };
  storage.insert(storage.Tables.POSTS, post);
  setLastPostRead(channelId, postData.time);
  _getChannelWorker(channelId).writePost(id, postData);
  if (originalContent) _getChannelWorker(channelId).writePost(id+"-content", originalContent);
}


function getPosts(channelId, amount) {
  const it = new _ReversePostIterator(channelId.contractAddress, channelId.channelIndex);
  const posts = [];
  let count = 0;
  while (count++ < amount && it.hasNext()) {
    posts.unshift(it.next());
  }
  return posts.sort( (a,b) => { return a.time - b.time } );
  // TODO need more efficient sorting/storage?
}


//
// Subscription Service
//

function subscribe(handler) {
  const id = subscriptionId++;
  subscribers.push({
    id: id,
    handler: handler
  });
  return id;
}


function unsubscribe(id) {
  let i=-1;
  let found = false;
  while (++i < subscribers.length && !found) {
    if (subscribers[i].id === id) {
      subscribers.splice(i,1);
      found = true;
    }
  }
  return found;
}

function handleChannelWorkerEvent(event) {
  switch (event) {
    case SubscriptionEvent.CHANNEL_OPEN:
    case SubscriptionEvent.CHANNEL_CLOSED:
    case SubscriptionEvent.CHANNEL_UNAVAILABLE:
    case SubscriptionEvent.CHANNEL_AVAILABLE:
    case SubscriptionEvent.METADATA_UPDATED:
    case SubscriptionEvent.NEW_POSTS:
      _informSubscribers(event);
      break;
    default:
      // do nothing
  }
}

function _informSubscribers(event, data) {
  subscribers.forEach( (subscriber) => {
    subscriber.handler(event, data);
  })
}


//
// Private functions
//

function _createChannelFromReceivedInvite(key, personaId, invite, knownAs, icon) {
  const connectPacketSignature = DatonaConnect.signInvite(invite, key);
  return DatonaConnect.deployChannelContract(invite.inviteCode, invite.expiryTime, connectPacketSignature, invite.signature)
    .then( (contractAddress) => {
      return createChannelRecords(personaId, invite.originator, contractAddress, invite.vault, knownAs, icon);
    })
    .then((channelId) => {
      const openPost = {
        type: "event",
        content: "Channel Opened"
      };
      const id = Date.now();
      storage.insert(storage.Tables.CHANNEL_TASKS, {channelId: channelId, task: ChannelTasks.CREATE_VAULT, id: id});
      storage.insert(storage.Tables.CHANNEL_TASKS, {channelId: channelId, task: ChannelTasks.WRITE_METADATA, id: id+1});
      storage.insert(storage.Tables.CHANNEL_TASKS, {channelId: channelId, task: ChannelTasks.POST, id: id+2, post: openPost});
      storage.insert(storage.Tables.CHANNEL_TASKS, {channelId: channelId, task: ChannelTasks.READ_METADATA, participantIndex: 1, id: id+3});
      storage.insert(storage.Tables.CHANNEL_TASKS, {channelId: channelId, task: ChannelTasks.OPEN_CHANNEL, id: id+4});
      const channelData = getChannel(channelId.contractAddress, channelId.channelIndex);
      const worker = new ChannelWorker(channelData, key);
      worker.subscribe(handleChannelWorkerEvent);
      channelWorkers.push(worker);
      return channelId;
    })
    .catch( (err) => {
      logger.logError("failed to construct new channel", err);
      if (err instanceof DatonaConnectError) {
        if (err.data.status === 401) throw new ApplicationError("Invite has expired", {error: err});
        else throw new ApplicationError("Could not access the blockchain. Please try again later.", {error: err});
      } else if (err instanceof datona.errors.ContractOwnerError) {
        throw new ApplicationError("Only the contract owner can construct the vault", {error: err});
      } else if (err instanceof datona.errors.ContractExpiryError) {
        throw new ApplicationError("The channel contract has expired. Please use a different invite", {error: err});
      } else if (err instanceof datona.errors.DatonaError) {
        throw new ApplicationError("Could not access the blockchain. Please try again later.", {error: err});
      } else {
        throw new InternalError("Internal error.  Please try again later.", {error: err});
      }
    });
}


function _constructChannelObject(channel) {
  const id = {contractAddress: channel.contractAddress, channelIndex: channel.channelIndex};
  const request = storage.select(storage.Tables.REQUESTS, channel.contractAddress);
  if (request === undefined) {
    logger.logError("Request not found for channel: "+channel.contractAddress);
    throw new ApplicationError("Request not found for channel: "+channel.contractAddress);
  }
  const channelParams = request.channels[channel.channelIndex];
  let name = "";
  let myParticipantIndex = undefined;
  let j = 0;
  for (let i=0; i<channel.participants.length; i++) {
    const participant = channel.participants[i];
    if (participant.id !== channel.persona ) name += j++ === 0 ? participant.name : " / "+participant.name;
    else myParticipantIndex = i;
  }
  const worker = _getChannelWorker(id);
  const lastPost = new _ReversePostIterator(channel.contractAddress, channel.channelIndex).next();
  const nullFunction = () => {};
  return {
    id: id,
    ...channel,
    request: request,
    name: name,
    personaIndex: myParticipantIndex,
    title: channelParams.title,
    post: worker ? worker.writePost.bind(worker) : nullFunction,
    updateMetadata: worker ? worker.updateMetadata.bind(worker) : nullFunction,
    subscribe: worker ? worker.subscribe.bind(worker) : nullFunction,
    unsubscribe: worker ? worker.unsubscribe.bind(worker) : nullFunction,
    setHighUpdateRate: worker ? worker.setHighUpdateRate.bind(worker) : nullFunction,
    posts: new _ReversePostIterator(channel.contractAddress, channel.channelIndex),
    lastMessageTime: lastPost ? lastPost.time : 0
  };
}


function terminateContract(contractAddress, key) {
  return DatonaConnect.terminateChannelContract(contractAddress, key)
    .then( () => {
      // Close Channels.  Closing a worker will stop the worker, inform subscribers and
      // mark the channel record as closed
      // channelWorkers.forEach( worker => {
      //   if (worker.channelId.contractAddress === contractAddress) worker.close();
      // });
    });
}

function _getChannelWorker(channelId) {
  for (let i=0; i<channelWorkers.length; i++) {
    const channelData = channelWorkers[i].channelData;
    if (channelData.contractAddress === channelId.contractAddress && channelData.channelIndex === channelId.channelIndex) {
      return channelWorkers[i];
    }
  }
}

class _ReversePostIterator {

  constructor(contractAddress, channelIndex) {
    this.contractAddress = contractAddress;
    this.channelIndex = channelIndex;
    this.posts = storage.select(storage.Tables.POSTS, {contractAddress: contractAddress, channelIndex: channelIndex});
    this.posts.sort((p1, p2) => {return p1.time - p2.time});
    this.ptr = this.posts.length;
    this._findNext();
  }

  hasNext() { return this.ptr >= 0 }

  next() {
    if (this.ptr < 0) return undefined;
    const result = this.ptr >= 0 ? this.posts[this.ptr] : undefined;
    this._findNext();
    return result;
  }

  reset() {
    this.ptr = this.posts.length;
    this._findNext();
  }

  _findNext() {
    while (--this.ptr >= 0 &&
    ( this.posts[this.ptr].contractAddress !== this.contractAddress ||
      this.posts[this.ptr].channelIndex !== this.channelIndex)) {}
  }

  toArray() {
    const backupPtr = this.ptr;
    this.ptr = this.posts.length;
    this._findNext();
    let result = [];
    while (this.hasNext()) result.push(this.next());
    this.ptr = backupPtr;
    return result;
  }

}
