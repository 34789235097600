import React from "react";
import {ScrollPort, ViewPort} from "../../components/StyledComponents";
import {H3Header} from "../../components/Headers";
import styled, {useTheme} from "styled-components";
import storage from "../../model/storage";
import {useParams} from "react-router-dom";
import {InternalError} from "../../model/errors";
import {getDataItemUiDetails} from "../personas/CategoryUiDetails";
import {stringUtils} from "../../utils/utils";
import DataForm from "../personas/DataForm";
import model from "../../model/model";
import SelectBox from "../../components/SelectBox";
import Button from "../../components/Button";
import StarsIcon from "../../assets/stars4.png";
import CornerIcon from "../../assets/icons/corner-left-down.png";

class Component extends React.Component {

  constructor(props) {
    super(props);
    const params = props.params;
    const request = storage.select(storage.Tables.OPEN_REQUESTS, params.requestId);
    if (request === undefined) throw new InternalError("non-existent request passed to RequestView: "+params.requestId);
    const personas = model.getPersonas();
    const personaNameList = [];
    personas.forEach(persona => {
      personaNameList.push({id: persona.id, text: persona.name});
    });
    this.state = {
      requestId: params.requestId,
      request: request,
      personaId: personas[0].id,
      items: [],
      personaNameList: personaNameList
    }
    const items = request.dataItems;
    for (let i=0; i<items.length; i++) {
      this.state.items.push({
        index: i,
        standard: model.dataStandard.getItemById(items[i]),
        label: getDataItemUiDetails(items[i]).title,
        value: undefined
      });
    }
  }

  onPersonaChange(event) {
    this.setState({ personaId: event.target.value });
  }

  onReject() { console.log(this.state.requestId);
    storage.del(storage.Tables.OPEN_REQUESTS, this.state.requestId);
    this.props.history.goBack();
  }

  onApprove() {
    console.log("approve");
    model.approveRequest(this.state.request, this.state.personaId);
  }

  render() {
    const request = this.state.request;
    const dataItems = request.dataItems.map( item => {
      return <DataItem>{getDataItemUiDetails(item).title}</DataItem>;
    })
    const warnings =  request.rating.warnings === 0 ? "" : " ("+request.rating.warnings+" warnings)";
    const thirdParties = request.requesters.map(item => {
      return item.id === request.requester.id ? null : <li>{item.knownAs}</li>;
    });
    const restrictions = request.restrictions.map(item => {
      return <li>{stringUtils.capitalise(item.summary)}</li>;
    });
    return (
      <ViewPort>
        <H3Header title={request.requester.knownAs} subtitle="Data Request" icon={request.requester.logo} back={this.props.history.goBack} />
        <ScrollPort>
          <Content>
            <TitleRow>
              <Title className="h2">Request For Data</Title>
              <Stars src={StarsIcon} />
            </TitleRow>
            <Content>
              <Summary>{this.state.request.summary}</Summary>
              <DataItems>
                {dataItems}
              </DataItems>
              <TitleRow>
                <LeftSpan>Rating: <Highlight>{request.rating.stars}/5</Highlight>{warnings}</LeftSpan>
                <ForwardButton className="h6" onClick={() => {this.props.history.push("/request/rating/"+this.state.requestId)}}>Rating Details ></ForwardButton>
              </TitleRow>
              <br/>
              <hr/>
              <Summary>Summary</Summary>
              <SummaryBox>
                <SummaryRow>
                  <SummaryRowHeader>Period:</SummaryRowHeader>
                  <SummaryRowContent><p>{stringUtils.capitalise(request.maxDuration)}</p></SummaryRowContent>
                </SummaryRow>
                <SummaryRow>
                  <SummaryRowHeader><br/>Shared With:</SummaryRowHeader>
                  <SummaryRowContent><UL>{thirdParties}</UL></SummaryRowContent>
                </SummaryRow>
                <SummaryRow>
                  <SummaryRowHeader><br/>Restrictions</SummaryRowHeader>
                  <SummaryRowContent><UL>{restrictions}</UL></SummaryRowContent>
                </SummaryRow>
                <TitleRow>
                  <LeftSpan/>
                  <ForwardButton className="h6" onClick={() => {this.props.history.push("/request/exposure/"+this.state.requestId)}}>Full Details ></ForwardButton>
                </TitleRow>
              </SummaryBox>
              <Summary>Data</Summary>
              <PersonaSelectorContainer>
                <ArrowImage src={CornerIcon}/>
                <SelectBoxContainer>
                  <SelectBox options={this.state.personaNameList} value={this.state.personaId} onInput={this.onPersonaChange.bind(this)} color="brand" bgColor="lightLowContrast" height="37px" theme={this.props.theme}/>
                </SelectBoxContainer>
              </PersonaSelectorContainer>
              <DataForm personaId={this.state.personaId} items={this.state.items} editable={false} scrollable={false}/>
            </Content>
            <Footer>
              <Button text="Reject" bgColor="error" color="lightBackground" onClick={this.onReject.bind(this)} />
              <Button text="Approve" bgColor="brand" color="lightBackground" onClick={this.onApprove.bind(this)} />
            </Footer>
          </Content>
        </ScrollPort>
      </ViewPort>
    )
  }

}


export default (props) => (<Component {...props} params={useParams()} theme={useTheme()} />);

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Content = styled.div`
  display: flex:
  flex-direction: column;
  padding: 12px;
`;

const Title = styled.div`
  flex: 1 1;
`;

const Stars = styled.img`
  flex: 0 0 auto;
  width: 78px;
  height: 15px;
`;

const Summary = styled.p`
  color: ${props => props.theme.colors.lightHighContrast};
  padding-top: 24px;
  padding-bottom: 12px;
`;

const DataItems = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 24px;
  display: grid;
  grid-column-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
`;

const DataItem = styled.span`
  font-weight: bold;
`;

const Highlight = styled.span`
  font-weight: bold;
  color: ${props => props.theme.colors.brand};  
`;

const LeftSpan = styled.div`
  flex: 1 1;
`;

const ForwardButton = styled.div`
  color: ${props => props.theme.colors.brand};
`;

const SummaryBox = styled.div`
  border: 1px solid ${props => props.theme.colors.lightHighContrast};
  color: ${props => props.theme.colors.lightHighContrast};
  margin-bottom: 12px;
  display: flex:
  flex-direction: column;
  padding: 8px;
`;

const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
`;

const SummaryRowHeader = styled.div`
  flex: 0 0 auto;
  width: 100px;
`;

const SummaryRowContent = styled.div`
  flex: 1 1;
  font-weight: bold;
`;


const UL = styled.ul`
  padding-left: 20px;
`;

const PersonaSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding: 12px 24px;
`;

const ArrowImage = styled.img`
  flex: 0 0 auto;
  width: 28px;
  height: 28px;
`;

const SelectBoxContainer = styled.div`
  flex: 1 1;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: ${props => props.theme.colors.lightLowContrast};
  border-top: 1px solid ${props => props.theme.colors.lightHighContrast};
`;
