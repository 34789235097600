
export class ApplicationError extends Error {
  constructor(errorOrMessage, data) {
    if (toString.call(errorOrMessage) === '[object String]') {
      super(errorOrMessage);
      this.message = errorOrMessage;
      this.data = (data !== undefined) ? data : {};
    }
    else {
      super(errorOrMessage.message);
      this.message = errorOrMessage.message;
      this.data = (data !== undefined) ? data : {};
      this.data.error = errorOrMessage;
    }
    this.name = this.constructor.name;
  }
}

export class RestfulApiError extends ApplicationError {
  constructor(message, error, status) {
    if (error) super(message+": "+error.message, {status: status, error: error});
    else super(message, {status: status, error: error});
  }
}

export class DatonaConnectError extends RestfulApiError {}

export class InternalError extends ApplicationError {}

export class DataRequestError extends ApplicationError {
  constructor(message, request) {
    super(message, {request: request});
  }
}

export class UserError extends ApplicationError {}

