import React from "react";
import styled from "styled-components";
import SingleButtonFooter from "../../components/SingleButtonFooter";
import {useParams} from "react-router-dom";
import DatonaConnect from "../../components/DatonaConnect";
import TextArea from "../../components/TextArea";
import model from "../../model/model";
import {logger} from "../../utils/logger";
import {CenteredContent, LoadingIcon, ScrollPort, SuccessIcon, ViewPort} from "../../components/StyledComponents";
import {ButtonOnlyHeader} from "../../components/Headers";
import TextButton from "../../components/TextButton";
import {stringUtils} from "../../utils/utils";
import AnonymousIcon from "../../assets/anonymous.png";

export const ConnectRemotely_params = { invite: undefined };

const State = { NOT_CONNECTING: 0, CONNECTING: 1, FINISHED: 2, FAILED: 3 }

class Main extends React.Component {

  constructor(props) {
    super(props);
    let params = this.props.params;
    this.invite = ConnectRemotely_params.invite;
    ConnectRemotely_params.invite = undefined;

    // Validate params
    if (params.persona === undefined || params.knownAs === undefined || params.iconIndex === undefined) {
      logger.logError("Invalid parameters passed to Connect-Remotely: "+params.persona+"/"+params.knownAs+"/"+params.iconIndex);
      props.history.push("/channels");
    }
    else {
      const persona = model.getPersona(params.persona);
      if (persona === undefined) {
        logger.logError("Invalid persona passed to Connect-Remotely: "+params.persona);
        props.history.push("/channels");
      }
      else {
        const icon = params.iconIndex < persona.icons.length ? persona.icons[params.iconIndex] : AnonymousIcon;
        this.state = {
          persona: params.persona,
          knownAs: params.knownAs,
          iconIndex: params.iconIndex,
          icon: icon,
          link: undefined,
          validationResult: undefined,
          connecting: State.NOT_CONNECTING
        }

      }
      // If the invite is already given then process it right away.  Otherwise show the page.
      if (this.invite) {
        const validationResult = this.processLink(this.invite);
        if (validationResult !== undefined) {
          this.state.validationResult = validationResult;
          this.state.connecting = State.FAILED;
        }
        else this.state.connecting = State.CONNECTING;
      }
    }
  }

  handleCopyInvite() {
    if (this.invite === undefined) this.invite = model.generateInviteLink(this.state.persona, this.state.knownAs, this.state.icon);
    stringUtils.copyStringToClipboard(this.invite);
  }

  handleLink(event) {
    const validationResult = this.processLink(event.target.value);
    if (validationResult !== undefined) {
      this.setState({
        link: event.target.value,
        validationResult: validationResult
      });
    }
    else this.setState({connecting: State.CONNECTING});
  }

  processLink(link) {
    const inviteIsValid = model.validateChannelInvite(link);
    const validationResult = inviteIsValid ? undefined : "invite is invalid";
    if (inviteIsValid) this.connect(link, this.state.persona, this.state.knownAs, this.state.icon);
    return validationResult;
  }

  connect(link, personaId, knownAs, icon) {
    const setState = this.setState.bind(this);
    try {
      model.receiveChannelInvite(link, personaId, knownAs, icon)
        .then(() => {
          setState({connecting: State.FINISHED})
        })
        .catch(err => {
          logger.logError("Failed to create channel", err);
          // TODO generate meaningful error message
          setState({connecting: State.FAILED, validationResult: err.message});
        })
    }
    catch (err) {
      logger.logError("Failed to create channel", err);
      // TODO generate meaningful error message
      setState({connecting: State.NOT_CONNECTING, validationResult: err.message});
    }
  }

  render() {
    const errorMsg = this.state.validationResult === undefined ? <br/> : this.state.validationResult;

    const inputContent =
      <InputBoxContainer>
        <span className="BodyText">Send the recipient the following invite link:</span><br/>
        <TextButton className="buttonPressGrow" text="copy invite" color="brand" onClick={this.handleCopyInvite.bind(this)} />
        <br/><br/>
        <span className="BodyText">Or paste theirs below:</span><br/>
        <TextArea value={this.state.link} onInput={this.handleLink.bind(this)} textAlign="center" height="66px" bgColor="white" />
        <br/>
        <ErrorMessage className="h5">{errorMsg}</ErrorMessage>
      </InputBoxContainer>;

    const connectingContent =
      <Indicator>
        <LoadingIcon/>
        <p className="h5">Connecting...</p>
      </Indicator>;

    const connectedContent =
    <Indicator>
      <SuccessIcon/>
      <p className="h5">Connected!</p>
    </Indicator>;

    const failedContent =
      <InputBoxContainer>
        <p className="h2">Failed!</p>
        <br/>
        <ErrorMessage className="h5">{errorMsg}</ErrorMessage>
      </InputBoxContainer>;

    const content =
      (this.state.connecting === State.NOT_CONNECTING) ? inputContent :
      (this.state.connecting === State.CONNECTING) ? connectingContent :
      (this.state.connecting === State.FINISHED) ? connectedContent :  failedContent;

    const footerButton =
      (this.state.connecting === State.NOT_CONNECTING)
        ? <SingleButtonFooter buttonText="Done" route="/channels"/>
      : (this.state.connecting === State.CONNECTING)
        ? <SingleButtonFooter buttonText="OK" route="/channels" />
        : <SingleButtonFooter buttonText="Done" route="/channels" />;

    return (
      <ViewPort>
        <ButtonOnlyHeader back={this.props.history.goBack}/>
        <ScrollPort>
          <CenteredContent>
            <DatonaConnect/>
            <Gap/>
            {content}
          </CenteredContent>
        </ScrollPort>
        {footerButton}
      </ViewPort>
    );
  }

}

export default (props) => (<Main {...props} params={useParams()} />);

const InputBoxContainer = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Indicator = styled.div`
  flex: 0 0 auto;
  height: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Gap = styled.div`
  flex: 0 0 auto;
  height: 5vh;
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.error};
`;

