import React from "react";
import styled from "styled-components";
import {CenteredContent, ExpandingGap, ViewPort} from "../components/StyledComponents";
import {useParams} from "react-router-dom";
import storage from "../model/storage";
import SingleButtonFooterWithBypass from "../components/SingleButtonFooterWithBypass";
import SingleButtonFooter from "../components/SingleButtonFooter";
import {logger} from "../utils/logger";

class Del extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      request: props.params.table.toUpperCase(),
      done: false
    }
  }

  delete() {
    try {
      if (this.state.request === "ALL") storage.Tables.forEach(item => {
        storage.del(item, '*')
      });
      else storage.del(storage.Tables[this.state.request], '*');
      this.setState({ done: true })
    }
    catch (err) {
      logger.logError("failed to delete table", err);
      this.setState({ done: true, error: err.message });
    }
  }

  back() {
    this.props.history.back();
  }

  render() {
    let content;
    let footer;
    if (this.state.done) {
      content =
        <CenteredContent>
          {this.state.error ? <h2>Failed!</h2> : <h2>Success!</h2>}
          <ExpandingGap/>
          {this.state.error ? this.state.error : <h5>Deleted table {this.state.request}</h5>}
          <ExpandingGap/>
          <ExpandingGap/>
        </CenteredContent>
      footer = <SingleButtonFooter buttonText="OK" route="/" />
    }
    else {
      content =
        <CenteredContent>
          <Title className="h2">Are you sure?</Title>
          <p className="h5">This will delete the table {this.state.request}</p>
          <ExpandingGap/>
          <p className="h5">ONLY DELETE IF YOU KNOW WHAT YOU ARE DOING!</p>
          <ExpandingGap/>
          <ExpandingGap/>
        </CenteredContent>;
      footer = <SingleButtonFooterWithBypass buttonText="Delete" onClick={this.delete.bind(this)} bypassButtonText="cancel" bypassOnClick={this.back.bind(this)}/>
    }
    return (
      <ViewPort>
        {content}
        {footer}
      </ViewPort>
    );
  }
}

export default (props) => (<Del {...props} params={useParams()} />);

const Title = styled.div`
  color: ${props => props.theme.colors.lightHighContrast};
  flex: 0 0 auto;
  padding-top: 7vh;
  padding-bottom: 7vh;
`;
