import React from "react";
import BottomTabs from "../../components/BottomTabs";
import model from "../../model/model";
import PersonaItem from "../../components/PersonaItem";
import {H2Header} from "../../components/Headers";
import {ScrollPort, ViewPort} from "../../components/StyledComponents";
import AnonymousIcon from "../../assets/anonymous.png";

class Personas extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick
    }
  }

  render() {
    let key = 0;
    const personaList = model.getPersonas().map(item => {
      const icon = item.icons.length > 0 ? item.icons[0] : AnonymousIcon;
      return <PersonaItem key={key++} title={item.name} icon={icon} route={"/personas/persona/"+item.id} />
    });
    return (
      <ViewPort>
        <ScrollPort>
          <H2Header title="Profiles" add="/personas/create-persona"/>
          {personaList}
        </ScrollPort>
        <BottomTabs selected="personas"/>
      </ViewPort>
    );
  };

}

export default Personas;

