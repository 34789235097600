import UserIcon from "../../assets/icons/user.png";
import AtIcon from "../../assets/icons/at-sign.png";
import UserCheckIcon from "../../assets/icons/user-check.png";
import BookmarkIcon from "../../assets/icons/bookmark.png";
import UsersIcon from "../../assets/icons/users.png";
import StarIcon from "../../assets/icons/star.png";
import AnchorIcon from "../../assets/icons/anchor.png";
import MapIcon from "../../assets/icons/map.png";
import HelpIcon from "../../assets/icons/help-circle.png";

export function getCategoryUiDetails(catId) {
  let name = "";
  let summary = "";
  let icon = "";
  switch (catId) {
    case "about":
      name = "About You";
      summary = "Name, date of birth, gender, etc";
      icon = UserIcon;
      break;
    case "contact":
      name = "Contact Details";
      summary = "Phone, email, address, etc";
      icon = AtIcon;
      break;
    case "identity":
      name = "Identity Documents";
      summary = "Photo ID, proof of address, etc";
      icon = UserCheckIcon;
      break;
    case "career":
      name = "Jobs & Career";
      summary = "Current jobs and career history";
      icon = BookmarkIcon;
      break;
    case "family":
      name = "Family Details";
      summary = "Marital status, children, etc";
      icon = UsersIcon;
      break;
    case "social":
      name = "Social";
      summary = "Social media identifiers";
      icon = StarIcon;
      break;
    case "lifestyle":
      name = "Lifestyle Details";
      summary = "House, cars, pets, etc";
      icon = AnchorIcon;
      break;
    case "historical":
      name = "Historical Data";
      summary = "Previous addresses, names, etc";
      icon = MapIcon;
      break;
    case "other":
      name = "Other Data";
      summary = "Other, uncategorized information";
      icon = HelpIcon;
      break;
    default:
      name = catId;
      summary = "Unknown category";
      icon = HelpIcon;
  }
  return {name, summary, icon};
}


export function getDataItemUiDetails(id) {
  switch (id) {
    case "first-name":
      return { title: "First Name" };
    case "middle-names":
      return { title: "Middle Names" };
    case "last-name":
      return { title: "Last Name" };
    case "preferred-name":
      return { title: "Preferred Name" };
    case "legal-full-name":
      return { title: "Legal Full Name" };
    case "known-as":
      return { title: "Known As" };
    case "full-legal-name":
      return { title: "Full Legal Name" };
    case "gender":
      return { title: "Gender" };
    case "dob":
      return { title: "Date Of Birth" };
    case "title":
      return { title: "Title" };
    case "post-nominal-letters":
      return { title: "Post Nominal Letters" };
    case "photo-id":
      return { title: "Photo ID" };
    case "proof-of-address":
      return { title: "Proof Of Address" };
    default:
      return { title: id };
  }
}