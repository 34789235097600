import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";
import {colorUtils} from "../utils/utils";
import {Link} from "react-router-dom";


const ButtonComponent = (props) => {
  const button = <Button className={"h6 "+props.className} color={props.color} onClick={props.onClick}>{props.text}</Button>;
  if (props.route) {
    return (
      <Link to={props.route}>
        {button}
      </Link>
    );
  }
  else return button;
};

ButtonComponent.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string,
  route: PropTypes.string
}

export default ButtonComponent;


const Button = styled.div`
  color: ${colorUtils.colorSelector};
`;
