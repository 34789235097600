import React from "react";
import styled from "styled-components";
import SingleButtonFooterWithBypass from "../../components/SingleButtonFooterWithBypass";
import InputBox from "../../components/InputBox";
import model from "../../model/model";
import {logger} from "../../utils/logger";
import {AppStates} from "../../AppStates";
import {CenteredContent, ErrorMessage, ViewPort} from "../../components/StyledComponents";

const UNMATCHED_PINS_ERROR_MSG = "Please ensure your PINs match";

class Main extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      newPIN1: "",
      newPIN2: "",
      validationResult: undefined
    }
  }

  pin1Update(event) {
    logger.logTrace("pin1Update: "+event.target.value);
    let validationResult = undefined;
    if (this.state.validationResult !== undefined || this.state.newPIN2.length !== 0) {
      if (event.target.value !== this.state.newPIN2) validationResult = UNMATCHED_PINS_ERROR_MSG;
      else validationResult = model.validatePIN(event.target.value);
    }
    this.setState({newPIN1: event.target.value, validationResult: validationResult});
  }

  pin2Update(event) {
    logger.logTrace("pin2Update: "+event.target.value);
    let validationResult = undefined;
    if (this.state.validationResult !== undefined || event.target.value.length >= this.state.newPIN1.length) {
      if (event.target.value !== this.state.newPIN1) validationResult = UNMATCHED_PINS_ERROR_MSG;
      else validationResult = model.validatePIN(event.target.value);
    }
    this.setState({newPIN2: event.target.value, validationResult: validationResult});
  }

  setPIN() {
    logger.logTrace("setPIN");
    try {
      if (this.state.newPIN1 !== this.state.newPIN2) throw UNMATCHED_PINS_ERROR_MSG;
      model.setPIN(undefined,this.state.newPIN1);
      model.setAppState(AppStates.PIN_CREATED);
      this.props.history.push("/name-your-persona");
    }
    catch(err) {
      logger.logTrace("PIN Invalid: "+err);
      this.setState({validationResult: err});
    }
  }

  skipPIN() {
    logger.logTrace("skipPIN");
    model.setAppState(AppStates.PIN_CREATED);
    this.props.history.push("/name-your-persona");
  }


  render() {
    return (
      <ViewPort>
        <CenteredContent>
          <Title className="h2">Create a PIN</Title>
          <p className="h5">For security of your data, it is highly recommended that you create a PIN to protect this
            app</p>
          <PinBoxes>
            <InputBox bgColor="white" placeholder="Enter PIN" textAlign="center" onInput={this.pin1Update.bind(this)} height="50px"/>
            <InputBox bgColor="white" placeholder="Re-enter PIN" textAlign="center" onInput={this.pin2Update.bind(this)} height="50px"/>
            <ErrorMessage text={this.state.validationResult} />
          </PinBoxes>
          <BottomGap/>
        </CenteredContent>
        <SingleButtonFooterWithBypass buttonText="Next" bypassButtonText="Skip" bypassButtonColor="brand"
                                      onClick={this.setPIN.bind(this)} bypassOnClick={this.skipPIN.bind(this)} />
      </ViewPort>
    );
  };

}

export default Main;

const Title = styled.div`
  color: ${props => props.theme.colors.lightHighContrast};
  flex: 0 0 auto;
  padding-top: 7vh;
  padding-bottom: 7vh;
`;

const PinBoxes = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 170px;
  width: 80%;
  padding: 16px;
`;

const BottomGap = styled.div`
  flex: 0 0 auto;
  height: 20vh;
`;