import React from 'react';
import {imageUtils} from "../utils/utils";
import model from "../model/model";
import theme from "../styles/theme";
import {FileAddButton, Icon} from "./StyledComponents";
import styled, {useTheme} from "styled-components";
import AnonymousIcon from "../assets/anonymous.png";

class IconBar extends React.Component {

  constructor(props) {
    super(props);
    const persona = model.getPersona(props.personaId);
    const icons = [];
    persona.icons.forEach((item) => {icons.push(item)});
    icons.push(AnonymousIcon);
    this.state = {
      personaId: props.personaId,
      icons: icons,
      selectable: props.selectable || false,
      iconIndex: props.selectedIndex ? parseInt(props.selectedIndex) : 0,
      iconChangeHandler: props.onChange
    };
    if (this.state.iconChangeHandler && !props.selectedIndex) this.state.iconChangeHandler(0, icons[0]);
  }

  addIcons(event) {
    const setState = this.setState.bind(this);
    const personaId = this.state.personaId;
    const fileList = event.target.files;
    if (fileList) {
      for( let i=0; i<fileList.length; i++) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const image = new Image();
          image.onload = function () {
            const resizedImage = imageUtils.resize(image, 240, 240);
            const persona = model.getPersona(personaId);
            const icons = [];
            const personaIcons = [];
            persona.icons.forEach((item) => {personaIcons.push(item); icons.push(item)});
            personaIcons.push(resizedImage);
            model.setPersonaIcons(personaId, personaIcons);
            icons.push(resizedImage);
            icons.push(AnonymousIcon);
            setState({icons: icons, iconIndex: icons.length-2});
          };
          image.src = e.target.result.toString();
        }
        reader.readAsDataURL(fileList.item(i));
      }
    }
  }

  setIcon(index) {
    if (this.state.selectable === true) {
      this.state.iconChangeHandler(index, this.state.icons[index]);
      this.setState({iconIndex: index});
    }
  }

  render() {
    const icons = [];
    for (let i=0; i<this.state.icons.length; i++) {
      const styleMod = (this.state.selectable === true && i === this.state.iconIndex) ? {border: "3px solid "+theme.colors.brand} : undefined;
      icons.push(<Icon key={i} src={this.state.icons[i]} onClick={() => {this.setIcon(i)}} style={styleMod} />);
    }
    icons.push(<FileAddButton key="addButton" filter="image/*" size={18} color="brand" onFiles={this.addIcons.bind(this)} theme={this.props.theme}/>);
    return (
      <IconBox>{icons}</IconBox>
    );
  }
}

export default (props) => (<IconBar {...props} theme={useTheme()} />);

const IconBox = styled.div`
  height: 60px;
  max-width: 90%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  overflow: hidden;
  overflow-x: scroll;
`;
