
export const logger = {
  log: logInfo,
  logError: logError,
  logWarning: logWarning,
  logDebug: logDebug,
  logTrace: logTrace
}

export const logLevels = {
  TRACE: 2,
  DEBUG: 1,
  INFO: 0,
  logLevel: 2,
}

function _log(str, level) {
  if (logLevels.logLevel >= level) console.log(str);
}

function logError(str, error) {
  if (error) {
    _log("\u001b[31mERROR! "+str+": "+error.message, logLevels.INFO)
    if (error.details) _log("  "+error.details, logLevels.INFO);
    logDebug(error);
  }
  else _log("\u001b[31mERROR! "+str, logLevels.INFO)
}

function logInfo(str) { _log(str, logLevels.INFO) }
function logDebug(str) { _log("\u001b[34m(debug) "+str, logLevels.DEBUG) }
function logTrace(str) { _log("\u001b[34m(trace) "+str, logLevels.TRACE) }
function logWarning(str) { _log("\u001b[35m(warning) "+str, logLevels.INFO) }

