import React from "react";
import styled from "styled-components";
import model from "../../model/model";
import {TextItem, SelectItem, DateItem, IconBarItem} from "../../components/TextItem";
import SingleButtonFooter from "../../components/SingleButtonFooter";
import SingleButtonFooterWithBypass from "../../components/SingleButtonFooterWithBypass";
import {logger} from "../../utils/logger";

class Form extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      personaId: props.personaId,
      editMode: false,
      items: props.items,
      editable: props.editable !== undefined ? props.editable : true,
      scrollable: props.scrollable !== undefined ? props.scrollable : true
    };
  }

  enableEdit() {
    this.setState({editMode: true})
  }

  cancelEdit() {
    this.state.items.forEach((item) => {
      item.edit = undefined;
    });
    this.setState({editMode: false, keybase: this.state.keybase+100})
  }

  save() {
    this.state.items.forEach((item) => {
      if (item.edit !== undefined && item.edit !== item.value) {
        model.setDataItem(this.state.personaId, item.standard.id, item.edit);
      }
      item.edit = undefined;
    });
    this.setState({editMode: false})
  }

  textItemChange(itemIndex, value) {
    const newItems = this.state.items.map( (item, i) => {
      if (i === itemIndex) return {...item, edit: value}
      else return item;
    })
    this.setState( (prevState) => { return {...prevState, items: newItems } } );
  }

  render() {
    this.state.items.forEach((item) => {
      item.value = model.getDataItem(this.props.personaId, item.standard.id).value;
    });
    let key = 0;
    const itemList = this.state.items.map(item => { return this.getDataItemComponent(key++, item) });
    const footer = !this.state.editable ? null
      : this.state.editMode
        ? <SingleButtonFooterWithBypass buttonText="Save" onClick={this.save.bind(this)} bypassButtonText="Cancel" bypassOnClick={this.cancelEdit.bind(this)} bypassButtonColor="brand"/>
        : <SingleButtonFooter buttonText="Edit" onClick={this.enableEdit.bind(this)}/>;

    const scrollableList =
      <ScrollContainer>
        <ScrollPort>
          {itemList}
        </ScrollPort>
      </ScrollContainer>;

    const nonScrollableList =
      <NonScrollContainer>
        {itemList}
      </NonScrollContainer>;

    const list = this.state.scrollable ? scrollableList : nonScrollableList;

    return (
      <FormContainer>
        {list}
        {footer}
      </FormContainer>
    );
  };


  getDataItemComponent(key, item) {
    const textInputHandler = this.textItemChange.bind(this);
    switch (item.standard.type) {
      case "text":
        return <TextItem key={key} label={item.label} value={item.edit !== undefined ? item.edit : item.value || ""} onInput={(event) => textInputHandler(item.index, event.target.value)} editDisabled={!this.state.editMode}/>
      case "date":
        return <DateItem key={key} label={item.label} value={item.edit !== undefined ? item.edit : item.value} onInput={(event) => textInputHandler(item.index, event.target.value)} editDisabled={!this.state.editMode}/>
      case "enum":
        const options = [];
        for (let i=0; i<item.standard.values.length; i++) {
          options.push(item.standard.values[i]);
        }
        return <SelectItem key={key} label={item.label} options={options} value={item.edit !== undefined ? item.edit : item.value} onInput={(event) => textInputHandler(item.index, event.target.value)} editDisabled={!this.state.editMode} />
      case "icons":
        return <IconBarItem key={key} label={item.label} personaId={this.state.personaId} />;
      default:
        logger.logError("DataForm: unsupported item type "+item.standard.type);
    }
  }

}

export default Form;


// Nested component styles

const FormContainer = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
`;

const ScrollContainer = styled.div`
  flex: 1 1;
  position: relative;
`;

const ScrollPort = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-y: scroll;
  padding-left: 33px;
  padding-right: 33px;
`;

const NonScrollContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`;

