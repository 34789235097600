import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Icon} from "./StyledComponents";
import ChevronRight from "../assets/icons/chevron-right.png";

const Item = (props) => {
  const item =
    <ItemComponent>
      <Icon src={props.icon} style={{height: 56, width: 56}}/>
      <Title className="h3">{props.title}</Title>
      <Chevron src={ChevronRight}/>
    </ItemComponent>;
  if (props.route) return (<Link to={props.route}>{item}</Link>);
  else return (<div style={{opacity: 0.5}}>{item}</div>);
};

export default Item;


// Nested component styles

const ItemComponent = styled.div`
  background-color: ${props => props.theme.colors.lightBackground};
  border: 1px solid ${props => props.theme.colors.lightLowContrast};
  padding: 12px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  gap: 12px;
`;

const Title = styled.div`
  flex 1 1;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.lightHighContrast};
`;

const Chevron = styled.img`
  flex 0 0 auto;
  background-color: transparent;
  border-radius: 50%;
  width: 28px;
  height: 28px;
`;

