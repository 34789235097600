import React from "react";
import styled from "styled-components";
import model from "../model/model";
import DatonaConnect from "./DatonaConnect";
import {CenteredContent} from "./StyledComponents";
import InputBox from "./InputBox";
import {arrayUtils} from "../utils/utils";
import IconBar from "./IconBar";

class Main extends React.Component {

  constructor(props) {
    super(props);

    // Determine name options
    const dropdownValues = [];
    const personaId = props.personaId;
    const knownAsRecord = model.getDataItem(personaId, "known-as");
    const knownAs = knownAsRecord ? knownAsRecord.value : undefined;
    const preferredNameRecord = model.getDataItem(personaId, "preferred-name");
    const preferredName = preferredNameRecord ? preferredNameRecord.value : undefined;
    const firstNameRecord = model.getDataItem(personaId, "first-name");
    const firstName = firstNameRecord ? firstNameRecord.value : undefined;
    const lastNameRecord = model.getDataItem(personaId, "last-name");
    const lastName = lastNameRecord ? lastNameRecord.value : undefined;
    const titleRecord = model.getDataItem(personaId, "title");
    const title = titleRecord ? titleRecord.value : undefined;
    if (knownAs) dropdownValues.push(knownAs);
    if (preferredName) arrayUtils.exclusivePush(dropdownValues, preferredName);
    if (lastName) {
      if (preferredName) arrayUtils.exclusivePush(dropdownValues, preferredName+" "+lastName);
      if (firstName) arrayUtils.exclusivePush(dropdownValues, firstName+" "+lastName);
      if (title) {
        arrayUtils.exclusivePush(dropdownValues, title+" "+lastName);
        if (preferredName) arrayUtils.exclusivePush(dropdownValues, title+" "+preferredName+" "+lastName);
        if (firstName) arrayUtils.exclusivePush(dropdownValues, title+" "+firstName+" "+lastName);
      }
    }
    dropdownValues.push("Anonymous");

    this.state = {
      personaId: personaId,
      dropdownValues: dropdownValues,
      knownAs: props.knownAs || dropdownValues[0],
      iconIndex: props.iconIndex || 0,
      icon: undefined,
      listener: props.onChange
    }
    // IconBar will call setIcon when constructed, which will call this element's listener and initialise the icon in the state
  }

  setKnownAs(event) {
    if (this.state.listener) this.state.listener(event.target.value, this.state.iconIndex, this.state.icon);
    this.setState({ knownAs: event.target.value });
  }

  setIcon(index, icon) {
    if (this.state.listener) this.state.listener(this.state.knownAs, index, icon);
    this.setState({ iconIndex: index, icon: icon });
  }

  render() {
    const dropdownValues = this.state.dropdownValues.map((val, i) => {
      return <option key={i} value={val}/>;
    });
    return (
      <CenteredContent>
        <DatonaConnect/>
        <InputBoxContainer>
          <span className="BodyText">Choose how you would like to be known:</span>
          <InputBox value={this.state.knownAs} list="known-as" onInput={this.setKnownAs.bind(this)} bgColor="lightLowContrast" textAlign="center" height="38px" />
          <datalist id="known-as">
            {dropdownValues}
          </datalist>
        </InputBoxContainer>
        <InputBoxContainer>
          <span className="BodyText">and which icon to share:</span>
          <IconBar personaId={this.state.personaId} selectable={true} selectedIndex={this.state.iconIndex} onChange={this.setIcon.bind(this)} />
        </InputBoxContainer>
        <BottomGap/>
      </CenteredContent>
    );
  }

}

export default Main;


const InputBoxContainer = styled.div`
  height: 100px;
  width: 90%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const BottomGap = styled.div`
  flex: 0 0 auto;
  height: 20vh;
`;

