import {logger} from "../../utils/logger";
import storage from "../storage";
import {Identities} from "./identities";
import {InternalError} from "../errors";


export const Personas = {
  getPersonas: getPersonas,
  getPersona: getPersona,
  setPersonaName: setPersonaName,
  setPersonaIcons: setPersonaIcons,
  setPersonaDetails: setPersonaDetails,
  getDataItem: getDataItem,
  setDataItem: setDataItem,
  createPersona: createPersona,
  validatePersonaName: validatePersonaName
}


function getPersonas() {
  return storage.select(storage.Tables.PERSONAS, '*');
}

function getPersona(id) {
  return storage.select(storage.Tables.PERSONAS, id);
}

function setPersonaName(id, name) {
  setPersonaDetails(id, name, undefined);
}

function setPersonaIcons(id, icons) {
  setPersonaDetails(id, undefined, icons);
}

function setPersonaDetails(id, name, icons) {
  logger.logTrace("set name and icons of persona with id "+id);
  const persona = getPersona(id);
  if (persona === undefined) throw new InternalError("cannot set details of persona with id "+id+" since it does not exist");
  if (icons && !Array.isArray(icons)) throw new InternalError("cannot set icons of persona with id "+id+": only accept an array of icons");
  if (name) persona.name = name;
  if (icons) persona.icons = icons;
  storage.update(storage.Tables.PERSONAS, id, persona);
}

function getDataItem(personaId, itemId) {
  return storage.select(storage.Tables.PERSONA_DATA, {personaId: personaId, id: itemId}) || {};
}

function setDataItem(personaId, itemId, value) {
  const storageId = {personaId: personaId, id: itemId};
  let item = storage.select(storage.Tables.PERSONA_DATA, storageId);
  if (item !== undefined) {
    storage.update(storage.Tables.PERSONA_DATA, storageId, {...item, value: value});
  }
  else {
    item = {
      personaId: personaId,
      id: itemId,
      value: value,
      creationTime: Date.now()
    };
    storage.insert(storage.Tables.PERSONA_DATA, item);
  }
}

function createPersona(name) {
  logger.logTrace("createPersona: "+name);
  validatePersonaName(name);
  const identity = storage.select(storage.Tables.IDENTITIES, '*')[0];
  let childIndex = 0;
  getPersonas().forEach( (persona) => { if (persona.parentId === identity.id) childIndex++ });
  const derivationPath = 'm/44/60/'+childIndex+'/0/0';
  const key = Identities.getChildKey(identity, derivationPath);
  const newPersona = {
    parentId: identity.id,
    id: key.address,
    derivationPath: derivationPath,
    name: name,
    icons: []
  };
  storage.insert(storage.Tables.PERSONAS, newPersona);
  logger.log("created persona "+name+" with id "+newPersona.id);
}

function validatePersonaName(name) {
  if (typeof name !== "string" || name.length === 0) throw new Error("please provide a valid name");
  // TODO: check name is not already taken?
}
