import React from "react";
import styled from "styled-components";
import InputBox from "../../components/InputBox";
import {logger} from "../../utils/logger";
import model from "../../model/model";
import {CenteredContent, ErrorMessage, ViewPort} from "../../components/StyledComponents";
import SingleButtonFooterWithBypass from "../../components/SingleButtonFooterWithBypass";

const DEFAULT_NAME = "Anonymous";

class Main extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      validationResult: undefined
    }
    this.personaName = DEFAULT_NAME;
  }

  next() {
    logger.logTrace("next: Persona Name="+this.personaName);
    try {
      model.createPersona(this.personaName);
      this.props.history.push("/personas");
      this.clear();  // tidy up
    }
    catch(err) {
      this.setState({validationResult: err.message});
    }
  }

  clear() {
    this.setState({ validationResult: undefined });
    this.personaName = DEFAULT_NAME;
  }

  input(event) {
    if (event.target.value.length === 0) this.personaName = DEFAULT_NAME;
    else this.personaName = event.target.value;
  }

  render() {
    return (
      <ViewPort>
        <CenteredContent>
          <Title className="h2">New Profile</Title>
          <p className="h5">Enter the name of the new profile</p>
          <InputBoxContainer>
            <InputBox placeholder={DEFAULT_NAME} onInput={this.input.bind(this)} bgColor="white" textAlign="center" style={{width: "90%"}} height="50px"/>
            <ErrorMessage text={this.state.validationResult}/>
          </InputBoxContainer>
          <p className="BodyText">For your convenience your personal identity data is organised into profiles. <br/><br/>You can have as many profiles as you want.</p>
          <BottomGap/>
        </CenteredContent>
        <SingleButtonFooterWithBypass buttonText="Done" onClick={this.next.bind(this)} bypassButtonText="Cancel" bypassRoute="/personas" />
      </ViewPort>
    );
  }

}

export default Main;

const Title = styled.div`
  color: ${props => props.theme.colors.lightHighContrast};
  flex: 0 0 auto;
  padding-top: 7vh;
  padding-bottom: 7vh;
`;

const InputBoxContainer = styled.div`
  height: 140px;
  width: 80%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const BottomGap = styled.div`
  flex: 0 0 auto;
  height: 10vh;
`;