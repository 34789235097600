import React from "react";
import {ScrollPort, ViewPort} from "../../components/StyledComponents";
import {H3Header} from "../../components/Headers";
import storage from "../../model/storage";
import {InternalError} from "../../model/errors";
import {useParams} from "react-router-dom";
import {useTheme} from "styled-components";

class Component extends React.Component {

  constructor(props) {
    super(props);
    const params = props.params;
    const request = storage.select(storage.Tables.OPEN_REQUESTS, params.requestId);
    if (request === undefined) throw new InternalError("non-existent request passed to RequestView: "+params.requestId);
    this.state = {
      requestId: params.requestId,
      request: request
    }
  }

  render() {
    const request = this.state.request;
    return (
      <ViewPort>
        <H3Header title={request.requester.knownAs} subtitle="Data Request" icon={request.requester.logo} back={this.props.history.goBack} />
        <ScrollPort>

        </ScrollPort>
      </ViewPort>
    )
  }

}


export default (props) => (<Component {...props} params={useParams()} theme={useTheme()} />);
