import {logger} from "../utils/logger";

const schema = [
  {
    id: "about",
    items: [
      {
        id: "first-name",
        type: "text"
      },
      {
        id: "middle-names",
        type: "text"
      },
      {
        id: "last-name",
        type: "text"
      },
      {
        id: "preferred-name",
        type: "text"
      },
      {
        id: "known-as",
        type: "text"
      },
      {
        id: "legal-full-name",
        type: "text"
      },
      {
        id: "gender",
        type: "enum",
        allowOther: true,
        values: ["Prefer Not To Say", "Male", "Female", "Non-Binary", "Transgender", "Intersex"]
      },
      {
        id: "dob",
        type: "date"
      },
      {
        id: "title",
        type: "enum",
        allowOther: true,
        values: ["Mr", "Mrs", "Miss", "Ms", "Mx"]
      },
      {
        id: "post-nominal-letters",
        type: "text"
      }
    ]
  },
  {
    id: "contact",
    items: [
      // {
      //   id: "phone",
      //   type: "infinite-set",
      //   subtype: "phone"
      // },
      // {
      //   id: "email",
      //   type: "infinite-set",
      //   subtype: "email"
      // },
      // {
      //   id: "address",
      //   type: "address"
      // }
    ]
  },
  {
    id: "identity",
    items: [
      {
        id: "photo-id",
        type: "text"
      },
      {
        id: "proof-of-address",
        type: "text"
      }
    ]
  },
  {
    id: "career",
    items: []
  },
  {
    id: "family",
    items: []
  },
  {
    id: "social",
    items: []
  },
  {
    id: "lifestyle",
    items: []
  },
  {
    id: "historical",
    items: []
  },
  {
    id: "other",
    items: []
  }
];

let categories = [];

const DataStandard = {
  items: [],
  categories: categories,
  getItemById: getItemById,
  isInCategory: (id) => {},
  getItemsInCategory: getItemsInCategory
}

export default DataStandard;

function getItemsInCategory(category) {
  for (let i=0; i<schema.length; i++) {
    if (schema[i].id === category) return schema[i].items;
  }
  return [];
}

function getItemById(id) {
  for (let i=0; i<schema.length; i++) {
    for (let j=0; j<schema[i].items.length; j++) {
      if (schema[i].items[j].id === id) return schema[i].items[j];
    }
  }
  logger.logError("item id not found in data standard: "+id);
}

schema.forEach( (category) => {
  categories.push(category.id);
})