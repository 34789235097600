import axios from "axios";
import {RestfulApiError} from "./errors";

export class PrepaidBlockchainGateway {

  constructor(api) {
    this.api = api;
  }

  transact(args) {
    return axios.post(this.api, args)
      .then((res) => {
        if (res.status === 200) return res.data;
        else throw new Error("unexpected status code " + res.status);
      })
      .catch((err) => {
        // error structure and status codes are determined by the Datona Connect server
        const status = (err.response !== undefined) ? err.response.status : undefined;
        switch (status) {
          case 400:
            throw new RestfulApiError("Blockchain API failure", err.response.data.error, status);
          case 401:
            throw new RestfulApiError("Transaction has expired", err.response.data.error, status);
          default:
            throw new RestfulApiError("Blockchain API failure: undefined status code " + status, err.response.data.error, status);
        }
      });
  }

}