import React from "react";
import PropTypes from 'prop-types';
import Button from "./Button";
import TextButton from "./TextButton";
import {FooterColumn} from "./StyledComponents";

const SingleButtonFooter = (props) => {
  return (
    <FooterColumn bgColor={props.bgColor}>
      <Button bgColor={props.buttonColor ? props.buttonColor : "brand"} text={props.buttonText} route={props.route} onClick={props.onClick}/>
      <TextButton color={props.bypassButtonColor ? props.bypassButtonColor : "brand"} text={props.bypassButtonText} route={props.bypassRoute} onClick={props.bypassOnClick} />
    </FooterColumn>
  );
};

SingleButtonFooter.propTypes = {
  buttonText: PropTypes.string.isRequired,
  route: PropTypes.string,
  onClick: PropTypes.func,
  bypassButtonText: PropTypes.string.isRequired,
  bypassRoute: PropTypes.string,
  bypassOnClick: PropTypes.func,
  buttonColor: PropTypes.string,
  bypassButtonColor: PropTypes.string,
  bgColor: PropTypes.string
}

export default SingleButtonFooter;
