import React from "react";
import {CenteredContent, Footer, LoadingIcon, SuccessIcon, Title, ViewPort} from "../../components/StyledComponents";
import {H3Header} from "../../components/Headers";
import Button from "../../components/Button";
import TextButton from "../../components/TextButton";
import styled from "styled-components";
import model from "../../model/model";
import {useParams} from "react-router-dom";
import AnonymousIcon from "../../assets/anonymous.png";

const State = { NOT_CLOSING: 0, CLOSING: 1, CLOSED: 2, FAILED: 3 }

class Component extends React.Component {

  constructor(props) {
    super(props);
    let params = this.props.params;
    const channelId = {contractAddress: params.contractAddress, channelIndex: parseInt(params.channelIndex)};
    const channel = model.getChannel(channelId.contractAddress, channelId.channelIndex);
    const nameAndIcon = this.getNameAndIcon(channel);
    if (channel === undefined) throw new Error("channel does not exist: " + params.contractAddress + "/" + params.channelIndex);
    this.state = {
      state: State.NOT_CLOSING,
      post: undefined,
      channelId: channelId,
      channel: channel,
      name: nameAndIcon.name,
      icon: nameAndIcon.icon
    };
  }

  getNameAndIcon(channel) {
    const name = channel.name ? channel.name : "Anonymous";
    let icon = AnonymousIcon;
    let found = false;
    for (let i=0; !found && i<channel.participants.length; i++) {
      if (channel.participants[i].id !== channel.persona) {
        icon = channel.participants[i].icon;
        found = true;
      }
    }
    return {name: name, icon: icon};
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  terminateChannel() {
    model.terminateContract(this.state.channelId.contractAddress, this.state.channel.persona)
      .then(() => {
        if (this.mounted) this.setState({ state: State.CLOSED });
      })
      .catch( (err) => {
        if (this.mounted) this.setState({ state: State.FAILED, error: "Could not close channel at this time. Please try again later." });
      })
    this.setState({ state: State.CLOSING });
  }

  render() {

    const terminateChannel = this.terminateChannel.bind(this);

    const inputContent =
      <CenteredContent>
        <Gap/>
        <Title className="h2">Are You Sure?</Title>
        <span className="BodyText">This will permanently close your channel.<br/><br/>
        All posts, images, emails and other data will be deleted from the vault.</span>
        <Button bgColor="error" color="lightBackground" text="Close Channel" onClick={terminateChannel} />
        <Gap/>
      </CenteredContent>

    const closingContent =
      <CenteredContent>
        <Indicator>
          <Gap/>
          <Gap/>
          <LoadingIcon/>
          <p className="h5">Closing...</p>
        </Indicator>
      </CenteredContent>;

    const closedContent =
      <CenteredContent>
        <Indicator>
          <Gap/>
          <Gap/>
          <SuccessIcon/>
          <p className="h5">Channel Closed</p>
        </Indicator>
      </CenteredContent>

    const failedContent =
      <CenteredContent>
        <Gap/>
        <div>
          <p className="h2">Failed!</p>
          <br/>
          <ErrorMessage className="h5">{this.state.error}</ErrorMessage>
        </div>
        <Gap/>
      </CenteredContent>;

    const content =
      (this.state.state === State.NOT_CLOSING) ? inputContent :
        (this.state.state === State.CLOSING) ? closingContent :
          (this.state.state === State.CLOSED) ? closedContent :  failedContent;

    const footer = this.state.state === State.NOT_CLOSING
      ? <TextButton text="Cancel" onClick={this.props.history.goBack}/>
      : <Button text="Done" bgColor="brand" color="lightBackground" onClick={this.props.history.goBack}/>;

    return (
      <div>
        <ViewPort>
          <H3Header title={this.state.name} subtitle={this.state.channel.title} icon={this.state.icon} back={this.props.history.goBack} />
          {content}
          <Footer>
            {footer}
          </Footer>
        </ViewPort>
      </div>
    )
  }

}


export default (props) => (<Component {...props} params={useParams()} />);


const Indicator = styled.div`
  flex: 0 0 auto;
  height: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Gap = styled.div`
  flex: 0 0 auto;
  height: 5vh;
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.error};
`;

