import React from 'react';
import styled from "styled-components";
import {colorUtils} from "../utils/utils";

class inputBoxComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: props.value === undefined ? "" : props.value
    };
  }

  inputChange(e) {
    //this.setState({value: e.target.value});
  }

  render() {
    return (
      <InputBoxComponent bgColor={this.props.bgColor}>
        <Input className="h5" placeholder={this.props.placeholder} defaultValue={this.state.value}
               textAlign={this.props.textAlign} height={this.props.height} onInput={this.props.onInput}
               disabled={this.props.disabled === undefined ? false : this.props.disabled}/>
      </InputBoxComponent>
    );
  }
}

export default inputBoxComponent;

const InputBoxComponent = styled.div`
  background: ${colorUtils.bgColorSelector};
  border: 1px solid rgba(228, 228, 228, 0.6);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 4px 8px;
`;

const Input = styled.input`
  height: ${props => props.height ? props.height : "50px"};
  width: 100%;
  border: 0;
  outline: none;
  background-color: transparent;
  text-align: ${props => props.textAlign ? props.textAlign : "center"};
  color: ${props => props.theme.colors.lightHighContrast};
  ::placeholder {
    color: ${props => props.theme.colors.lightMidContrast};
  }
`;
