import React from "react";
import styled from "styled-components";
import InputBox from "./InputBox";
import IconBar from "./IconBar";

let nonce = 0;

export const TextItem = function(props) {
   return (
    <ItemComponent>
      <Label className="body-text">{props.label}</Label>
      <InputBox onInput={props.onInput} value={props.value} textAlign="left" height="30px" bgColor="lightLowContrast" disabled={props.editDisabled === undefined ? false : props.editDisabled}/>
    </ItemComponent>
  )
}

export const SelectItem = function(props) {
  const dropdownValues = props.options.map((val, i) => {
    return <option key={i} value={val}/>;
  });
  return (
    <ItemComponent>
      <Label className="body-text">{props.label}</Label>
      <InputBox list={"option-list-"+nonce} onInput={props.onInput} value={props.value} textAlign="left" height="30px" bgColor="lightLowContrast" disabled={props.editDisabled === undefined ? false : props.editDisabled} onClick={event => {event.currentTarget.value=''}}/>
      <datalist id={"option-list-"+nonce++}>
        {dropdownValues}
      </datalist>
    </ItemComponent>
  )
}

export const DateItem = function(props) {
  return (
    <ItemComponent>
      <Label className="body-text">{props.label}</Label>
      <InputBox type="date" onInput={props.onInput} value={props.value} textAlign="left" height="30px" bgColor="lightLowContrast" disabled={props.editDisabled === undefined ? false : props.editDisabled}/>
    </ItemComponent>
  )
}

export const IconBarItem = function(props) {
  return (
    <ItemComponent>
      <Label className="body-text">{props.label}</Label>
      <IconBar personaId={props.personaId} selectable="false" />
    </ItemComponent>
  )
}


// Nested component styles

const ItemComponent = styled.div`
  background-color: ${props => props.theme.colors.lightBackground};
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 12px;
`;

const Label = styled.span`
 color: ${props => props.theme.colors.lightMidContrast};
`;
