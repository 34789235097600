// import {
//   sampleChannels,
//   sampleDataItems,
//   sampleIdentities,
//   samplePersonas, samplePosts,
//   samplePublicIdentities,
//   sampleRequests
// } from "./sampleData";

import {logger} from "../utils/logger";
import {InternalError} from "./errors";

const AUTH_STRING = "Datona Identity";

let data = {};

const datonaBlockchainHttpUrl = {
  scheme: "https",
  host: "datonavault.com",
  port: "8130"
}

const datonaBlockchainWsUrl = {
  scheme: "wss",
  host: "datonavault.com/ws",
  port: "8130"
}

const infuraUrl = {
  scheme: "https",
  host: "ropsten.infura.io/v3/def9d47e2d744b90b2b68cf690db503a",
  port: ""
}

const infuraWebSocketUrl = {
  scheme: "wss",
  host: "ropsten.infura.io/ws/v3/def9d47e2d744b90b2b68cf690db503a",
  port: ""
}


// const sampleData = {
//   authString: AUTH_STRING,
//   identities: sampleIdentities,
//   settings: {
//     blockchainProvider: infuraUrl,
//     blockchainEventProvider: infuraWebSocketUrl,
//     preferredVaultService: {
//       name: "Datona Vault",
//       id: "0x288b32F2653C1d72043d240A7F938a114Ab69584",
//       url: {
//         scheme: "http",
//         host: "77.68.75.133",
//         port: 8128
//       }
//     },
//     preferredConnectService: {
//       name: "Datona Connect",
//       url: {
//         scheme: "http",
//         host: "77.68.75.133",
//         port: 8129
//       }
//     }
//   },
//   openRequests: [],
//   publicIdentities: samplePublicIdentities,
//   requests: sampleRequests,
//   channels: sampleChannels,
//   posts: samplePosts,
//   personas: samplePersonas,
//   dataItems: sampleDataItems,
//   invites: [],
//   channelTasks: []
// }

const VERSION = 0.2;

const defaultData = {
  version: VERSION,
  authString: AUTH_STRING,
  identities: [],
  settings: {
    appState: 0,
    blockchainProvider: datonaBlockchainHttpUrl,
    blockchainEventProvider: infuraWebSocketUrl,
    preferredVaultService: {
      name: "Datona Vault",
      id: "0x288b32F2653C1d72043d240A7F938a114Ab69584",
      url: {
        scheme: "https",
        host: "datonavault.com",
        port: 8131
      }
    },
    prePaidBlockchainService: "https://datonavault.com:8133"
  },
  openRequests: [],
  publicIdentities: [],
  requests: [],
  channels: [],
  posts: [],
  personas: [],
  dataItems: [],
  invites: [],
  channelTasks: []
}


const storage = {

  Tables: {
    IDENTITIES: 0,
    PERSONAS: 1,
    PERSONA_DATA: 2,
    REQUESTS: 3,
    CHANNELS: 4,
    POSTS: 5,
    SETTINGS: 6,
    OPEN_REQUESTS: 7,
    PUBLIC_IDS: 8,
    INVITES: 9,
    CHANNEL_TASKS: 10
  },

  open: open,
  close: close,
  changeKey: changeKey,

  select: select,
  update: update,
  insert: insert,
  del: del

}

export default storage;

function open(key) {
  //encryptionKey = key;
  //data = sampleData;
  initialise(false);
}

function initialise(forceClear) {
  if (forceClear || window.localStorage.identities === undefined) {
    data = defaultData;
    save();
  }
  else {
    load();
    upgrade();
  }
}

function upgrade() {
  // undefined -> 0.1
  if (!data.settings.version) {
    logger.log("upgrading database to v0.1");
    data.settings.prePaidBlockchainService = defaultData.settings.prePaidBlockchainService;
    data.settings.preferredConnectService = undefined;
    data.settings.version = 0.1;
    save(storage.Tables.SETTINGS);
  }
  if (data.settings.version === 0.1) {
    logger.log("upgrading database from v0.1 to v0.2 (migrate to ropsten)");
    data.settings.blockchainProvider = datonaBlockchainHttpUrl;
    data.settings.blockchainEventProvider = datonaBlockchainWsUrl;
    data.settings.preferredVaultService = defaultData.settings.preferredVaultService;
    data.settings.version = 0.2;
    data.channels = [];
    data.posts = [];
    data.invites = [];
    data.channelTasks = [];
    save();
  }
  if (data.settings.version === 0.2) {
    logger.log("upgrading database from v0.2 to v0.3 (use https services)");
    data.settings.blockchainProvider = datonaBlockchainHttpUrl;
    data.settings.blockchainEventProvider = infuraWebSocketUrl;
    data.settings.prePaidBlockchainService = defaultData.settings.prePaidBlockchainService;
    data.settings.preferredVaultService = defaultData.settings.preferredVaultService;
    for (let i=0; i<data.channels.length; i++) {
      data.channels[i].vault = defaultData.settings.preferredVaultService;
    }
    data.settings.version = 0.3;
    save(storage.Tables.SETTINGS);
    save(storage.Tables.CHANNELS);
  }
}

function load(table) {
  logger.logTrace("loading table "+table);
  if (table === undefined || table === storage.Tables.IDENTITIES) data.identities = JSON.parse(window.localStorage.identities);
  if (table === undefined || table === storage.Tables.PERSONAS) data.personas = JSON.parse(window.localStorage.personas);
  if (table === undefined || table === storage.Tables.PERSONA_DATA) data.dataItems = JSON.parse(window.localStorage.dataItems);
  if (table === undefined || table === storage.Tables.CHANNELS) data.channels = JSON.parse(window.localStorage.channels);
  if (table === undefined || table === storage.Tables.CHANNEL_TASKS) data.channelTasks = JSON.parse(window.localStorage.channelTasks);
  if (table === undefined || table === storage.Tables.INVITES) data.invites = JSON.parse(window.localStorage.invites);
  if (table === undefined || table === storage.Tables.POSTS) data.posts = JSON.parse(window.localStorage.posts);
  if (table === undefined || table === storage.Tables.SETTINGS) data.settings = JSON.parse(window.localStorage.settings);
  if (table === undefined || table === storage.Tables.REQUESTS) data.requests = JSON.parse(window.localStorage.requests);
  if (table === undefined || table === storage.Tables.PUBLIC_IDS) data.publicIdentities = JSON.parse(window.localStorage.publicIdentities);
  if (table === undefined || table === storage.Tables.OPEN_REQUESTS) data.openRequests = JSON.parse(window.localStorage.openRequests);
}

function save(table) {
  logger.logTrace("saving table "+table);
  if (table === undefined || table === storage.Tables.IDENTITIES) window.localStorage.identities = JSON.stringify(data.identities);
  if (table === undefined || table === storage.Tables.PERSONAS) window.localStorage.personas = JSON.stringify(data.personas);
  if (table === undefined || table === storage.Tables.PERSONA_DATA) window.localStorage.dataItems = JSON.stringify(data.dataItems);
  if (table === undefined || table === storage.Tables.CHANNELS) window.localStorage.channels = JSON.stringify(data.channels);
  if (table === undefined || table === storage.Tables.CHANNEL_TASKS) window.localStorage.channelTasks = JSON.stringify(data.channelTasks);
  if (table === undefined || table === storage.Tables.INVITES) window.localStorage.invites = JSON.stringify(data.invites);
  if (table === undefined || table === storage.Tables.POSTS) window.localStorage.posts = JSON.stringify(data.posts);
  if (table === undefined || table === storage.Tables.SETTINGS) window.localStorage.settings = JSON.stringify(data.settings);
  if (table === undefined || table === storage.Tables.REQUESTS) window.localStorage.requests = JSON.stringify(data.requests);
  if (table === undefined || table === storage.Tables.PUBLIC_IDS) window.localStorage.publicIdentities = JSON.stringify(data.publicIdentities);
  if (table === undefined || table === storage.Tables.OPEN_REQUESTS) window.localStorage.openRequests = JSON.stringify(data.openRequests);
}

function close() {
  save();
}

function changeKey(newKey) {
  //encryptionKey = newKey;
}

function select(table, id) {
  switch (table) {

    case storage.Tables.IDENTITIES:
      if (id==='*') return data.identities;
      else for (let i=0; i<data.identities.length; i++) {
        if (data.identities[i].id === id) return data.identities[i];
      }
      return undefined;

    case storage.Tables.PERSONAS:
      if (id==='*') return data.personas;
      else for (let i=0; i<data.personas.length; i++) {
        if (data.personas[i].id === id) return data.personas[i];
      }
      return undefined;

    case storage.Tables.PERSONA_DATA:
      if (id==='*') return data.dataItems;
      else for (let i=0; i<data.dataItems.length; i++) {
        if (data.dataItems[i].personaId === id.personaId && data.dataItems[i].id === id.id) return data.dataItems[i];
      }
      return undefined;

    case storage.Tables.REQUESTS:
      if (id==='*') return data.requests;
      else for (let i=0; i<data.requests.length; i++) {
        if (data.requests[i].contractAddress === id) return data.requests[i];
      }
      return undefined;

    case storage.Tables.CHANNELS:
      if (id==='*') return data.channels;
      else for (let i=0; i<data.channels.length; i++) {
        if (data.channels[i].contractAddress === id.contractAddress && data.channels[i].channelIndex === id.channelIndex) return data.channels[i];
      }
      return undefined;

    case storage.Tables.POSTS:
      if (id==='*') return data.posts;
      else {
        const byId = (toString.call(id) === '[object String]');
        let results = [];
        for (let i = 0; i < data.posts.length; i++) {
          if (byId) {
            if (data.posts[i].id === id) results.push(data.posts[i]);
          }
          else {
            if (data.posts[i].contractAddress === id.contractAddress && data.posts[i].channelIndex === id.channelIndex) results.push(data.posts[i]);
          }
        }
        return results;
      }

    case storage.Tables.SETTINGS:
      if (id==='*') return data.settings;
      else return data.settings[id];

    case storage.Tables.OPEN_REQUESTS:
      if (id==='*') return data.openRequests;
      else for (let i=0; i<data.openRequests.length; i++) {
        if (data.openRequests[i].id === id) return data.openRequests[i];
      }
      return undefined;

    case storage.Tables.PUBLIC_IDS:
      if (id==='*') return data.publicIdentities;
      else for (let i=0; i<data.publicIdentities.length; i++) {
        if (data.publicIdentities[i].id === id) return data.publicIdentities[i];
      }
      return undefined;

    case storage.Tables.INVITES:
      if (id==='*') return data.invites;
      else for (let i=0; i<data.invites.length; i++) {
        if (data.invites[i].inviteCode === id) return data.invites[i];
      }
      return undefined;

    case storage.Tables.CHANNEL_TASKS:
      if (id==='*') return data.channelTasks;
      else {
        const tasks = [];
        for (let i=0; i<data.channelTasks.length; i++) {
          if (data.channelTasks[i].channelId.contractAddress === id.contractAddress && data.channelTasks[i].channelId.channelIndex === id.channelIndex) tasks.push(data.channelTasks[i]);
        }
        return tasks;
      }

    default:
      throw new Error("select: invalid storage table: "+table);
  }
}

function insert(table, record) {
  logger.logTrace("INSERT into "+Object.keys(storage.Tables)[table]+" "+JSON.stringify(record));
  switch (table) {
    case storage.Tables.IDENTITIES:
      data.identities.push(record);
      break;
    case storage.Tables.PERSONAS:
      data.personas.push(record);
      break;
    case storage.Tables.PERSONA_DATA:
      data.dataItems.push(record);
      break;
    case storage.Tables.REQUESTS:
      data.requests.push(record);
      break;
    case storage.Tables.CHANNELS:
      data.channels.push(record);
      break;
    case storage.Tables.POSTS:
      data.posts.push(record);
      break;
    case storage.Tables.SETTINGS:
      data.settings.push(record);
      break;
    case storage.Tables.OPEN_REQUESTS:
      data.openRequests.push(record);
      break;
    case storage.Tables.PUBLIC_IDS:
      data.publicIdentities.push(record);
      break;
    case storage.Tables.INVITES:
      data.invites.push(record);
      break;
    case storage.Tables.CHANNEL_TASKS:
      data.channelTasks.push(record);
      break;
    default:
      throw new Error("insert: invalid storage table: "+table);
  }
  save(table);
}


function update(table, id, item) {
  //logger.logTrace("UPDATE "+JSON.stringify(id)+" in "+Object.keys(storage.Tables)[table]+" to "+JSON.stringify(item));
  let found = false;
  switch (table) {
    case storage.Tables.IDENTITIES:
      for (let i=0; i<data.identities.length; i++) {
        if (data.identities[i].id === id) {
          data.identities[i] = item;
          found = true;
        }
      }
      break;

    case storage.Tables.PERSONAS:
      for (let i=0; i<data.personas.length; i++) {
        if (data.personas[i].id === id) {
          data.personas[i] = item;
          found = true;
        }
      }
      break;

    case storage.Tables.PERSONA_DATA:
      for (let i=0; i<data.dataItems.length; i++) {
        if (data.dataItems[i].personaId === id.personaId && data.dataItems[i].id === id.id) {
          data.dataItems[i] = item;
          found = true;
        }
      }
      break;

    case storage.Tables.REQUESTS:
      for (let i=0; i<data.requests.length; i++) {
        if (data.requests[i].contractAddress === id) {
          data.requests[i] = item;
          found = true;
        }
      }
      break;

    case storage.Tables.CHANNELS:
      for (let i=0; i<data.channels.length; i++) {
        if (data.channels[i].contractAddress === id.contractAddress && data.channels[i].channelIndex === id.channelIndex) {
          data.channels[i] = item;
          found = true;
        }
      }
      break;

    case storage.Tables.POSTS:
      throw new Error("storage: cannot update posts")

    case storage.Tables.SETTINGS:
      if (id==='*') {
        data.settings = item;
        found = true;
      }
      else {
        data.settings[id] = item;
        found = true;
      }
      break;

    case storage.Tables.OPEN_REQUESTS:
      for (let i=0; i<data.openRequests.length; i++) {
        if (data.openRequests[i].id === id) {
          data.openRequests[i] = item;
          found = true;
        }
      }
      break;

    case storage.Tables.PUBLIC_IDS:
      for (let i=0; i<data.publicIdentities.length; i++) {
        if (data.publicIdentities[i].id === id) {
          data.publicIdentities[i] = item;
          found = true;
        }
      }
      break;

    case storage.Tables.INVITES:
      for (let i=0; i<data.invites.length; i++) {
        if (data.invites[i].id === id) {
          data.invites[i] = item;
          found = true;
        }
      }
      break;

    case storage.Tables.CHANNEL_TASKS:
      throw new InternalError("cannot update CHANNEL_TASKS record");

    default:
      throw new InternalError("update: invalid storage table: "+table);
  }
  if (!found) throw new InternalError("update: record not found: "+id);
  save(table);
}


function del(table, id) {
  logger.logTrace("DELETE "+JSON.stringify(id)+" in "+Object.keys(storage.Tables)[table]);
  switch (table) {
    case storage.Tables.IDENTITIES:
      if (id==='*') {
        data.identities = [];
        save(storage.Tables.IDENTITIES);
        return;
      }
      for (let i=0; i<data.identities.length; i++) {
        if (data.identities[i].id === id) {
          data.identities.splice(i,1);
          save(storage.Tables.IDENTITIES);
          return;
        }
      }
      break;

    case storage.Tables.PERSONAS:
      if (id==='*') {
        data.personas = [];
        save(storage.Tables.PERSONAS);
        return;
      }
      for (let i=0; i<data.personas.length; i++) {
        if (data.personas[i].id === id) {
          data.personas.splice(i,1);
          save(storage.Tables.PERSONAS);
          return;
        }
      }
      break;

    case storage.Tables.PERSONA_DATA:
      if (id==='*') {
        data.dataItems = [];
        save(storage.Tables.PERSONA_DATA);
        return;
      }
      for (let i=0; i<data.dataItems.length; i++) {
        if (data.dataItems[i].personaId === id.personaId && data.dataItems[i].id === id.id) {
          data.dataItems.splice(i,1);
          save(storage.Tables.PERSONA_DATA);
          return;
        }
      }
      break;

    case storage.Tables.REQUESTS:
      if (id==='*') {
        data.requests = [];
        save(storage.Tables.REQUESTS);
        return;
      }
      for (let i=0; i<data.requests.length; i++) {
        if (data.requests[i].contractAddress === id) {
          data.requests.splice(i,1);
          save(storage.Tables.REQUESTS);
          return;
        }
      }
      break;

    case storage.Tables.CHANNELS:
      if (id==='*') {
        data.channels = [];
        save(storage.Tables.CHANNELS);
        return;
      }
      for (let i=0; i<data.channels.length; i++) {
        if (data.channels[i].contractAddress === id.contractAddress && data.channels[i].channelIndex === id.channelIndex) {
          data.channels.splice(i,1);
          save(storage.Tables.CHANNELS);
          return;
        }
      }
      break;

    case storage.Tables.POSTS:
      if (id==='*') {
        data.posts = [];
        save(storage.Tables.POSTS);
        return;
      }
      let found = 0;
      for (let i=0; i<data.posts.length; i++) {
        if (data.posts[i].contractAddress === id.contractAddress && data.posts[i].channelIndex === id.channelIndex) {
          data.posts.splice(i,1);
          i--;
          found = true;
        }
      }
      if (found) {
        save(storage.Tables.POSTS);
        return;
      }
      break;

    case storage.Tables.SETTINGS:
      if (id==='*') {
        data.settings = defaultData.settings;
        save(storage.Tables.SETTINGS);
        return;
      }
      else throw new Error("storage: cannot delete settings")

    case storage.Tables.OPEN_REQUESTS:
      if (id==='*') {
        data.openRequests = [];
        save(storage.Tables.OPEN_REQUESTS);
        return;
      }
      for (let i=0; i<data.openRequests.length; i++) {
        if (data.openRequests[i].id === id) {
          data.openRequests.splice(i,1);
          save(storage.Tables.OPEN_REQUESTS);
          return;
        }
      }
      break;

    case storage.Tables.PUBLIC_IDS:
      if (id==='*') {
        data.publicIdentities = [];
        save(storage.Tables.PUBLIC_IDS);
        return;
      }
      for (let i=0; i<data.publicIdentities.length; i++) {
        if (data.publicIdentities[i].id === id) {
          data.publicIdentities.splice(i,1);
          save(storage.Tables.PUBLIC_IDS);
          return;
        }
      }
      break;

    case storage.Tables.INVITES:
      if (id==='*') {
        data.invites = [];
        save(storage.Tables.INVITES);
        return;
      }
      for (let i=0; i<data.invites.length; i++) {
        if (data.invites[i].inviteCode.toLowerCase() === id.toLowerCase()) {
          data.invites.splice(i,1);
          save(storage.Tables.INVITES);
          return;
        }
      }
      break;

    case storage.Tables.CHANNEL_TASKS:
      if (id==='*') {
        data.channelTasks = [];
        save(storage.Tables.CHANNEL_TASKS);
        return;
      }
      for (let i=0; i<data.channelTasks.length; i++) {
        if (data.channelTasks[i].id === id) {
          data.channelTasks.splice(i,1);
          save(storage.Tables.CHANNEL_TASKS);
          return;
        }
      }
      break;

    default:
      throw new Error("del: invalid storage table: "+table);
  }
  throw new Error("delete: record not found: "+table+"/"+id);
}