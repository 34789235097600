import React from "react";
import styled from "styled-components";
import ConversationItem from "../../components/ConversationItem";
import BottomTabs from "../../components/BottomTabs";
import model from "../../model/model";
import Menu from "../../components/Menu";
import {H2Header} from "../../components/Headers";
import {ScrollPort, ViewPort} from "../../components/StyledComponents";
import {ChannelState} from "../../model/workers";

class Channels extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      channels: model.getChannels(),
      onClick: props.onClick,
      menuVisible: false,
      showClosed: false
    }
  }

  componentDidMount() {
    if (!this.subscriptionId) {
      this.subscriptionId = model.subscribeToAllChannels(this.handleChannelEvent.bind(this));
    }
  }

  componentWillUnmount() {
    model.unsubscribeFromAllChannels(this.subscriptionId);
    this.subscriptionId = undefined;
  }

  handleChannelEvent() {
    this.setState({
      channels: model.getChannels()
    });
  }

  openMenu() {
    this.setState({menuVisible: true});
  }

  closeMenu() {
    this.setState({menuVisible: false});
  }

  render() {
    let listKey = 0;
    const channelList = [];
    for (let i=0; i<this.state.channels.length; i++) {
      const item = this.state.channels[i];
      if (this.state.showClosed || item.state !== ChannelState.CLOSED)
        channelList.push(<ConversationItem key={listKey++} route={"/Channel/"+item.contractAddress+"/"+item.channelIndex} conversation={item} />);
    }
    const personas = model.getPersonas();
    const menuButtons = [
      { text: "Connect to Someone", route: personas.length > 1 ? "/channels/connect/choose-persona" : "/channels/connect/choose-known-as/"+personas[0].id },
      { text: "Follow Someone" },  // TODO
      { text: "Create Group" },  // TODO
      { text: "Scan or Paste a Request", route: "/request/scanOrPaste" },
    ];
    return (
      <div>
        <MenuContainer hidden={!this.state.menuVisible}>
          <Menu closeButtonText="Cancel" handleClose={this.closeMenu.bind(this)} buttons={menuButtons} />
        </MenuContainer>
        <ViewPort className={this.state.menuVisible ? "darken" : ""}>
          <ScrollPort>
            <H2Header title="Channels" add={this.openMenu.bind(this)} />
            {channelList}
          </ScrollPort>
          <BottomTabs selected="channels"/>
        </ViewPort>
      </div>
    );
  };

}

export default Channels;


// Nested component styles

const MenuContainer = styled.div`
  position: absolute;
  top: 52px;
  width: 100%;
  align: center;
  margin: auto;
  z-index: 100;
  ::menuHidden {
    display: none;
  }
`;


