import React from 'react';
import './styles/theme.css';
import Channels from "./pages/channels/Channels";
import ChannelView from "./pages/channels/Channel";
import FirstScreen from "./pages/onboarding/FirstScreen";
import WelcomeScreen from "./pages/onboarding/WelcomeScreen";
import CreatePinScreen from "./pages/onboarding/CreatePinScreen";
import EnterPinScreen from "./pages/onboarding/CreatePinScreen"; // TODO
import NameYourPersonaScreen from "./pages/onboarding/NamePersonaScreen";
import HaveARequestScreen from "./pages/onboarding/HaveARequestScreen";
import DevDelete from "./pages/Dev-Delete";
import DevView from "./pages/Dev-View";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import model from "./model/model";
import {AppStates} from "./AppStates";
import EmailView from "./pages/channels/EmailView";
import Settings from "./pages/settings/settings";
import ImportAnIdentity from "./pages/settings/ImportAnIdentity";
import Personas from "./pages/personas/personas";
import CreatePersona from "./pages/personas/CreatePersona";
import CategoryView from "./pages/personas/CategoryView";
import PersonaDataView from "./pages/personas/DataView";
import Connect_ChoosePersona from "./pages/channels/Connect-ChoosePersona";
import Connect_ChooseKnownAs from "./pages/channels/Connect-ChooseKnownAs";
import Connect_Remotely from "./pages/channels/Connect-Remotely";
import CloseChannel from "./pages/channels/CloseChannel";
import PasteRequest from "./pages/requests/PasteRequest";
import RequestView from "./pages/requests/RequestView";
import RequestRating from "./pages/requests/Request-Rating";
import RequestDataExposure from "./pages/requests/Request-DataExposure";
import RequestTermsAndConditions from "./pages/requests/Request-TermsAndConditions";
import styled from "styled-components";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "first-screen",
      context: null,
      model: props.model
    }
  }

  render() {
    let firstScreen = EnterPinScreen;
    switch (model.getAppState()) {
      case AppStates.START:
        firstScreen = FirstScreen;
        break;
      case AppStates.PIN_CREATED:
        firstScreen = NameYourPersonaScreen;
        break;
      case AppStates.PERSONA_NAMED:
        firstScreen = HaveARequestScreen;
        break;
      default:
        firstScreen = Channels;
    }
    return (
      <div className="App fullscreen" >
        <Screen>
          <Router basename={process.env.PUBLIC_URL}>
            <Switch>
              <Route exact path="/" component={firstScreen}/>
              <Route exact path="/welcome" component={WelcomeScreen}/>
              <Route exact path="/enter-pin" component={EnterPinScreen}/>
              <Route exact path="/create-pin" component={CreatePinScreen}/>
              <AuthorisedRoute exact path="/name-your-persona" component={NameYourPersonaScreen}/>
              <AuthorisedRoute exact path="/have-a-request" component={HaveARequestScreen}/>
              <AuthorisedRoute exact path="/channels" component={Channels} />
              <AuthorisedRoute exact path="/channels/connect/choose-persona" component={Connect_ChoosePersona} />
              <AuthorisedRoute exact path="/channels/connect/choose-known-as/:persona" component={Connect_ChooseKnownAs} />
              <AuthorisedRoute exact path="/channels/connect/choose-known-as/:persona/:knownAs/:iconIndex" component={Connect_ChooseKnownAs} />
              <AuthorisedRoute exact path="/channels/connect/remote/:persona/:knownAs/:iconIndex" component={Connect_Remotely} />
              <AuthorisedRoute exact path="/channel/:contractAddress/:channelIndex" component={ChannelView}/>
              <AuthorisedRoute exact path="/channel/email" component={EmailView} />
              <AuthorisedRoute exact path="/channel/close/:contractAddress/:channelIndex" component={CloseChannel} />
              <AuthorisedRoute exact path="/settings" component={Settings} />
              <AuthorisedRoute exact path="/settings/import-an-identity" component={ImportAnIdentity} />
              <AuthorisedRoute exact path="/personas" component={Personas} />
              <AuthorisedRoute exact path="/personas/persona/:id" component={CategoryView} />
              <AuthorisedRoute exact path="/personas/persona/data/:personaId/:category" component={PersonaDataView} />
              <AuthorisedRoute exact path="/personas/create-persona" component={CreatePersona} />
              <AuthorisedRoute exact path="/request/scanOrPaste" component={PasteRequest} />
              <AuthorisedRoute exact path="/request/:requestId" component={RequestView} />
              <AuthorisedRoute exact path="/request/rating/:requestId" component={RequestRating} />
              <AuthorisedRoute exact path="/request/exposure/:requestId" component={RequestDataExposure} />
              <AuthorisedRoute exact path="/request/terms/:requestId" component={RequestTermsAndConditions} />
              <AuthorisedRoute exact path="/dev/view/:table" component={DevView} />
              <AuthorisedRoute exact path="/dev/delete/:table" component={DevDelete} />
              <Route render={() => { return <Redirect to={firstScreen}/> }} />
             </Switch>
          </Router>
        </Screen>
      </div>
     );
  }

}

export default App;

class AuthorisedRoute extends React.Component {
  render() {
    const { component: Component, ...props } = this.props
    return (
      <Route
        {...props}
        render={props => (
          model.isAuthenticated() ?
            <Component {...props} /> :
            <Redirect to='/enter-pin' />
        )}
      />
    )
  }
}

const Screen = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0;
  background: ${props => props.theme.colors.lightBackground};
  overflow: hidden;
  padding-bottom: 16px;
`;

