import React from "react";
import BottomTabs from "../../components/BottomTabs";
import SelectionItem from "../../components/SelectionItem";
import {H2Header} from "../../components/Headers";
import {ScrollPort, ViewPort} from "../../components/StyledComponents";
import DownloadCloudIcon from "../../assets/icons/download-cloud.png";
import UploadCloudIcon from "../../assets/icons/upload-cloud.png";
import LockIcon from "../../assets/icons/lock.png";
import CopyIcon from "../../assets/icons/copy.png";
import SettingsIcon from "../../assets/icons/settings.png";

class Settings extends React.Component {

  render() {
    return (
      <ViewPort>
        <H2Header title="Settings"/>
        <ScrollPort>
          <SelectionItem title="Security" summary="Change your PIN and improve security" icon={LockIcon} route="" />
          <SelectionItem title="Backup Your Identity" summary="Backup your private key and personas" icon={UploadCloudIcon} route="" />
          <SelectionItem title="Import An Identity" summary="Restore your private key from a backup" icon={DownloadCloudIcon} route="/settings/import-an-identity" />
          <SelectionItem title="Enable Another Device" summary="Copy your identity to another device" icon={CopyIcon} route="" />
          <SelectionItem title="Configure Your Vaults" summary="Choose where to store your data" icon={SettingsIcon} route="" />
        </ScrollPort>
        <BottomTabs selected="settings"/>
      </ViewPort>
    );
  };

}

export default Settings;




