import React from "react";
import styled from "styled-components";
import {H3Header} from "../../components/Headers";
import {ScrollPort, ViewPort} from "../../components/StyledComponents";

const EmailView = (props) => {
    return (
      <ViewPort>
        <H3Header title={props.channel.name} subtitle={props.post.subject} back={this.props.history.goBack} icon={props.icon} />
        <ScrollPort>
          <Frame srcDoc={props.post.content}/>
        </ScrollPort>
      </ViewPort>
    );
  };

export default EmailView;

const Frame = styled.iframe`
  width: 100%;
  height: 100%;
`;

